import gql from "graphql-tag";

export const PacingDataObjectOrderByInput = {
  daysLeft_ASC: "daysLeft_ASC",
  daysLeft_DESC: "daysLeft_DESC",
  pace_ASC: "pace_ASC",
  pace_DESC: "pace_DESC",
  campaignName_ASC: "campaignName_ASC",
  campaignName_DESC: "campaignName_DESC",
};

const GET_PACING_DATA_CONNECTION = gql`
  query pacings(
    $skip: Int
    $first: Int
    $campaignOrderIds: [ID!]
    $orgID: ID!
    $orderBy: PacingDataObjectOrderByInput
  ) {
    pacingDataObjectsConnection(
      where: {
        AND: [
          {
            campaignOrder: {
              id_in: $campaignOrderIds
              id_not: null
              type_not: CPC
              cpm_not: null
              orgs_some: {
                OR: [{ id: $orgID }, { parentOrg_some: { id: $orgID } }]
              }
              status_not_in: [PENDING, DRAFT]
            }
          }
          { daysLeft_gte: 0 }
        ]
      }
      skip: $skip
      first: $first
      orderBy: $orderBy
    ) {
      edges {
        cursor
        node {
          pace
          paceYesterday
          paceTwoDaysAgo
          paceThreeDaysAgo
          paceFourDaysAgo
          paceFiveDaysAgo
          paceSixDaysAgo
          paceSevenDaysAgo
          dailyAvgImpressions
          daysLeft
          daysIntoFlight
          dailyAvgImpressionsLeft
          impressionsLeft
          impressionsYesterday
          expectedImpressions
          ctr
          roas
          campaignOrder {
            id
            name
            type
            advertiser {
              name
              id
            }
            advertiserName
            cpm
            budget
            startDate
            endDate
            status
            attributionWindow
            aggregateData {
              overallData {
                aggImpressions
              }
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        startCursor
        endCursor
        hasPreviousPage
      }
      aggregate {
        count
      }
    }
    total: pacingDataObjectsConnection(
      where: {
        AND: [
          {
            campaignOrder: {
              id_in: $campaignOrderIds
              id_not: null
              type_not: CPC
              cpm_not: null
              orgs_some: {
                OR: [{ id: $orgID }, { parentOrg_some: { id: $orgID } }]
              }
              status_not_in: [PENDING, DRAFT]
            }
          }
          { daysLeft_gte: 0 }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export default GET_PACING_DATA_CONNECTION;
