import React from "react";
import { Card, Row } from "antd";
import { Chart, Axis, Geom, Tooltip } from "bizcharts";
import {
  CardText,
  CardTitleText,
} from "../../../../../../shared/globalStyling/styledText";
import { ExtraInfo } from "./";
import {
  formatWholeNumber,
  formatWholeNumberNaN,
} from "../../../../../../../core/utils/campaigns";

const formatKey = key => {
  switch (key) {
    case "one":
      return "1";
    case "two":
      return "2";
    case "three":
      return "3";
    case "fourToSeven":
      return "4 - 7";
    case "eightToFifteen":
      return "8 - 15";
    case "SixteenToTwentyFive":
      return "16 - 25";
    case "TwentySixOrMore":
      return "26 or more";
    default:
      return key;
  }
};

const FrequencyChartCard = ({
  data,
  geoDates: { start, end },
  customLabel,
  explanation = "",
}) => {
  const frequencyData = Object.keys(data).map(key => ({
    "Times of Visit": formatKey(key),
    Visits: data[key],
  }));

  const totalVisits = frequencyData.reduce(
    (accumulator, currentValue) => accumulator + currentValue["Visits"],
    0
  );

  const geoDatesDiff = end.diff(start, "days");
  const startDate = start.format("MMMM DD, YYYY");
  const endDate = end.format("MMMM DD, YYYY");

  // Chart scale
  const maxVisits = Math.max(...frequencyData.map(item => item.Visits));
  const getTickInterval = max => {
    if (max <= 10) return 1;
    if (max <= 50) return 5;
    if (max <= 100) return 10;
    if (max <= 500) return 50;
    return 100;
  };

  const tickInterval = getTickInterval(maxVisits);
  const roundedMax = Math.ceil(maxVisits / tickInterval) * tickInterval;

  const scale = {
    Visits: {
      min: 0,
      tickInterval: tickInterval,
      max: roundedMax,
    },
  };

  return (
    <Card
      title={
        <CardTitleText
          text={`${
            customLabel ? customLabel : "Walk Ins"
          }: Frequency Times of Visit`}
        />
      }
      style={{
        height: "34.6em",
      }}
      bodyStyle={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: "2%",
      }}
      headStyle={{ borderWidth: "2px" }}
      bordered
      extra={
        explanation ? (
          <ExtraInfo placement={"top"} title={explanation} />
        ) : (
          false
        )
      }
    >
      <Row type="flex" justify="center">
        <CardText
          text={`${startDate} - ${endDate} (${geoDatesDiff} days) - ${formatWholeNumberNaN(
            totalVisits
          )} Visits`}
        />
      </Row>
      <Chart
        height={315}
        data={frequencyData}
        scale={scale}
        forceFit
        padding={[30, 20, 60, 40]}
      >
        <Axis name="Times of Visit" title={false} />
        <Axis
          name="Visits"
          title={false}
          label={{
            formatter(visits) {
              return formatWholeNumber(visits);
            },
          }}
        />
        <Tooltip crosshairs={{ type: "y" }} />
        <Geom
          type="lineStack"
          position="Times of Visit*Visits"
          color="#b2d5ff"
          size={0}
        />
        <Geom
          type="areaStack"
          position="Times of Visit*Visits"
          color="#b2d5ff"
          size={0}
        />
      </Chart>
    </Card>
  );
};

export default FrequencyChartCard;
