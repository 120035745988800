import { Card, Spin } from "antd";
import React, { useEffect, useState } from "react";
import DonutChart from "../../../../shared/charts/DonutChart";

const PageViewDonutChart = ({ data }) => {
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    if (data && data.length > 0) {
      const totalCount = data.reduce((acc, curr) => acc + curr.counts, 0);
      setTotalCount(totalCount);
    }
  }, [data]);

  return (
    <Card title={"Breakdown"}>
      <Spin spinning={false}>
        <DonutChart
          chartData={data}
          totalCount={totalCount}
          showCenterText={true}
          centerText="Transactions"
        />
      </Spin>
    </Card>
  );
};

export default PageViewDonutChart;
