import React from "react";
import { Card, Col, Row, Spin, Typography } from "antd";
import moment from "moment";

const { Text } = Typography;

// Configurable action labels
const defaultHistoryConfig = {
  action: {
    DELETED: "deleted",
    CREATED: "created",
    UPDATED: "updated",
    PENDING_DELETE: "deleted",
    PENDING_CREATE: "created",
    PENDING_UPDATE: "updated",
  },
};

// Convert nested field names to a more human-readable format
const formatNestedFieldName = fieldName => {
  if (!fieldName) return fieldName;

  return fieldName
    .split(".") // Split by `.`
    .map(part => {
      if (!isNaN(part)) return `[${part}]`; // Format array indices like `[0]`
      if (part === part.toUpperCase()) return part.toLowerCase(); // Skip if already lowercase
      return part
        .replace(/([A-Z])/g, " $1") // Convert camelCase to readable format
        .toLowerCase();
    })
    .join(" -> "); // Join with `->` for a clear hierarchy
};

// Format values for display (for non-object values)
const valueFormatter = value => {
  if (typeof value === "boolean") {
    return value.toString();
  } else if (moment(value, moment.ISO_8601).isValid()) {
    return moment(value).format("ll");
  } else if (Array.isArray(value)) {
    return null;
  } else if (typeof value === "object" && value !== null) {
    return null; // For objects, just return [Object]
  } else if (typeof value === "string" && value.includes("_")) {
    return value.replace(/_/g, " "); // Replace underscores with spaces
  } else {
    return value;
  }
};

// Generic History component
export const Histories = ({
  title = "HISTORY",
  histories = [],
  historyConfig = defaultHistoryConfig,
  loading = false,
}) => {
  return (
    <Card title={title}>
      {loading && <Spin />}

      {histories &&
        histories.length > 0 &&
        histories
          .sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)) // Sort descending by date
          .map((history, index) => (
            <div
              key={index}
              style={{
                paddingTop: 24,
                paddingBottom: 24,
                borderBottom: "1px solid #0000000f",
              }}
            >
              <Row>
                <Col span={20}>
                  <div>
                    <span>&#9702;</span>{" "}
                    <strong>
                      {(history && history.author.name) || "Unknown"}
                    </strong>{" "}
                    <span>
                      {historyConfig.action[history.action] || "updated"}
                    </span>{" "}
                    <span>{formatNestedFieldName(history.data.fieldName)}</span>{" "}
                    {valueFormatter(history.data.oldValue) && (
                      <Text ellipsis>
                        {" "}
                        from{" "}
                        <strong>{valueFormatter(history.data.oldValue)}</strong>
                      </Text>
                    )}{" "}
                    {valueFormatter(history.data.newValue) && (
                      <Text ellipsis>
                        {history.data.oldValue && <span>to </span>}
                        <strong>{valueFormatter(history.data.newValue)}</strong>
                      </Text>
                    )}
                  </div>
                </Col>
                <Col span={4} style={{ textAlign: "end" }}>
                  <Typography.Text type="secondary">
                    {moment(history.createdAt)
                      .utc()
                      .format("MM/DD/YYYY HH:mm [GMT]")}
                  </Typography.Text>
                </Col>
              </Row>
            </div>
          ))}

      {histories && histories.length === 0 && !loading && (
        <Text>No history available</Text>
      )}
    </Card>
  );
};
