import gql from "graphql-tag";

const GET_HISTORIES = gql`
  query historyLogs($where: HistoryLogWhereInput!) {
    historyLogs(where: $where) {
      id
      author {
        id
        name
      }
      action
      type
      referenceId
      data
      createdAt
    }
  }
`;

export default GET_HISTORIES;
