import React from "react";
import { MapConfig } from "../../../../platform/shared/maps/configs/mapConfigs";
import {
  getFormattedAddress,
  getGeoCodes,
} from "../../../../platform/shared/maps/utils/mapUtils";
import { message } from "antd";
import { camelCase, isEmpty } from "lodash";
import { stateList } from "../../../utils/constants/constants";
import moment from "moment";
import { parseOverlayToGeoTarget } from "../../../utils/geoTargets";
import { GoogleApiWrapper } from "google-maps-react";
import googleMapsConfig from "../../../../platform/shared/maps/googlemaps";

// IMPORTANT NOTE: To the next dev that will work on the new screens please change this controller to a functional component
class RetailStoresMainController extends React.Component {
  state = {
    selectedOrg: null,
    parentState: {
      selectedLicense: {},
      zoomLevel: 10,
      initialLocation: {
        latitude: "",
        longitude: "",
      },
      polygons: [],
      name: "",
      street: "",
      city: "",
      zip: "",
      tags: [],
      start: "",
      end: "",
      lat: null,
      lng: null,
      formattedAddress: "",
      state: "",
      country: "",
      description: "",
      locationLoading: false,
      email: "",
      contact: "",
      phone: "",
      url: "",
      hrs: "",
      locationId: "",
      geoFrameId: "",
      tablet: window.matchMedia("(max-width: 1100px)").matches,
      locationLoaded: false,
      id: "",
      tempName: "",
      selectedGeoTargets: [],
      inProgressOverlay: [],
      cart: "",
      menuDataKey: "",
      geoframe: [],
      province: "",
    },
    isTargetingPreviewModalOpen: false,
    targetingLocationsPreviewData: [],
    retailLocationList: [],
    selectedLocations: [],
    attributionAudienceName: "",
    locationLoaded: false,
    targetingAudiences: [],
  };

  componentDidMount = () => {
    let loggedInOrg = this.props.loggedInOrg;

    this.setState({
      selectedOrg: {
        ...loggedInOrg,
      },
    });
  };

  componentDidUpdate(prevProps) {
    const { locationList } = this.props;

    if (!this.props.loadingLocation && !this.state.locationLoaded) {
      const locations =
        locationList && locationList.locations ? locationList.locations : [];

      this.setState({
        locationLoaded: true,
        retailLocationList: locations,
      });
    }
  }

  changeLocationMap = place => {
    let tempAdd;

    if (place.length === 1) {
      const {
        name,
        address_components,
        geometry,
        formatted_address,
      } = place[0];

      if (formatted_address) {
        tempAdd = formatted_address.split(",");
        tempAdd.shift();

        const joinAdd = tempAdd.join(",");
        const country =
          tempAdd && tempAdd.length && tempAdd[tempAdd.length - 1]
            ? // Check for string equality
              typeof tempAdd[tempAdd.length - 1] === "string"
              ? tempAdd[tempAdd.length - 1].trim()
              : tempAdd[tempAdd.length - 1]
            : "";

        this.onChange("country", country ? country : "USA");
        this.onChange("formattedAddress", joinAdd);
      }

      if (geometry) {
        const { location } = geometry;
        if (location) {
          this.onChange("lat", location.lat());
          this.onChange("lng", location.lng());
        }
      }

      if (address_components) {
        this.onChange("tempName", name ? name : "");
        this.onChange(
          "street",
          address_components[0] && address_components[1]
            ? `${address_components[0].long_name} ${address_components[1].long_name}`
            : ""
        );
        this.onChange(
          "city",
          address_components[3] ? `${address_components[3].long_name}` : ""
        );
        const getState =
          address_components[5] && address_components[5].long_name
            ? stateList.filter(
                state => state === address_components[5].long_name.toUpperCase()
              )
            : "";

        this.onChange(
          "state",
          address_components[5] && address_components[5].long_name
            ? `${getState}`
            : ""
        );

        this.onChange(
          "country",
          address_components[6] && address_components[6].short_name
            ? `${
                address_components[6].short_name.includes("CA")
                  ? "CANADA"
                  : "USA"
              }`
            : ""
        );

        this.onChange(
          "zip",
          address_components[7] ? `${address_components[7].long_name}` : ""
        );
      }

      //can add in everything we can on a places match
    } else {
      console.log("we have many need exact");
    }
  };

  onChange = (key, value) => {
    let updateParentState = { ...this.state.parentState };

    updateParentState[key] = value;

    this.setState({
      parentState: updateParentState,
    });
  };

  onCsvUploadComplete = async parsedData => {
    let results = [];
    const { countryType } = MapConfig;
    const formattedData = parsedData.map((data, key) => {
      const formattedKeyValue = {};
      Object.keys(data).map(key => {
        return (formattedKeyValue[camelCase(key)] = data[key]);
      });

      return formattedKeyValue;
    });

    results = this.getTableData(formattedData);

    Promise.all(results).then(values => {
      const { retailLocationList, selectedOrg } = this.state;
      const filteredLocations = retailLocationList
        .filter(location => location.org && location.org.id === selectedOrg.id)
        .filter(location => location.audienceType === "TARGETING");

      const previewData = formattedData
        .map((data, index, array) => {
          const isDuplicate =
            array.findIndex(name => name.name === data.name) !== index;
          return isDuplicate ? { ...data, duplicate: true } : data;
        })
        .map((data, key) => {
          if (values[key].status === "OK") {
            const place = values[key].results[0];
            const { state, city, zip, country, address } = getFormattedAddress({
              result: place,
            });

            const matchedLocation = filteredLocations.find(
              location => location.name === data.name
            );

            return {
              ...data,
              id: key,
              matched: matchedLocation || data.duplicate ? false : true,
              selected: matchedLocation || data.duplicate ? false : true,
              state: state.toUpperCase(),
              city,
              zip,
              street: address,
              sourceAddress: data.address,
              formattedAddress: place.formatted_address,
              country: countryType[country],
              coordinate: place.geometry.location,
              errorMessage:
                matchedLocation || data.duplicate
                  ? "Location name already exists"
                  : "",
            };
          }

          return {
            ...data,
            id: key,
            matched: false,
            selected: false,
            sourceAddress: data.address,
            formattedAddress: "",
          };
        });

      this.setState({
        isTargetingPreviewModalOpen: true,
        targetingLocationsPreviewData: previewData,
      });
    });
  };

  getTableData = locations => {
    const results = [];
    for (let i = 0; i < locations.length; i++) {
      const location = locations[i];
      const fullAddress = `${location.address} ${location.city} ${location.state} ${location.zip}`
        .split(" ")
        .join("+");
      results.push(getGeoCodes(fullAddress));
    }

    return results;
  };

  createOrgLocation = async () => {
    message.loading("Action in progress..", 0);
    const {
      currentuser: { id, role },
      createLocation,
      createLocationWithGeotarget,
    } = this.props;

    const { parentState, retailLocationList } = this.state;

    let tempRetailLocationList = retailLocationList.filter(() => {
      return true;
    });

    const {
      name,
      description,
      street,
      city,
      state,
      country,
      lat,
      lng,
      email,
      url,
      phone,
      contact,
      zip,
      inProgressOverlay,
      formattedAddress,
      cart,
      menuDataKey,
      locationKey,
      audienceType,
      province,
    } = parentState;

    const { circles, polygons } = parseOverlayToGeoTarget(inProgressOverlay);

    try {
      let geoId = null;
      if (circles.length !== 0 || polygons.length !== 0) {
        await this.props
          .createGeoTargetLocation({
            variables: {
              name: name,
              orgID: role.org.id,
              isBase: true,
              circles: circles,
              polygons: polygons,
              start: moment()
                .subtract(90, "days")
                .format(),
              end: moment().format(),
            },
          })
          .then(val => {
            geoId =
              val &&
              val.data &&
              val.data.createGeoTarget &&
              val.data.createGeoTarget.id;
          });
      }
      const data = {
        audienceType,
        cart: cart === "" ? "NOCART" : cart,
        circles,
        city,
        contact,
        country,
        description,
        email,
        end: moment().format(),
        formattedAddress,
        geoId,
        isBase: true,
        lat,
        lng,
        locationKey,
        menuDataKey,
        name,
        orgId: role.org.id,
        phone,
        polygons,
        start: moment()
          .subtract(90, "days")
          .format(),
        state,
        street,
        url,
        zip,
        province,
        createdBy: id,
      };

      const response =
        circles.length !== 0 || polygons.length !== 0
          ? await createLocationWithGeotarget(data)
          : await createLocation(data);

      if (response.status === "success") {
        tempRetailLocationList.unshift(response.data);

        this.setState({
          retailLocationList: tempRetailLocationList,
        });

        message.destroy();
        message.success(`Successfully created new Location`);

        return response.data;
      }
    } catch (err) {
      console.log(err);
      message.destroy();
      message.error(`Error creating new Location`);
    }
  };

  deleteLocation = async ids => {
    message.loading("Deleting Location(s)..", 0);
    const { deleteLocation } = this.props;
    const { retailLocationList } = this.state;
    let tempRetailLocationList = [...retailLocationList];
    let successCount = 0;
    let errors = [];

    for (const Id of ids) {
      let index = tempRetailLocationList.findIndex(
        location => location.id === Id
      );
      if (index !== -1) {
        const response = await deleteLocation({
          variables: {
            Id,
          },
        });

        if (response) {
          tempRetailLocationList.splice(index, 1);
          successCount++;
        } else {
          message.destroy();
          errors.push(
            `Error deleting location with ID ${Id}: ${response.error}`
          );
        }
      }
    }

    this.setState({
      retailLocationList: tempRetailLocationList,
    });

    if (successCount > 0) {
      message.destroy();
      message.success(`Successfully deleted ${successCount} location(s).`);
    }

    if (errors.length > 0) {
      message.destroy();
      errors.forEach(error => message.error(error));
    }
  };

  deleteAudience = async selectedIds => {
    message.loading("Deleting Audience(s)...", 0);
    let successCount = 0;
    let failCount = 0;

    for (const id of selectedIds) {
      try {
        const { data } = await this.props.deleteAudienceLocation({
          variables: { Id: id },
        });
        if (data && data.deleteAudience && data.deleteAudience.id) {
          successCount++;
        } else {
          failCount++;
        }
      } catch (error) {
        console.error(`Error deleting audience with id ${id}:`, error);
        failCount++;
      }
    }

    message.destroy();
    if (successCount > 0) {
      message.success(`Successfully deleted ${successCount} audience(s)`);
    }
    if (failCount > 0) {
      message.error(`Failed to delete ${failCount} audience(s)`);
    }

    return { successCount, failCount };
  };

  editRetailLocation = async data => {
    let updateParentState = { ...this.state.parentState };

    const {
      id,
      name,
      description,
      street,
      city,
      state,
      country,
      lat,
      lng,
      email,
      url,
      phone,
      contact,
      formattedAddress,
      zip,
      geoframe,
      transConfig,
      locationKey,
    } = data;

    let shapes = [];
    shapes.push(geoframe);

    const start = geoframe ? geoframe.start : null;
    const end = geoframe ? geoframe.end : null;

    const { cart, menuDataKey } = transConfig;

    updateParentState["id"] = id;
    updateParentState["name"] = name;
    updateParentState["description"] = description;
    updateParentState["street"] = street;
    updateParentState["city"] = city;
    updateParentState["state"] = state;
    updateParentState["country"] = country;
    updateParentState["lat"] = lat;
    updateParentState["lng"] = lng;
    updateParentState["email"] = email;
    updateParentState["url"] = url;
    updateParentState["phone"] = phone;
    updateParentState["contact"] = contact;
    updateParentState["formattedAddress"] = formattedAddress;
    updateParentState["zip"] = zip;
    updateParentState["selectedGeoTargets"] = shapes;
    updateParentState["inProgressOverlay"] = [];
    updateParentState["cart"] = cart;
    updateParentState["menuDataKey"] = menuDataKey;
    updateParentState["start"] = start;
    updateParentState["end"] = end;
    updateParentState["geoframe"] = geoframe;
    updateParentState["locationKey"] = locationKey;

    this.setState({
      parentState: updateParentState,
    });
  };

  resetParentState = () => {
    this.setState({
      shouldClearShapes: false,
      parentState: {
        selectedLicense: {},
        zoomLevel: 10,
        initialLocation: {
          latitude: "39.462407",
          longitude: "-105.642816",
        },
        polygons: [],
        name: "",
        street: "",
        city: "",
        zip: "",
        tags: [],
        start: "",
        end: "",
        lat: null,
        lng: null,
        formattedAddress: "",
        state: "",
        country: "",
        description: "",
        locationLoading: false,
        email: "",
        contact: "",
        phone: "",
        url: "",
        hrs: "",
        locationId: "",
        geoFrameId: "",
        tablet: window.matchMedia("(max-width: 1100px)").matches,
        locationLoaded: false,
        id: "",
        tempName: "",
        selectedGeoTargets: [],
        inProgressOverlay: [],
        cart: "",
        menuDataKey: "",
        refreshGeotargetDates: false,
        locationKey: "",
      },
    });
  };

  updateOrgLocation = async () => {
    message.loading("Action in progress..", 0);

    const {
      updateLocation,
      updateLocationWithGeotargets,
      currentuser: { role, id: userId },
    } = this.props;

    const { parentState } = this.state;

    const {
      name,
      description,
      street,
      city,
      state,
      country,
      email,
      url,
      phone,
      contact,
      formattedAddress,
      zip,
      id,
      inProgressOverlay,
      cart,
      menuDataKey,
      locationKey,
      audienceType,
      province,
    } = parentState;

    const { circles, polygons } = parseOverlayToGeoTarget(inProgressOverlay);

    try {
      let geoId = "";

      await this.props
        .createGeoTargetLocation({
          variables: {
            name: name,
            orgID: role.org.id,
            isBase: true,
            circles: circles,
            polygons: polygons,
            start: moment()
              .subtract(90, "days")
              .format(),
            end: moment().format(),
          },
        })
        .then(val => {
          geoId =
            val &&
            val.data &&
            val.data.createGeoTarget &&
            val.data.createGeoTarget.id;
        });

      const data = {
        name,
        description,
        street,
        city,
        state,
        country: country || "USA",
        email,
        url,
        phone,
        contact,
        formattedAddress: `${formattedAddress}`,
        zip,
        id,
        cart,
        menuDataKey,
        geoId,
        locationKey,
        audienceType,
        province,
        updatedBy: userId,
      };

      if (data.country !== "USA" && data.country !== "CANADA") {
        delete data.state;
      } else {
        delete data.province;
      }

      const response =
        circles.length !== 0 || polygons.length !== 0
          ? await updateLocationWithGeotargets({
              variables: data,
            })
          : await updateLocation(data);

      if (!isEmpty(response)) {
        this.setState({
          onLocationUpdate: true,
        });

        this.resetParentState();
        message.destroy();
        message.success(`Targeting location successfully updated.`);
        return true;
      } else {
        throw new Error();
      }
    } catch (err) {
      message.destroy();
      message.success(`Failed to update targeting location.`);
    }
  };

  onChangeStateValue = (key, val) => {
    this.setState({ [key]: val });
  };

  onSubmitAudience = async type => {
    message.loading("Action in progress..", 0);

    const {
      createSelectedAudienceLocation,
      currentuser: { id, role },
    } = this.props;
    const { selectedLocations, attributionAudienceName } = this.state;

    try {
      const getAllGeotargetIds =
        selectedLocations &&
        selectedLocations.map(val => {
          const geotargets = val.geoframe;
          const geotargetsId = geotargets && geotargets.id;

          return {
            id: geotargetsId,
          };
        });

      const res = await createSelectedAudienceLocation({
        variables: {
          name: attributionAudienceName,
          type: type,
          geoTargets: getAllGeotargetIds,
          orgid: role.org.id,
          createdBy: id,
        },
      });

      if (res && res.data) {
        if (this.state.targetingAudiences.length === 1) {
          this.setAudienceAmplifyDefault(this.state.targetingAudiences[0]);
        }

        this.setState({
          selectedLocations: [],
        });
        message.destroy();
        message.success(`Successfully created audience`);
      } else {
        throw Error;
      }
    } catch (err) {
      console.log(err);
      message.destroy();
      message.error(`Failed to create audience`);
    }
  };

  setAudienceAmplifyDefault = async record => {
    await this.props.updateManyAudiences({
      variables: {
        orgId: [record.orgs[0].id],
        isAmplifyDefault: false,
      },
    });

    await this.props.updateAudience({
      variables: {
        id: record.id,
        name: record.name,
        description: "",
        type: record.type,
        isAmplifyDefault: true,
      },
    });
  };

  render() {
    return React.cloneElement(this.props.children, {
      ...this.props.children.props,
      currentuser: this.props.currentuser,
      selectedOrg: this.state.selectedOrg,
      locationList: this.state.retailLocationList,
      parentState: this.state.parentState,
      changeLocationMap: this.changeLocationMap,
      onChange: this.onChange,
      onCsvUploadComplete: this.onCsvUploadComplete,
      createLocation: this.createOrgLocation,
      deleteLocation: this.deleteLocation,
      editRetailLocation: this.editRetailLocation,
      resetParentState: this.resetParentState,
      updateOrgLocation: this.updateOrgLocation,
      cartProvider: this.props.cartProvider,
      onChangeStateValue: this.onChangeStateValue,
      selectedLocations: this.state.selectedLocations,
      onSubmitAudience: this.onSubmitAudience,
      attributionAudienceName: this.state.attributionAudienceName,
      attributionAudiences: this.props.attributionAudiences,
      google: this.props.google,
      deleteAudience: this.deleteAudience,
      isSelfService: this.props.permission.isSelfService,
    });
  }
}

export default GoogleApiWrapper(googleMapsConfig)(RetailStoresMainController);
