import React, { useEffect, useState } from "react";
import { formatWholeNumber } from "../../../../../core/utils/campaigns";
import { Avatar, Card, Col, Row, Select, Table } from "antd";
import {
  PrimaryTableRowText,
  TableColumnTitle,
} from "../../../../shared/globalStyling/styledText";
import DonutChart from "../../../../shared/charts/DonutChart";
import { CloudOutlined } from "@ant-design/icons";

const TopCreatives = ({ data = [] }) => {
  const [creatives, setCreatives] = useState(data);
  const [selectedTop, setSelectedTop] = useState("top5");
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    if (data) {
      let filteredData;
      switch (selectedTop) {
        case "top5":
          filteredData = data.slice(0, 5);
          break;
        case "top10":
          filteredData = data.slice(0, 10);
          break;
        case "top20":
          filteredData = data.slice(0, 20);
          break;
        default:
          filteredData = data;
          break;
      }
      setCreatives(filteredData);

      // Calculate total
      if (filteredData.length > 0) {
        const total = filteredData.reduce(
          (acc, curr) => acc + (curr.transactions || 0),
          0
        );
        setTotalCount(total);
      } else {
        setTotalCount(0);
      }
    }
  }, [data, selectedTop]);
  const columns = [
    {
      title: "",
      dataIndex: "url",
      key: "url",
      width: "70px",
      align: "left",
      render: url => {
        return (
          <Avatar
            shape="square"
            size={50}
            icon={<CloudOutlined />}
            src={url === "" || !url ? false : url}
            style={{ marginLeft: "-16px" }}
          />
        );
      },
    },
    {
      title: "",
      dataIndex: "color",
      key: "color",
      width: "15px",
      render: text => {
        return (
          <Row
            type="flex"
            justify="center"
            align="middle"
            style={{
              width: "100%",
            }}
          >
            <div
              style={{
                backgroundColor: text,
                height: "15px",
                width: "15px",
                minWidth: "15px",
                borderRadius: "50px",
                marginLeft: "-16px",
              }}
            />
          </Row>
        );
      },
    },
    {
      title: <TableColumnTitle text={"Ad Unit Name"} />,
      dataIndex: "name",
      key: "name",
      render: text => {
        return <PrimaryTableRowText text={text ? text : ""} />;
      },
    },
    {
      title: <TableColumnTitle text={"Impressions"} />,
      dataIndex: "impressions",
      key: "impressions",
      render: text => {
        return <PrimaryTableRowText text={formatWholeNumber(text)} />;
      },
    },
    {
      title: <TableColumnTitle text={"Transactions"} />,
      dataIndex: "transactions",
      key: "transactions",
      render: text => {
        return <PrimaryTableRowText text={formatWholeNumber(text)} />;
      },
    },
  ];

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24} lg={18}>
        <Card
          title="Top Creatives"
          extra={
            <Select
              style={{ width: "100%" }}
              defaultValue={"top5"}
              options={[
                { label: "Top 5", value: "top5" },
                { label: "Top 10", value: "top10" },
                { label: "Top 20", value: "top20" },
                { label: "All", value: "all" },
              ]}
              value={selectedTop}
              onChange={value => {
                setSelectedTop(value);
              }}
            />
          }
        >
          <Table
            dataSource={creatives}
            columns={columns}
            pagination={{
              position: ["none", "bottomCenter"],
              total: creatives.length,
              responsive: true,
              showSizeChanger: false,
              showLessItems: true,
              size: "default",
            }}
            scroll={{ x: 200, y: 400 }}
            rowKey={({ transactionId }) => transactionId}
          />
        </Card>
      </Col>
      <Col xs={24} lg={6}>
        <Card title="Breakdown - Top Creatives" style={{ height: "100%" }}>
          <DonutChart
            axisName="name"
            position="transactions"
            chartData={creatives}
            showTooltip
            totalCount={totalCount}
            showCenterText={true}
            centerText="Transactions"
          />
        </Card>
      </Col>
    </Row>
  );
};

export default TopCreatives;
