import React from "react";
import {
  PrimaryTableRowText,
  TableColumnTitle,
} from "../../../../shared/globalStyling/styledText";
import { Card, Row, Select, Spin, Table } from "antd";

const TopPageViewTable = ({
  loading = false,
  title = "Top 10 Pages",
  topData = [],
  setSelectedTop,
  selectedTop = "top10",
}) => {
  const columns = [
    {
      title: "",
      dataIndex: "color",
      key: "color",
      width: "30px",
      render: text => {
        return (
          <Row
            type="flex"
            justify="center"
            align="middle"
            style={{
              width: "100%",
            }}
          >
            <div
              style={{
                backgroundColor: text,
                height: "15px",
                width: "15px",
                minWidth: "15px",
                borderRadius: "50px",
              }}
            />
          </Row>
        );
      },
    },
    {
      title: <TableColumnTitle text={"Page"} />,
      index: "value",
      key: "value",
      width: "60%",
      render: (text, { value }) => {
        return <PrimaryTableRowText text={value} />;
      },
    },
    {
      title: <TableColumnTitle text={"Transactions"} />,
      index: "counts",
      key: "counts",
      render: (text, { counts }) => {
        return <PrimaryTableRowText text={counts} />;
      },
    },
    {
      title: <TableColumnTitle text={"Actions"} />,
      key: "actions",
      render: (text, record) => {
        return (
          <a href={record.value} target="_blank" rel="noopener noreferrer">
            Visit Page
          </a>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <Card
        title={title}
        extra={
          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <span>Show Top</span>
            <Select
              style={{ width: "80px" }}
              defaultValue={"top5"}
              options={[
                { label: "5", value: "top5" },
                { label: "10", value: "top10" },
                { label: "20", value: "top20" },
                { label: "All Time", value: "all" },
              ]}
              onChange={value => {
                setSelectedTop(value);
              }}
            />
          </div>
        }
      >
        <Spin spinning={loading}>
          <Table
            scroll={{ x: 800, y: 400 }}
            dataSource={topData}
            rowKey={record => record.value}
            pagination={
              selectedTop !== "top10" && selectedTop !== "top5"
                ? {
                    position: ["none", "bottomCenter"],
                    total: topData.length,
                    responsive: true,
                    showSizeChanger: false,
                    showLessItems: true,
                    size: "default",
                  }
                : false
            }
            columns={columns}
          />
        </Spin>
      </Card>
    </React.Fragment>
  );
};

export default TopPageViewTable;
