import React from "react";

// Ant Design
import { Table, Tag, Tooltip } from "antd";
import moment from "moment";

export const demographicsAudienceTableColumnMobile = [
  {
    title: "Audience Name",
    dataIndex: "name",
    key: "name",
    render: (_, record) => {
      return (
        <div
          style={{
            border: "1px solid black",
            padding: "20px",
            borderRadius: "20px",
            borderLeft: "5px solid #1677FF",
          }}
        >
          <p style={{ marginBottom: "0.1em" }}>
            {record.name} {record.isArchived && <Tag color="red">Archived</Tag>}
          </p>
          <p style={{ marginBottom: "0.1em" }}>
            <b>Created On</b> {moment(record.createdAt).format("MMM D, YYYY")}
          </p>
          <p style={{ marginBottom: "0.1em" }}>
            <b>Reach</b>{" "}
            {record && record.reachCount
              ? record.reachCount.toLocaleString()
              : 0}
          </p>
        </div>
      );
    },
  },
];

export const demographicsAudienceTableColumn = [
  Table.SELECTION_COLUMN,
  Table.EXPAND_COLUMN,
  {
    title: "Audience Name",
    dataIndex: "name",
    key: "name",
    render: text => <span>{text}</span>,
  },
  {
    title: "Reach",
    dataIndex: "reachCount",
    key: "reachCount",
    render: reachCount => {
      return <span>{reachCount ? reachCount.toLocaleString() : 0}</span>;
    },
  },
  {
    title: "Created",
    dataIndex: "createdAt",
    key: "createdAt",
    render: createdAt => moment(createdAt).format("M/D/YYYY"),
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (status, record) => {
      let tagColor; // Will hold the final tag component
      let tagTitle; // Tooltip message for each status

      status = getStatus({
        isArchived: record.isArchived,
        isPushedToS3MJ: record.isPushedToS3MJ,
        isPushedToS3Statara: record.isPushedToS3Statara,
        isPublished: record.isPublished,
      });

      // Switch case to handle multiple status configurations
      switch (status) {
        case "Archived":
          tagColor = "red";
          tagTitle = status;
          break;

        case "Ready to be Published":
          tagColor = "yellow";
          tagTitle = status;
          break;

        case "Publishing in Progress":
          tagColor = "orange";
          tagTitle = status;
          break;

        case "Published":
          tagColor = "green";
          tagTitle = status;
          break;

        default:
          tagColor = "red";
          tagTitle = "Unpublished";
          break;
      }

      // Return a tooltip-wrapped tag component
      return (
        <Tooltip title={tagTitle}>
          <Tag color={tagColor}>{tagTitle}</Tag>
        </Tooltip>
      );
    },
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    render: text => <span>{text}</span>,
  },
  {
    title: "Tags",
    key: "tags",
    dataIndex: "tags",
    render: (_, { tags }) => (
      <>
        {tags &&
          tags
            .sort((a, b) => a.localeCompare(b))
            .map(tag => {
              return <Tag key={tag}>{tag}</Tag>;
            })}
      </>
    ),
  },
];

const getStatus = ({
  isArchived,
  isPushedToS3MJ,
  isPushedToS3Statara,
  isPublished,
}) => {
  if (isArchived) {
    return "Archived";
  }
  if (isPublished) {
    return "Published";
  }
  if (isPushedToS3Statara) {
    return "Publishing in Progress";
  }
  if (isPushedToS3MJ) {
    return "Ready to be Published";
  }
  return "Unpublished";
};
