import React from "react";
import moment from "moment";

const colorPicker = (date, value) => {
  let color = "success";
  if (!date) {
    color = "secondary";
  } else if (date && value < 1) {
    color = "danger";
  }
  return color;
};

const typePicker = (date, value) => {
  let color = "success";
  if (!date) {
    color = "default";
  } else if (date && value < 1) {
    color = "error";
  }
  return color;
};

const TagsMainController = ({ ...props }) => {
  const {
    dataMultiOrgTags,
    signalTagsData,
    requestAssistance,
    createImpressionTag,
    refetch,
    currentOrgId,
    isAttentionSignals,
  } = props;

  const formattedTags = dataMultiOrgTags.eventsTargets.map(tag => {
    const {
      transactions,
      pageViews,
      signups,
      name,
      id,
      tagUpdateDate,
      eventTags,
      orgs,
      isSignUps,
    } = tag;
    const appId = eventTags[0].appId[0];
    return {
      id,
      name,
      advertiser: orgs[0].name,
      measuredEvents: {
        pageViews,
        transactions,
        signups,
        isSignUps,
        date: tagUpdateDate
          ? moment(tagUpdateDate).format("MM/DD/YYYY HH:MM")
          : "Signal Unknown",
        colors: {
          transactions: typePicker(tagUpdateDate, transactions),
          pageViews: typePicker(tagUpdateDate, pageViews),
          signups: typePicker(tagUpdateDate, signups),
        },
        typeColors: {
          transactions: colorPicker(tagUpdateDate, transactions),
          pageViews: colorPicker(tagUpdateDate, pageViews),
          signups: colorPicker(tagUpdateDate, signups),
        },
      },
      appId,
    };
  });

  const publishersTags = [];
  const measurementsTags = [];
  const impressionsTags = [];

  // Process publisher tags
  signalTagsData.editorials.forEach(publisher => {
    const { clicksTag, transactionsTag } = publisher;
    const publisherExisting = publishersTags.find(p => p.id === clicksTag.id);
    const measurementExisting = measurementsTags.findIndex(
      p => p.id === transactionsTag.id
    );

    if (!publisherExisting) {
      publishersTags.push({
        id: clicksTag.id,
        name: clicksTag.name,
        advertiser: clicksTag.orgs[0].name,
        domain: clicksTag.orgs[0].website,
        pageViews: clicksTag.pageViews,
        tagId: clicksTag.eventTags[0].appId[0],
      });
    }

    if (measurementExisting === -1) {
      measurementsTags.push({
        id: transactionsTag.id,
        name: transactionsTag.name,
        advertiser: transactionsTag.orgs[0].name,
        funnels: 1,
        events: [
          {
            event: "Transactions",
            value: transactionsTag.transactions,
            date: transactionsTag.tagUpdateDate,
          },
        ],
        tagId: transactionsTag.eventTags[0].appId[0],
      });
    } else {
      measurementsTags[measurementExisting].funnels += 1;
    }
  });

  // Process impression tags
  signalTagsData.impressionTags.forEach(impressionsTag => {
    impressionsTags.push({
      id: impressionsTag.id,
      name: impressionsTag.name,
      dsp: impressionsTag.dsp,
      tagId: impressionsTag.eventTags[0].appId[0],
    });
  });

  // Process measurement tags
  signalTagsData.measurementsTags.forEach(measurementsTag => {
    const measurementExisting = measurementsTags.findIndex(
      p => p.id === measurementsTag.id
    );

    if (measurementExisting === -1) {
      measurementsTags.push({
        id: measurementsTag.id,
        name: measurementsTag.name,
        advertiser: measurementsTag.orgs[0].name,
        funnels: 0,
        events: [
          {
            event: "Transactions",
            value: measurementsTag.transactions,
          },
        ],
        tagId: measurementsTag.eventTags[0].appId[0],
      });
    }
  });

  // Process advertiser tags
  signalTagsData.advertisers.forEach(advertiser => {
    const { transactionsTag, transactionsTags } = advertiser;
    if (transactionsTag) {
      const { orgs } = transactionsTag;
      const measurementExisting = measurementsTags.findIndex(
        p => p.id === transactionsTag.id
      );

      if (measurementExisting === -1) {
        measurementsTags.push({
          id: transactionsTag.id,
          name: transactionsTag.name,
          advertiser: orgs[0].name,
          funnels: 1,
          events: [
            {
              event: "Transactions",
              value: transactionsTag.transactions,
            },
          ],
          tagId: transactionsTag.eventTags[0].appId[0],
        });
      } else {
        measurementsTags[measurementExisting].funnels += 1;
      }
    } else {
      transactionsTags.forEach(tag => {
        const { eventsTarget } = tag;
        const { orgs } = eventsTarget;
        const measurementExisting = measurementsTags.findIndex(
          p => p.id === eventsTarget.id
        );

        if (measurementExisting === -1) {
          measurementsTags.push({
            id: eventsTarget.id,
            name: eventsTarget.name,
            advertiser: orgs[0].name,
            funnels: 1,
            events: [
              {
                event: "Transactions",
                value: eventsTarget.transactions,
              },
            ],
            tagId: eventsTarget.eventTags[0].appId[0],
          });
        } else {
          measurementsTags[measurementExisting].funnels += 1;
        }
      });
    }
  });

  // Process publisher domain tags
  signalTagsData.publisherDomainTags.forEach(publisherDomainTag => {
    const publisherExisting = publishersTags.findIndex(
      p => p.id === publisherDomainTag.id
    );

    if (publisherExisting === -1) {
      publishersTags.push({
        id: publisherDomainTag.id,
        advertiser: publisherDomainTag.orgs[0].name,
        name: publisherDomainTag.name,
        domain: publisherDomainTag.orgs[0].website,
        pageViews: publisherDomainTag.pageViews,
        tagId: publisherDomainTag.eventTags[0].appId[0],
      });
    }
  });

  const onImressionsTagCreate = async input => {
    const { name, dsp } = input;
    await createImpressionTag({
      variables: {
        name,
        dsp,
        orgId: currentOrgId,
      },
    });
  };

  return React.cloneElement(props.children, {
    ...props.children.props,
    formattedTags,
    publishersTags,
    measurementsTags,
    impressionsTags,
    onImressionsTagCreate,
    requestAssistance,
    refetch,
    isAttentionSignals,
  });
};

export default TagsMainController;
