import React, { useEffect, useState } from "react";
import { Card, Form, Input, Radio, Space, Typography } from "antd";
import CampaignRequestorAudienceDialog from "./CampaignRequestorAudienceDialog";
import { CampaignRequestorAudienceTable } from "./CampaignRequestorAudienceTable";

const { Text } = Typography;

export const CampaignRequestorAudience = ({
  form,
  index,
  fieldKey,
  restField,
  name,
  setPreviousValues,
}) => {
  const campaigns = form.getFieldValue("campaigns");
  const isRegulated = form.getFieldValue("isRegulated");
  const firstPartyDataAudiences =
    campaigns[index].firstPartyDataAudiences || [];

  const [showFirstPartyDataModal, setShowFirstPartyDataModal] = useState(false);
  const [audienceType, setAudienceType] = useState(
    campaigns[index].audienceType || null
  );

  useEffect(() => {
    setTimeout(() => {
      setAudienceType(form.getFieldValue(["campaigns", index, "audienceType"]));
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  return (
    <>
      <Card title="AUDIENCE">
        <Space direction="vertical" size="large" style={{ width: "100%" }}>
          <Text>Audience type for this line item:</Text>
          <Form.Item
            {...restField}
            name={[name, "audienceType"]}
            fieldKey={[fieldKey, "audienceType"]}
            rules={[
              {
                required: true,
                message: "Please select an audience type",
              },
            ]}
          >
            <Radio.Group
              value={audienceType}
              onChange={e => setAudienceType(e.target.value)}
            >
              <Space direction="vertical">
                <Radio value="FIRST_PARTY_DATA">First Party Data</Radio>
                {/* <Radio value="THIRD_PARTY_DATA">Third Party Data</Radio> */}
                <Radio value="ALL_USERS">
                  All users in targeting area {isRegulated ? "aged 21+" : ""}
                </Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
        </Space>
        {audienceType === "FIRST_PARTY_DATA" && (
          <CampaignRequestorAudienceTable
            name={name}
            restField={restField}
            fieldKey={fieldKey}
            setShowFirstPartyDataModal={setShowFirstPartyDataModal}
            editable={true}
            form={form}
            index={index}
            audienceType={audienceType}
            setPreviousValues={setPreviousValues}
          />
        )}

        {/* Create hidden input for firstPartyDataAudiences */}
        <Form.Item
          {...restField}
          name={[name, "firstPartyDataAudiences"]}
          fieldKey={[fieldKey, "firstPartyDataAudiences"]}
          hidden
          rules={[
            {
              required: audienceType === "FIRST_PARTY_DATA",
              message: "Please select a at least one first party audience",
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Card>

      {showFirstPartyDataModal && (
        <CampaignRequestorAudienceDialog
          visible={showFirstPartyDataModal}
          setVisible={setShowFirstPartyDataModal}
          orgId={form.getFieldValue("advertiserId")}
          selectedAudiences={firstPartyDataAudiences}
          onAddSelections={audiences => {
            const updatedCampaigns = campaigns.map((campaign, i) => {
              if (i === index) {
                return {
                  ...campaign,
                  firstPartyDataAudiences: audiences.map(audience => {
                    // Check if audience already exists in the list
                    const existingAudience = firstPartyDataAudiences.find(
                      a => a.name === audience.name
                    );

                    if (existingAudience) {
                      return existingAudience;
                    }

                    delete audience.id;
                    return audience;
                  }),
                };
              }
              return campaign;
            });
            form.setFieldsValue({
              campaigns: updatedCampaigns,
            });
            setPreviousValues({
              ...form.getFieldsValue(),
              campaigns: updatedCampaigns,
            });
          }}
        />
      )}
    </>
  );
};
