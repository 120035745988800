import React, { useEffect, useState } from "react";
import { Card, Col, Table, Input, Button, Select, Row, Modal, Tag } from "antd";
import { taskTypes, clientTypes, statusTypes } from "../configs/task.config";
import { useTaskContext } from "../../../../core/components/tasks/useTaskContext";
import { withRouter } from "react-router-dom";
import { useUserContext } from "../../../../core/components/users/useUserContext";
import { debounce } from "lodash";
import qs from "qs";
import moment from "moment";
import useSearchParams from "../../../../core/hooks/useSearchParams";

const { Option } = Select;

const TaskBoard = ({ history }) => {
  const {
    currentUser,
    fetchTasksPaginated,
    task,
    taskBoardPagination,
    setTaskBoardPagination,
    tasksLoading,
  } = useTaskContext();
  const {
    fetchBasicUsersDetails,
    basicUsersDetails,
    basicUsersDetailsLoading,
  } = useUserContext();
  const [searchParams, setSearchParams] = useSearchParams({
    assigneeName: "",
    requestType: "",
    status: "",
    clientType: "",
    name: "",
  });

  const [selectedCampaigns, setSelectedCampaigns] = useState([]);
  const [showRelatedCampaignModal, setShowRelatedCampaignModal] = useState(
    false
  );

  useEffect(() => {
    handleFetchTasksPaginated({
      skip:
        (taskBoardPagination.current - 1) * taskBoardPagination.pageSize || 0,
      first: taskBoardPagination.pageSize,
      ...searchParams,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskBoardPagination.pageSize, searchParams]);

  const handleAssigneeFilter = value => {
    const updatedFilters = {
      ...searchParams,
      assigneeName: value === "all" ? "" : value,
    };
    setSearchParams(updatedFilters);
  };

  const handleTaskTypeFilter = value => {
    const updatedFilters = {
      ...searchParams,
      requestType: value === "all" ? "" : value,
    };
    setSearchParams(updatedFilters);
  };

  const handleStatusFilter = value => {
    const updatedFilters = {
      ...searchParams,
      status: value === "all" ? "" : value,
    };
    setSearchParams(updatedFilters);
  };

  const handleClientTypeFilter = value => {
    const updatedFilters = {
      ...searchParams,
      clientType: value === "all" ? "" : value,
    };
    setSearchParams(updatedFilters);
  };

  const handleFetchTasksPaginated = debounce(
    ({
      skip,
      first,
      name = "",
      assigneeName = "",
      requestType = "",
      status = "",
      clientType = "",
    }) => {
      fetchTasksPaginated({
        variables: {
          where: {
            AND: [
              { name_contains: name },
              ...(assigneeName
                ? [{ assigneeUser: { name: assigneeName } }]
                : []),
              ...(requestType ? [{ requestType }] : []),
              ...(status ? [{ status }] : []),
              ...(clientType
                ? [
                    {
                      createdBy: { roles_some: { org: { level: clientType } } },
                    },
                  ]
                : []),
              {
                OR: [
                  {
                    createdBy: {
                      roles_some: {
                        org: {
                          OR: [
                            {
                              parentOrg_some: {
                                id: currentUser.role.org.id,
                              },
                            },
                            {
                              id: currentUser.role.org.id,
                            },
                          ],
                        },
                      },
                    },
                  },
                ],
              },
            ],
          },
          orderBy: "updatedAt_DESC",
          skip,
          first,
        },
      });
    },
    500
  );

  const fetchBasicUsers = debounce((name = "") => {
    fetchBasicUsersDetails({
      variables: {
        first: 10,
        skip: 0,
        where: {
          AND: [
            {
              roles_some: {
                org: {
                  level: "PLATFORM",
                },
                roleItems_some: {
                  feature: "TASK_MANAGEMENT",
                },
              },
              name_contains: name,
            },
          ],
        },
      },
    });
  }, 500);

  const handleTableChange = ({ current, pageSize }) => {
    setTaskBoardPagination(prev => ({ ...prev, current, pageSize }));

    const newQuery = { ...searchParams };
    const queryString = qs.stringify(newQuery, { addQueryPrefix: true });
    history.push(`/tasks/main/${current}${queryString}`);

    handleFetchTasksPaginated({
      skip: (current - 1) * pageSize || 0,
      first: pageSize,
      ...newQuery,
    });
  };

  const handleSearchChange = name => {
    const updatedFilters = { ...searchParams, name };
    setSearchParams(updatedFilters);
  };

  const level =
    currentUser &&
    currentUser.role &&
    currentUser.role.org &&
    currentUser.role.org.level;

  const columns = [
    {
      title: "Task Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <Button
          type="link"
          onClick={() => {
            history.push(`/tasks/details/${record.id}`);
          }}
        >
          {text}
        </Button>
      ),
    },
    {
      title: "Due Date",
      dataIndex: "dueDate",
      key: "dueDate",
      sorter: (a, b) => new Date(a.dueDate) - new Date(b.dueDate),
      render: dueDate =>
        dueDate ? new Date(dueDate).toLocaleDateString("en-CA") : "N/A",
    },
    {
      title: "Assignee",
      dataIndex: "assigneeUser",
      key: "assigneeUser",
      render: assigneeUser =>
        assigneeUser && assigneeUser.name ? assigneeUser.name : "N/A",
      onFilter: (value, record) => {
        return (
          record.assigneeUser &&
          record.assigneeUser.name &&
          record.assigneeUser.name.indexOf(value) === 0
        );
      },
    },
    {
      title: "Request Type",
      dataIndex: "requestType",
      key: "requestType",
      render: (requestType, record) => (
        <>
          {requestType || "N/A"}
          <br />
          <span style={{ color: "gray" }}>{record.requestSubType || ""}</span>
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: status => {
        if (!status) return "N/A";
        const formattedStatus = status
          .split("_")
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
        return formattedStatus;
      },
    },
  ];

  if (level === "CHANNEL_PARTNER") {
    columns.push({
      title: "Advertiser",
      dataIndex: "advertiser",
      key: "advertiser",
      render: advertiser =>
        advertiser && advertiser.id ? advertiser.name : "",
    });
  } else {
    columns.push(
      {
        title: "Client Type",
        dataIndex: "clientType",
        key: "clientType",
        render: (_, record) => {
          const orgLevel =
            record.createdBy &&
            record.createdBy.roles &&
            record.createdBy.roles[0] &&
            record.createdBy.roles[0].org &&
            record.createdBy.roles[0].org.level;
          const formattedOrgLevel = orgLevel
            ? orgLevel
                .split("_")
                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ")
            : "N/A";
          return formattedOrgLevel;
        },
      },
      {
        title: "Client",
        dataIndex: "client",
        key: "client",
        render: (_, record) => {
          const orgName =
            record.createdBy &&
            record.createdBy.roles &&
            record.createdBy.roles[0] &&
            record.createdBy.roles[0].org &&
            record.createdBy.roles[0].org.name;
          return (
            <>
              <Tag
                color="geekblue"
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "150px",
                }}
              >
                {orgName || "N/A"}
              </Tag>
            </>
          );
        },
      }
    );
  }

  columns.push({
    title: "Campaigns",
    dataIndex: "campaigns",
    key: "campaigns",
    render: (_, record) =>
      record.relatedCampaigns && record.relatedCampaigns.length > 0 ? (
        <Button
          type="link"
          onClick={() => {
            setSelectedCampaigns(record.relatedCampaigns);
            setShowRelatedCampaignModal(true);
          }}
        >
          View
        </Button>
      ) : (
        <div style={{ marginLeft: 25 }}>-</div>
      ),
  });

  const campaignColumns = [
    {
      title: "Campaign Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, record) => (
        <text
          onClick={() => history.push(`/campaigns/details/${record.id}`)}
          style={{ cursor: "pointer", color: "#1890ff" }}
        >
          {text}
        </text>
      ),
    },
    {
      title: "Flight Dates",
      dataIndex: "flightDates",
      key: "flightDates",
      sorter: (a, b) => new Date(a.startDate) - new Date(b.startDate),
      render: (_, record) => {
        const startDate = moment(record.startDate).format("MM/DD/YYYY");
        const endDate = moment(record.endDate).format("MM/DD/YYYY");
        return `${startDate} - ${endDate}`;
      },
    },
  ];

  return (
    <>
      <Card
        title="TASKS"
        extra={
          <Row gutter={16} align="middle">
            <Col>
              <Input.Search
                placeholder="Search tasks"
                onSearch={value => handleSearchChange(value)}
                style={{ width: 200 }}
              />
            </Col>
            <Col>
              <Button
                type="primary"
                onClick={() => {
                  history.push(`/tasks/form/`);
                }}
              >
                Create Task
              </Button>
            </Col>
          </Row>
        }
      >
        <Row gutter={16} style={{ marginBottom: 16 }} justify="end">
          <Col span={6}>
            <Row align="middle" gutter={8}>
              <Col flex="none">Assignee</Col>
              <Col flex="auto">
                <Select
                  showSearch
                  defaultValue="all"
                  style={{ width: "100%" }}
                  onFocus={fetchBasicUsers()}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                  onSearch={searchText => fetchBasicUsers(searchText)} // Fetch filtered data on search
                  loading={basicUsersDetailsLoading}
                  onChange={handleAssigneeFilter} // Trigger fetch when "All" is selected
                >
                  <Select.Option value="all">All</Select.Option>
                  {basicUsersDetails.map(user => (
                    <Select.Option key={user.id} value={user.name}>
                      {user.name}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
            </Row>
          </Col>
          <Col span={6}>
            <Row align="middle" gutter={8}>
              <Col flex="none">Request Type:</Col>
              <Col flex="auto">
                <Select
                  defaultValue="all"
                  style={{ width: "100%" }}
                  onChange={handleTaskTypeFilter} // Call the task type filter on change
                >
                  <Option value="all">All</Option>
                  {taskTypes.map(type => (
                    <Option key={type} value={type}>
                      {type}
                    </Option>
                  ))}
                </Select>
              </Col>
            </Row>
          </Col>
          <Col span={6}>
            <Row align="middle" gutter={8}>
              <Col flex="none">Status:</Col>
              <Col flex="auto">
                <Select
                  defaultValue="all"
                  style={{ width: "100%" }}
                  onChange={handleStatusFilter}
                >
                  <Option value="all">All</Option>
                  {Object.keys(statusTypes).map(status => (
                    <Option key={status} value={statusTypes[status]}>
                      {status}
                    </Option>
                  ))}
                </Select>
              </Col>
            </Row>
          </Col>
          <Col span={6}>
            <Row align="middle" gutter={8}>
              <Col flex="none">Client Type</Col>
              <Col flex="auto">
                <Select
                  defaultValue="all"
                  style={{ width: "100%" }}
                  onChange={handleClientTypeFilter}
                >
                  <Option value="all">All</Option>
                  {Object.keys(clientTypes).map(type => (
                    <Option key={type} value={clientTypes[type]}>
                      {type}
                    </Option>
                  ))}
                </Select>
              </Col>
            </Row>
          </Col>
        </Row>
        <Table
          columns={columns}
          dataSource={task}
          loading={tasksLoading}
          pagination={taskBoardPagination}
          onChange={handleTableChange}
        />
      </Card>
      {showRelatedCampaignModal && (
        <Modal
          closable
          visible={showRelatedCampaignModal}
          width={"40%"}
          onCancel={() => setShowRelatedCampaignModal(false)}
          footer={null}
          title="RELATED CAMPAIGNS"
        >
          <Table
            rowKey="id"
            columns={campaignColumns}
            dataSource={selectedCampaigns}
            pagination={{ pageSize: 5 }}
          />
        </Modal>
      )}
    </>
  );
};

export default withRouter(TaskBoard);
