export const getMobileOperatingSystem = userAgent => {
  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  if (/iPad/.test(userAgent) && !window.MSStream) {
    return "iPad";
  }

  if (/iPhone/.test(userAgent) && !window.MSStream) {
    return "iPhone";
  }

  if (/iPod/.test(userAgent) && !window.MSStream) {
    return "iPod";
  }

  if (/CrOS/.test(userAgent) && !window.MSStream) {
    return "Chromebook Computer";
  }

  if (/Macintosh/.test(userAgent)) {
    return "Apple Computer";
  }

  if (/Windows NT/.test(userAgent)) {
    return "Windows Computer";
  }

  //Default Response
  return "Computer";
};

export const determineAdServer = url => {
  if (!url) return "Unknown";

  // Check for common ad server domains
  if (
    url.includes("amazon-adsystem") ||
    url.includes("mopub") ||
    url.includes("adsbynimbus") ||
    url.includes("smartadserver") ||
    url.includes("localhost") ||
    url.includes("googlesyndication") ||
    url.includes("doubleclick") ||
    url.includes("inner-active.mobi") ||
    url.includes("nexage") ||
    url.includes("adcolony") ||
    url.includes("sdk")
  )
    return "Ad Server";

  if (
    url.includes("itunes") ||
    url.includes("play.google") ||
    url.includes("apps.apple.com")
  )
    return "Application";

  // Check if it's likely an encoded/obfuscated ad server URL
  // These often have very long subdomains with random-looking characters
  const suspiciousPatterns = [
    /^[a-zA-Z0-9]{30,}\./, // Very long subdomain
    /^r\d+\./, // Starts with 'r' followed by numbers (common in some ad servers)
    /[0-9a-z]{40,}/, // Very long string of alphanumeric characters
  ];

  for (const pattern of suspiciousPatterns) {
    if (pattern.test(url)) {
      return "Ad Server";
    }
  }

  return "Website";
};
