import React, { useEffect, useState } from "react";
import { Button, Input, Modal, Table } from "antd";

import moment from "moment";
import { debounce } from "lodash";
import { useCampaignRequestorContext } from "../../../core/components/campaignRequestor/useCampaignRequestorContext";
import { formatWholeNumber } from "../../../core/utils/campaigns";

const { Search } = Input;

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    ellipsis: true, // Enables ellipsis for text overflow
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
  {
    title: "Flight Dates",
    dataIndex: "flightDates",
    key: "flightDates",
    render: (_, record) => {
      return `${moment(record.startDate).format("MM/DD/YYYY")} - ${moment(
        record.endDate
      ).format("MM/DD/YYYY")}`;
    },
  },
  {
    title: "Impressions",
    dataIndex: "impressions",
    key: "impressions",
    render: (_, record) => {
      const impressions =
        record &&
        record.aggregateData &&
        record.aggregateData.overallData &&
        record.aggregateData.overallData.aggImpressions;
      return formatWholeNumber(impressions || 0);
    },
  },
];

const CampaignRequestorCloneDialog = ({
  visible,
  setVisible,
  orgId,
  onAddSelections,
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const {
    fetchCampaignOrdersConnection,
    campaignOrdersConnection,
    loadingCampaignOrdersConnection,
    pagination,
    setPagination,
  } = useCampaignRequestorContext();

  const onSelectChange = newSelectedRowKeys => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleAddSelections = () => {
    if (selectedRowKeys.length === 0) {
      onAddSelections([]);
      setVisible(false);
      return;
    }
    const selectedAdGroup = campaignOrdersConnection.filter(adGroup =>
      selectedRowKeys.includes(adGroup.id)
    );
    onAddSelections(selectedAdGroup);
    setVisible(false);
  };

  const rowSelection = {
    type: "radio",
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleFetchCampaignOrders = debounce(
    ({ skip = 0, first = 10, searchName = "" } = {}) => {
      fetchCampaignOrdersConnection({
        variables: {
          where: {
            AND: [
              {
                OR: [
                  {
                    orgs_some: {
                      OR: [{ id: orgId }, { parentOrg_some: { id: orgId } }],
                    },
                  },
                ],
              },
              { status_not: "DRAFT" },
              { status_not: "PENDING" },
              { name_contains: searchName },
            ],
          },
          orderBy: "createdAt_DESC",
          skip,
          first,
        },
      });
    },
    500
  );

  useEffect(() => {
    setPagination({
      current: 1,
      pageSize: 10,
      total: 0,
    });
    handleFetchCampaignOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Modal
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingRight: 20,
            }}
          >
            <span>MY CAMPAIGNS</span>
            <div>
              <Button type="default" onClick={() => handleAddSelections()}>
                Add Selection
              </Button>
            </div>
          </div>
        }
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
        width={1000}
      >
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Search
            placeholder="Search a campaign"
            style={{
              width: 200,
            }}
            onSearch={searchName => {
              console.log({ searchName });
              handleFetchCampaignOrders({ searchName });
            }}
            allowClear
          />
        </div>

        <Table
          rowKey="id"
          rowSelection={rowSelection}
          columns={columns}
          pagination={pagination}
          dataSource={campaignOrdersConnection}
          loading={loadingCampaignOrdersConnection}
          onChange={pagination => {
            setPagination(pagination);
            handleFetchCampaignOrders({
              skip: (pagination.current - 1) * pagination.pageSize,
              first: pagination.pageSize,
            });
          }}
        />
      </Modal>
    </>
  );
};

export default CampaignRequestorCloneDialog;
