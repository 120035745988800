/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import moment from "moment";
import { Tooltip } from "antd";

// Ant Design
import {
  Row,
  Col,
  Space,
  Table,
  Typography,
  Select,
  Input,
  Button,
  Popconfirm,
  message,
} from "antd";

const { Title, Text } = Typography;
const { TextArea } = Input;

const AudienceDetails = props => {
  let {
    audienceDetails,
    record,
    handleUpdateAudience,
    handleActivateAudience,
    handleFetchAudienceDemographs,
  } = props;
  const {
    name,
    createdAt,
    createdBy,
    reachCount,
    isArchived,
  } = audienceDetails;
  const { name: createdByName } = createdBy;

  const [loading, setLoading] = useState(false);
  const [audienceState, setAudienceState] = useState({});

  // Initialize the state with the audience details
  useEffect(() => {
    setAudienceState(audienceDetails);
  }, []);

  const handleActivation = async audienceDemograph => {
    try {
      setLoading(true);
      const response = await handleActivateAudience(audienceDemograph);
      checkAudienceActivationStatus(response);
      await handleFetchAudienceDemographs();
      setLoading(false);
    } catch (error) {
      console.log(error);
      message.error("Failed to activate audience", 2);
      setLoading(false);
    }
  };

  const checkAudienceActivationStatus = response => {
    const data =
      response &&
      response.data &&
      response.data.activateDemographAudiences &&
      response.data.activateDemographAudiences.data;

    if (
      data &&
      typeof data === "object" &&
      Object.keys(data).length > 0 &&
      data.status
    ) {
      const status = data.status;
      const metadata = data.metadata;

      if (status === "completed") {
        message.success("Audience has been activated");
      } else {
        const progress = (metadata && metadata.progress) || 0;
        message.success(`Audience is still ${progress}% completed`);
      }
    } else {
      message.error("Invalid response data");
    }
  };

  const handleArchive = async (id, isArchived = true) => {
    try {
      setLoading(true);
      await handleUpdateAudience({ id, isArchived: isArchived });
      await handleFetchAudienceDemographs();
      message.success("Audience has been archived", 2);
      setLoading(false);
    } catch (error) {
      console.log(error);
      message.error("Failed to delete audience", 2);
    }
  };

  // Function to handle update of Audience records
  const handleUpdateAudienceRecord = async data => {
    try {
      setLoading(true);

      const cleanedData = {
        id: data.id,
        description: data.description,
        tags: { set: data.tags },
      };

      await handleUpdateAudience({ ...cleanedData });
      await handleFetchAudienceDemographs();

      setAudienceState(undefined);
      message.success("Audience has been updated", 2);
      setLoading(false);
    } catch (error) {
      console.log(error);
      message.error("Failed to update audience", 2);
    }
  };

  const columns = [
    {
      title: "Published On",
      dataIndex: "publishedOn",
      key: "pubishedOn",
      render: text => <p>{text}</p>,
    },
    {
      title: "DSP",
      dataIndex: "dsp",
      key: "dsp",
      render: text => <p>{text}</p>,
    },
    {
      title: "Data CPM",
      dataIndex: "dataCpm",
      key: "dataCpm",
      render: text => <p>{text}</p>,
    },
  ];

  return (
    <>
      <Row style={{ marginBottom: "25px" }}>
        <Title level={5}>Details </Title>
      </Row>
      <Space></Space>
      <Row style={{ marginBottom: "25px" }}>
        <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
          <Text type="secondary">Audience Name</Text>
          <br />
          <div
            style={{
              width: "175px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <Tooltip title={name}>
              <span style={{ cursor: "pointer" }}>{name}</span>
            </Tooltip>
          </div>
        </Col>
        <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
          <Text type="secondary">Reach</Text>
          <br />
          <Text>{reachCount}</Text>
        </Col>
        <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6} span={6}>
          <Text type="secondary">Authored</Text>
          <br />
          <Text>{createdByName}</Text>
        </Col>
        <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6} span={6}>
          <Text type="secondary">Created On</Text>
          <br />
          <Text>{moment(createdAt).format("MM/DD/YYYY hh:mma")}</Text>
        </Col>
      </Row>
      <Row style={{ marginBottom: "25px" }} gutter={8}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
          <Text type="secondary">Description</Text>
          <br />
          <TextArea
            value={audienceState.description}
            onChange={e => {
              const newValue = e.target.value;
              setAudienceState({ ...audienceState, description: newValue });
            }}
            rows={4}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
          <Text type="secondary">Tags</Text>
          <br />
          <Select
            mode="tags"
            style={{ width: "100%" }}
            placeholder="Select or create tags"
            value={audienceState.tags}
            onChange={value => {
              setAudienceState({ ...audienceState, tags: value });
            }}
          ></Select>
        </Col>
        <Row style={{ marginTop: "25px", marginLeft: "1px" }} gutter={8}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <Button
              loading={loading}
              style={{ marginRight: "0.5em" }}
              type="primary"
              disabled={isArchived}
              onClick={() => {
                handleUpdateAudienceRecord(audienceState);
              }}
            >
              Update
            </Button>

            <Popconfirm
              title="Are you sure you want to publish this Audience?"
              onConfirm={() => {
                handleActivation({ id: record.id, ...record.s3MJDetails });
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button
                disabled={!record.isPushedToS3MJ}
                loading={loading}
                style={{ marginRight: "0.5em" }}
              >
                Publish
              </Button>
            </Popconfirm>

            {!isArchived && (
              <Popconfirm
                title="Are you sure you want to archive this record?"
                onConfirm={() => {
                  handleArchive(record.id);
                }}
                okText="Yes"
                cancelText="No"
              >
                <Button danger loading={loading} disabled={isArchived}>
                  Archive
                </Button>
              </Popconfirm>
            )}
            {isArchived && (
              <Popconfirm
                title="Are you sure you want to unarchive this record?"
                onConfirm={() => {
                  handleArchive(record.id, false);
                }}
                okText="Yes"
                cancelText="No"
              >
                <Button danger loading={loading}>
                  Unarchive
                </Button>
              </Popconfirm>
            )}
          </Col>
        </Row>
      </Row>
      <Row style={{ marginBottom: "25px" }}>
        <Col span={24}>
          <Title level={5}>Publishing History </Title>
          <br />
          <Table columns={columns}></Table>
        </Col>
      </Row>
    </>
  );
};

export default AudienceDetails;
