import React, { useEffect, useRef, useState } from "react";
import { Chart, Coord, Axis, Geom, Tooltip } from "bizcharts";
import { KPITitleText, KpiSubText } from "../globalStyling/styledText";

const DonutChart = ({
  axisName = "value",
  position = "counts",
  showTooltip = false,
  chartData = [{ value: "Placeholder", counts: 10, color: "Blue" }],
  height = 300,
  totalCount,
  showCenterText = false,
  centerText,
}) => {
  const containerRef = useRef(null);
  const [fontSize, setFontSize] = useState({ title: 12, value: 14 });

  useEffect(() => {
    const updateFontSize = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        const titleSize = Math.max(10, Math.min(12, containerWidth * 0.04));
        const valueSize = Math.max(12, Math.min(14, containerWidth * 0.05));
        setFontSize({ title: titleSize, value: valueSize });
      }
    };

    updateFontSize();
    window.addEventListener("resize", updateFontSize);
    return () => window.removeEventListener("resize", updateFontSize);
  }, []);

  const cols = {
    percent: {
      formatter: val => {
        val = (val / totalCount) * 100;
        return `${val.toFixed(2)}%`;
      },
    },
    counts: {
      formatter: val => {
        const percentage = totalCount
          ? `(${((val / totalCount) * 100).toFixed(2)}%)`
          : "";
        return `${val.toLocaleString()} ${percentage}`;
      },
    },
  };

  return (
    <div
      ref={containerRef}
      style={{
        position: "relative",
        height: height,
        width: "100%",
        minHeight: "200px",
      }}
    >
      <Chart
        forceFit
        data={chartData}
        padding={[20, 20, 20, 20]}
        height={height / 1.1}
        scale={cols}
      >
        <Coord type="theta" radius={0.75} innerRadius={0.65} />
        <Axis name={axisName} />
        {showTooltip && <Tooltip showTitle={false} />}
        <Geom
          color={[
            axisName,
            value => {
              const index = chartData.findIndex(el => el[axisName] === value);
              return index >= 0 ? chartData[index].color : "black";
            },
          ]}
          position={position}
          type="intervalStack"
        />
      </Chart>
      {showCenterText && centerText && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
            pointerEvents: "none",
            width: "100px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "2px",
          }}
        >
          <div
            style={{
              fontSize: `${fontSize.title}px`,
              lineHeight: 1.2,
              width: "100%",
            }}
          >
            <KPITitleText text="Transactions" />{" "}
            {/* Hardcoded to prevent truncation */}
          </div>
          <div
            style={{
              fontSize: `${fontSize.value}px`,
              lineHeight: 1.2,
              width: "100%",
            }}
          >
            <KpiSubText
              text={String(totalCount ? totalCount.toLocaleString() : 0)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default DonutChart;
