import React, { useState } from "react";
import { Empty, Tooltip } from "antd";
import PaginationTable from "../../../shared/PaginationTable";
import moment from "moment";

const LocationListView = props => {
  const {
    locationList,
    onChangeStateValue,
    selectedOrgId,
    history,
    isSelfService,
  } = props;

  const columns = [
    {
      title: "Location Name",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (name, record) => {
        return record.geoframe === null ? (
          <>
            {" "}
            <Tooltip title="No Geo Target">{name}</Tooltip>
          </>
        ) : (
          <>
            <Tooltip title={name}>{name}</Tooltip>
          </>
        );
      },
    },
    {
      title: "Street Address",
      dataIndex: "formattedAddress",
      key: "formattedAddress",
      render: (formattedAddress, record) => {
        // Check if the address is available and not null
        if (formattedAddress) {
          // Remove leading commas and spaces from the address
          const cleanedAddress = formattedAddress.trim().replace(/^, */, "");

          return record.geoframe === null ? (
            <>
              {" "}
              <Tooltip title="No Geo Target">{cleanedAddress}</Tooltip>
            </>
          ) : (
            <>{cleanedAddress}</>
          );
        } else {
          return null; // Handle the case where the address is null or undefined
        }
      },
    },
    {
      title: "Created On",
      dataIndex: "createdAt",
      key: "createdAt",
      render: text => {
        return moment(text)
          .utcOffset(0)
          .format("MM/DD/YY - HH:mm [GMT]");
      },
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
    },
  ];

  // remove location type column if it has no self-service permissions
  if (isSelfService) {
    columns.splice(2, 0, {
      title: "Location Type",
      dataIndex: "locationType",
      key: "locationType",
      render: (_, record) => {
        return record.locationKey ? "Retail Store" : "Walk-In";
      },
    });
  }

  const [currentPage, setCurrentPage] = useState(1);
  const table = (
    <div>
      <PaginationTable
        columns={columns}
        data={
          locationList
            ? locationList.filter(
                location =>
                  location.audienceType === "ATTRIBUTION" &&
                  location.org.id === selectedOrgId
              )
            : []
        }
        bordered={false}
        rowSelection={{
          type: "checkbox",
          getCheckboxProps: record => ({
            disabled: record.geoframe === null,
          }),
          onChange: (selectedRowKeys, selectedRows) => {
            onChangeStateValue("selectedLocations", selectedRows);
          },
        }}
        paginationSize={"default"}
        scroll={{ x: 1200 }}
        size={"default"}
        current={currentPage}
        pageSize={10}
        onChange={(paginationObject, filtersObject, sorterObject) => {
          setCurrentPage(paginationObject.current);
        }}
        onRow={(record, rowIndex) => {
          return {
            onClick: event => {
              history.push(`/retails_stores/details/${record.id}`);
            },
          };
        }}
      />
    </div>
  );

  return locationList && locationList.length ? table : <Empty />;
};

export default LocationListView;
