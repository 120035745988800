// Import necessary libraries and components
import React, { useState, useRef, useEffect } from "react";
import {
  Card,
  Col,
  Row,
  Typography,
  Button,
  Form,
  Input,
  Select,
  DatePicker,
  Tag,
  Table,
  message,
} from "antd";
import {
  CheckCircleOutlined,
  PaperClipOutlined,
  PictureOutlined,
  CodeOutlined,
} from "@ant-design/icons";
import { CopyBlock } from "react-code-blocks";
import { useTaskContext } from "../../../../core/components/tasks/useTaskContext";
import { withRouter } from "react-router-dom";
import { debounce } from "lodash";
import moment from "moment";

// Import custom components and configurations
import { PageContentLayout, PageHeaderLayout } from "../../../shared/layout";
import { TaskDatePickerModal } from "./TaskDatePickerModal";
import TaskCodeSnippetModal from "./TaskCodeSnippetModal";
import TaskRelatedCampaignsModal from "./TaskRelatedCampaignsModal";
// Import custom styles
import "../styles/DatePickerStyles.css";
import { useOrgContext } from "../../../../core/components/orgs/useOrgContext";

import { useUserContext } from "../../../../core/components/users/useUserContext";
import { uploadFileToS3 } from "../../../shared/upload/uploadFileToS3";
import { taskTypes, subTypes } from "../configs/task.config";
import { useParams } from "react-router-dom";
import OverlaySpinner from "../../../shared/OverlaySpinner";

const { Text } = Typography;
const { Option } = Select;

const TaskForm = ({ history }) => {
  const { id: currentTaskId } = useParams(); // Extract the ID from the URL with alias as currentTaskId

  const [form] = Form.useForm();
  const {
    currentUser,
    createTask,
    fetchTask,
    currentTask,
    taskLoading,
    updateTask,
  } = useTaskContext();
  const {
    fetchBasicOrgsDetails,
    basicOrgsDetails,
    basicOrgsDetailsLoading,
  } = useOrgContext();
  const {
    fetchBasicUsersDetails,
    basicUsersDetails,
    basicUsersDetailsLoading,
  } = useUserContext();

  const [initialAttachedFiles, setInitialAttachedFiles] = useState([]);
  const [initialAttachedImages, setInitialAttachedImages] = useState([]);
  const [newAttachedFiles, setNewAttachedFiles] = useState([]);
  const [newAttachedImages, setNewAttachedImages] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  useEffect(() => {
    if (currentTask) {
      setInitialAttachedFiles(currentTask.attachedFiles || []);
      setInitialAttachedImages(currentTask.attachedImages || []);
    }
  }, [currentTask]);

  const [assigneeOrgs, setAssigneeOrgs] = useState([]);
  const [channelPartnerOrgs, setChannelPartnerOrgs] = useState([]);
  const [advertiserOrgs, setAdvertiserOrgs] = useState([]);
  const [assigneeUsers, setAssigneeUsers] = useState(basicUsersDetails || []);
  const [orgTypeRequest, setOrgTypeRequest] = useState("");

  const userOrgName =
    currentUser && currentUser.role && currentUser.role.org.name;
  const level =
    currentUser &&
    currentUser.role &&
    currentUser.role.org &&
    currentUser.role.org.level;
  const isChannelPartnerOrg = level === "CHANNEL_PARTNER";
  const [filteredSubTypes, setFilteredSubTypes] = useState([]);
  const [isSubTypeDisabled, setIsSubTypeDisabled] = useState(true);
  const [taskDetailVisible, setTaskDatePickerVisible] = useState(false);

  const fileInputRef = useRef(null);
  const imageInputRef = useRef(null);
  const [attachedImages, setAttachedImages] = useState([]);
  const [attachedFiles, setAttachedFiles] = useState([]);

  const [showCodeSnippetModal, setShowCodeSnippetModal] = useState(false);
  const [attachedCodeSnippet, setAttachedCodeSnippet] = useState("");

  const [showRelatedCampaignModal, setRelatedCampaignModal] = useState(false);

  const [dueDateRecurringConfig, setDueDateRecurringConfig] = useState({});

  const relatedCampaigns = form.getFieldValue("relatedCampaigns") || [];

  const columns = [
    {
      title: "Campaign Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Flight Dates",
      dataIndex: "flightDates",
      key: "flightDates",
      render: (_, record) => {
        return `${moment(record.startDate).format("MM/DD/YYYY")} - ${moment(
          record.endDate
        ).format("MM/DD/YYYY")}`;
      },
    },
  ];

  const handleSubTypeChange = value => {
    const subTypesForSelected = subTypes[value] || [];
    setFilteredSubTypes(subTypesForSelected);
    setIsSubTypeDisabled(subTypesForSelected.length === 0);
    form.setFieldsValue({ requestSubType: "" });
  };

  useEffect(() => {
    if (currentTaskId && currentTask) {
      const subTypesForSelected = subTypes[currentTask.requestType] || [];
      setFilteredSubTypes(subTypesForSelected);
      setIsSubTypeDisabled(subTypesForSelected.length === 0);
    }
  }, [currentTaskId, currentTask]);

  const handleUploadFilesToS3 = async files => {
    try {
      const medias = [];
      if (files.length) {
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const media = await uploadFileToS3({ file });
          medias.push(media);
        }
      }
      return medias;
    } catch (error) {
      console.error(error);
      throw new Error("File Size Exceeded: No greater than 2MB is allowed");
    }
  };

  const handleFileDelete = (fileId, type) => {
    if (type === "file") {
      setAttachedFiles(attachedFiles.filter(file => file.id !== fileId));
      setNewAttachedFiles(newAttachedFiles.filter(file => file.id !== fileId));
    } else if (type === "image") {
      setAttachedImages(attachedImages.filter(image => image.id !== fileId));
      setNewAttachedImages(
        newAttachedImages.filter(image => image.id !== fileId)
      );
    }
  };

  const handleSubmit = async () => {
    const {
      name,
      dueDate,
      requestType,
      requestSubType,
      assigneeOrgId,
      assigneeUserId,
      advertiserId,
      taskDetails,
      relatedCampaigns,
    } = form.getFieldsValue();
    await form.validateFields();

    let channelPartnerId = form.getFieldValue("channelPartnerId");

    const {
      recurring,
      recurrenceType,
      dateOfMonth,
      dayOfMonth,
      dayOfWeek,
      monthlyType,
      weeklyDays,
      yearlyDate,
      yearlyMonth,
    } = dueDateRecurringConfig;

    const createdBy = {
      connect: {
        id: currentUser.id,
      },
    };

    // Use initial files to detect deletions
    const filesToDelete = initialAttachedFiles.filter(
      file => !attachedFiles.some(currentFile => currentFile.id === file.id)
    );

    const imagesToDelete = initialAttachedImages.filter(
      image =>
        !attachedImages.some(currentImage => currentImage.id === image.id)
    );

    // Use separate state for tracking new files
    let mediaFiles = [];
    let mediaImages = [];

    try {
      if (currentTaskId) {
        // Check and upload only new files
        if (newAttachedFiles.length > 0) {
          mediaFiles = await handleUploadFilesToS3(newAttachedFiles);
        }

        if (newAttachedImages.length > 0) {
          mediaImages = await handleUploadFilesToS3(newAttachedImages);
        }
      } else {
        if (attachedFiles.length > 0) {
          mediaFiles = await handleUploadFilesToS3(attachedFiles);
        }
        if (attachedImages.length > 0) {
          mediaImages = await handleUploadFilesToS3(attachedImages);
        }
      }
    } catch (error) {
      message.error(error.message);
      return;
    }

    // Determine channel partner ID if not set
    if (!channelPartnerId && isChannelPartnerOrg) {
      channelPartnerId = currentUser.role.org.id;
    }

    // Build the main task data object
    let data = {
      name,
      dueDate,
      createdBy,
      requestType,
      requestSubType,
      assigneeOrg: assigneeOrgId ? { connect: { id: assigneeOrgId } } : {},
      assigneeUser: assigneeUserId ? { connect: { id: assigneeUserId } } : {},
      channelPartner: channelPartnerId
        ? { connect: { id: channelPartnerId } }
        : {},
      advertiser: advertiserId ? { connect: { id: advertiserId } } : {},
      attachedFiles:
        mediaFiles && mediaFiles.length ? { create: mediaFiles } : {},
      attachedImages:
        mediaImages && mediaImages.length ? { create: mediaImages } : {},
      relatedCampaigns:
        relatedCampaigns && relatedCampaigns.length
          ? {
              connect: relatedCampaigns.map(campaign => ({
                id: campaign.id,
              })),
            }
          : undefined, // Ensure related campaigns are included if any
      taskDetails,
      codeSnippet: attachedCodeSnippet,
      status: "NOT_STARTED",
    };

    // Add recurring information if needed
    if (recurring) {
      data = { ...data, recurring, recurrenceType };
      switch (recurrenceType) {
        case "MONTHLY":
          data =
            monthlyType === "DATE_OF_MONTH"
              ? { ...data, dateOfMonth, monthlyType }
              : { ...data, dayOfMonth, dayOfWeek, monthlyType };
          break;
        case "WEEKLY":
          data = { ...data, weeklyDays: { set: weeklyDays } };
          break;
        case "YEARLY":
          data = { ...data, yearlyDate, yearlyMonth };
          break;
        default:
          break;
      }
    }

    try {
      message.loading(`${currentTaskId ? "Updating" : "Creating"} task...`, 0);

      if (currentTaskId) {
        // Delete files that have been removed by the user
        if (filesToDelete.length) {
          await updateTask({
            variables: {
              where: { id: currentTaskId },
              data: {
                attachedFiles: {
                  delete: filesToDelete.map(file => ({ id: file.id })),
                },
              },
            },
          });
        }

        if (imagesToDelete.length) {
          await updateTask({
            variables: {
              where: { id: currentTaskId },
              data: {
                attachedImages: {
                  delete: imagesToDelete.map(image => ({ id: image.id })),
                },
              },
            },
          });
        }

        // Filter out the data that is not needed for the update
        const { status, createdBy, ...filteredData } = data;

        // Proceed with the main update including new files
        await updateTask({
          variables: {
            where: { id: currentTaskId },
            data: filteredData,
          },
        });
        history.push(`/tasks/details/${currentTaskId}`);
      } else {
        await createTask({ variables: { data } });
        if (history) {
          history.push(`/tasks/main/1`);
        }
      }

      message.destroy();
      message.success(
        `Task ${currentTaskId ? "updated" : "created"} successfully`
      );
    } catch (error) {
      message.destroy();
      message.error(
        `Failed to ${currentTaskId ? "update" : "create"} task: ${
          error.message
        }`
      );
    }
  };

  const fetchBasicOrgs = debounce((name = "") => {
    setOrgTypeRequest("ASSIGNEE");
    fetchBasicOrgsDetails({
      variables: {
        first: 10,
        skip: 0,
        where: {
          AND: [
            {
              OR: [
                { parentOrg_some: { id: currentUser.role.org.id } },
                { id: currentUser.role.org.id },
              ],
              name_contains: name,
            },
          ],
          OR: [
            {
              roles_some: {
                org: {
                  level: "PLATFORM",
                },
                roleItems_some: {
                  feature: "TASK_MANAGEMENT",
                },
              },
            },
          ],
        },
      },
    });
  }, 500);

  const fetchChannelPartnerOrgs = debounce((name = "") => {
    setOrgTypeRequest("CHANNEL_PARTNER");
    fetchBasicOrgsDetails({
      variables: {
        first: 10,
        skip: 0,
        where: {
          AND: [
            {
              level_in: ["PLATFORM", "CHANNEL_PARTNER"],
            },
            {
              name_contains: name,
            },
          ],
        },
      },
    });
  }, 500);

  const fetchAdvertiserOrgs = debounce((name = "") => {
    const channelPartnerId = form.getFieldValue("channelPartnerId");
    setOrgTypeRequest("ADVERTISER");
    fetchBasicOrgsDetails({
      variables: {
        first: 10,
        skip: 0,
        where: {
          AND: [
            {
              OR: [
                {
                  parentOrg_some: {
                    id: channelPartnerId,
                  },
                },
                {
                  id: channelPartnerId,
                },
              ],
            },
            {
              name_contains: name,
            },
          ],
        },
      },
    });
  }, 500);

  const fetchBasicUsers = debounce((name = "") => {
    const assigneeOrgId = form.getFieldValue("assigneeOrgId");
    setOrgTypeRequest("CHANNEL_PARTNER");
    fetchBasicUsersDetails({
      variables: {
        first: 10,
        skip: 0,
        where: {
          AND: [
            {
              roles_some: {
                org: {
                  id: assigneeOrgId,
                  level: "PLATFORM",
                },
                roleItems_some: {
                  feature: "TASK_MANAGEMENT",
                },
              },
              name_contains: name,
            },
          ],
        },
      },
    });
  }, 500);

  useEffect(() => {
    if (currentTaskId) {
      fetchTask({
        variables: {
          where: {
            id: currentTaskId,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    switch (orgTypeRequest) {
      case "ASSIGNEE":
        setAssigneeOrgs(basicOrgsDetails);
        break;
      case "CHANNEL_PARTNER":
        setChannelPartnerOrgs(basicOrgsDetails);
        break;

      case "ADVERTISER":
        setAdvertiserOrgs(basicOrgsDetails);
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basicOrgsDetails]);

  useEffect(() => {
    setAssigneeUsers(basicUsersDetails);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basicUsersDetails]);

  useEffect(() => {
    if (currentTaskId && currentTask && form) {
      form.setFieldsValue({
        name: currentTask.name,
        dueDate: currentTask.dueDate ? moment(currentTask.dueDate) : null,
        requestType: currentTask.requestType,
        requestSubType: currentTask.requestSubType,
        assigneeOrgId: currentTask.assigneeOrg
          ? currentTask.assigneeOrg.id
          : null,
        assigneeUserId: currentTask.assigneeUser
          ? currentTask.assigneeUser.id
          : null,
        channelPartnerId: currentTask.channelPartner
          ? currentTask.channelPartner.id
          : null,
        advertiserId: currentTask.advertiser ? currentTask.advertiser.id : null,
        taskDetails: currentTask.taskDetails,
        relatedCampaigns:
          currentTask.relatedCampaigns && currentTask.relatedCampaigns.length
            ? currentTask.relatedCampaigns.map(rCampaign => ({
                ...rCampaign,
                startDate: moment(rCampaign.startDate),
                endDate: moment(rCampaign.endDate),
              }))
            : [],
      });

      setAssigneeOrgs(
        assigneeOrgs.concat(
          currentTask.assigneeOrg ? [currentTask.assigneeOrg] : []
        )
      );
      setChannelPartnerOrgs(
        channelPartnerOrgs.concat(
          currentTask.channelPartner ? [currentTask.channelPartner] : []
        )
      );
      setAdvertiserOrgs(
        advertiserOrgs.concat(
          currentTask.advertiser ? [currentTask.advertiser] : []
        )
      );
      setAssigneeUsers(
        assigneeUsers.concat(
          currentTask.assigneeUser ? [currentTask.assigneeUser] : []
        )
      );
      setAttachedFiles(currentTask.attachedFiles || []);
      setAttachedImages(currentTask.attachedImages || []);
      setAttachedCodeSnippet(currentTask.codeSnippet || "");
      setDueDateRecurringConfig({
        recurring: currentTask.recurring,
        recurrenceType: currentTask.recurrenceType,
        dateOfMonth: currentTask.dateOfMonth,
        dayOfMonth: currentTask.dayOfMonth,
        dayOfWeek: currentTask.dayOfWeek,
        monthlyType: currentTask.monthlyType,
        weeklyDays: currentTask.weeklyDays,
        yearlyDate: currentTask.yearlyDate,
        yearlyMonth: currentTask.yearlyMonth,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTask]);

  if (taskLoading) {
    return <OverlaySpinner />;
  }

  return (
    <>
      <PageHeaderLayout
        title={currentTaskId ? "Update Task" : "Create Task"}
        titleIcon={
          <CheckCircleOutlined
            style={{
              marginRight: "10px",
              fontSize: 20,
            }}
          />
        }
        rightContent={
          <Row>
            {level === "PLATFORM" && (
              <Col style={{ paddingInline: "5px" }}>
                <Button onClick={() => history.goBack()}>Back</Button>
              </Col>
            )}
            <Col style={{ paddingInline: "5px" }}>
              <Button
                type="primary"
                onClick={async () => {
                  setButtonDisabled(true); //refactored to state since using the updateTaskLoading or createTaskLoading is delayed 2 seconds
                  try {
                    await handleSubmit();
                  } catch (error) {
                    console.error(error.message);
                  } finally {
                    setButtonDisabled(false);
                  }
                }}
                disabled={buttonDisabled}
              >
                {currentTaskId ? "Update Task" : "Create Task"}
              </Button>
            </Col>
          </Row>
        }
      />
      <PageContentLayout>
        <Form form={form} layout="vertical">
          {/* TASK DETAILS */}
          <Row gutter={[30, 30]}>
            <Col span={16}>
              <Card
                style={{ borderRadius: "7px" }}
                title={
                  <Text style={{ fontWeight: "bolder" }}>TASK DETAILS</Text>
                }
              >
                <Row gutter={[18, 18]}>
                  <Col span={10}>
                    <Form.Item
                      name="name"
                      label={
                        <div>
                          <div>Task Name</div>
                          <div style={{ color: "gray", fontSize: "12px" }}>
                            The name you will identify this task by
                          </div>
                        </div>
                      }
                      required={false}
                      rules={[
                        { required: true, message: "Task Name is Required" },
                      ]}
                      colon={false}
                      style={{ paddingRight: "10px" }}
                    >
                      <Input placeholder="Enter task name" />
                    </Form.Item>
                  </Col>
                  <Col span={14}>
                    {level !== "CHANNEL_PARTNER" && (
                      <Form.Item
                        name="dueDate"
                        label={
                          <div>
                            <div>Due Date</div>
                            <div style={{ color: "gray", fontSize: "12px" }}>
                              Date of task completion
                            </div>
                          </div>
                        }
                        colon={false}
                        layout="vertical"
                      >
                        {/* CUSTOM STYLE DATEPICKER */}
                        <DatePicker
                          onOpenChange={open => {
                            if (open) {
                              setTaskDatePickerVisible(true); // Open the modal when DatePicker is clicked
                            }
                          }}
                          open={false}
                        />
                      </Form.Item>
                    )}
                  </Col>
                </Row>
                <Row gutter={[18, 18]}>
                  <Col span={10}>
                    <Form.Item
                      name="requestType"
                      label={
                        <div>
                          <div>Request Type</div>
                          <div style={{ color: "gray", fontSize: "12px" }}>
                            Select the type that best matches this task
                          </div>
                        </div>
                      }
                      required={false}
                      rules={[
                        { required: true, message: "Request Type is Required" },
                      ]}
                      colon={false}
                      style={{ paddingRight: "10px" }}
                    >
                      <Select
                        placeholder="Select request type"
                        onChange={handleSubTypeChange}
                      >
                        {taskTypes.map((type, index) => (
                          <Option key={index} value={type}>
                            {type}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={10}>
                    <Form.Item
                      name="requestSubType"
                      colon={false}
                      label={
                        <div>
                          <div>Request Details</div>
                          <div style={{ color: "gray", fontSize: "12px" }}>
                            Optional: Select a sub-type that best matches this
                            task
                          </div>
                        </div>
                      }
                    >
                      <Select
                        placeholder="Select request details"
                        disabled={isSubTypeDisabled}
                      >
                        {filteredSubTypes.map((subtype, index) => (
                          <Option key={index} value={subtype}>
                            {subtype}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>

            {/* REQUEST DETAILS */}
            <Col span={16}>
              <Card
                style={{ borderRadius: "7px" }}
                title={
                  <Text style={{ fontWeight: "bolder" }}>REQUEST DETAILS</Text>
                }
              >
                <Row gutter={[18, 18]}>
                  {level === "PLATFORM" && (
                    <>
                      <Col span={10}>
                        <Form.Item
                          name="assigneeOrgId"
                          label={
                            <div>
                              <div>Assignee Org</div>
                              <div style={{ color: "gray", fontSize: "12px" }}>
                                The org that will receive this task
                              </div>
                            </div>
                          }
                          colon={false}
                          style={{ paddingRight: "10px" }}
                        >
                          <Select
                            showSearch
                            placeholder="Select Assignee Org"
                            loading={basicOrgsDetailsLoading}
                            onFocus={() => fetchBasicOrgs()}
                            onSearch={searchText => fetchBasicOrgs(searchText)}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            filterSort={(optionA, optionB) =>
                              optionA.children
                                .toLowerCase()
                                .localeCompare(optionB.children.toLowerCase())
                            }
                          >
                            {assigneeOrgs.map(org => (
                              <Option key={org.id} value={org.id}>
                                {org.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col span={10}>
                        <Form.Item
                          name="assigneeUserId"
                          label={
                            <div>
                              <div>Assignee</div>
                              <div style={{ color: "gray", fontSize: "12px" }}>
                                The person that will receive this task
                              </div>
                            </div>
                          }
                          colon={false}
                          style={{ paddingRight: "10px" }}
                        >
                          <Select
                            placeholder="Select Assignee"
                            showSearch
                            loading={basicUsersDetailsLoading}
                            onFocus={() => fetchBasicUsers()}
                            onSearch={searchText => fetchBasicUsers(searchText)}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            filterSort={(optionA, optionB) =>
                              optionA.children
                                .toLowerCase()
                                .localeCompare(optionB.children.toLowerCase())
                            }
                          >
                            {assigneeUsers.map(user => (
                              <Option key={user.id} value={user.id}>
                                {user.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </>
                  )}
                </Row>
                <Row gutter={[18, 18]}>
                  <Col span={10}>
                    <Form.Item
                      label={
                        <div>
                          <div>Channel Partner</div>
                          <div style={{ color: "gray", fontSize: "12px" }}>
                            The partner this task is regarding
                          </div>
                        </div>
                      }
                      required={false}
                      rules={
                        level === "CHANNEL_PARTNER"
                          ? [] // No rules when level is CHANNEL_PARTNER
                          : [
                              {
                                required: true,
                                message: "Channel Partner is Required",
                              },
                            ]
                      }
                      colon={false}
                      style={{ paddingRight: "10px" }}
                      name="channelPartnerId"
                    >
                      <Select
                        placeholder={
                          level === "CHANNEL_PARTNER"
                            ? userOrgName
                            : "Select Partner"
                        }
                        showSearch
                        disabled={level === "CHANNEL_PARTNER"}
                        loading={basicOrgsDetailsLoading}
                        // value={
                        //   level === "CHANNEL_PARTNER" ? userName : undefined
                        // }
                        onFocus={() => fetchChannelPartnerOrgs()}
                        onSearch={searchText =>
                          fetchChannelPartnerOrgs(searchText)
                        }
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) => {
                          return optionA.children
                            .toLowerCase()
                            .localeCompare(optionB.children.toLowerCase());
                        }}
                      >
                        {channelPartnerOrgs.map(org => (
                          <Option key={org.id} value={org.id}>
                            {org.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={10}>
                    <Form.Item
                      name="advertiserId"
                      showLineNumbers
                      colon={false}
                      style={{ paddingRight: "10px" }}
                      label={
                        <div>
                          <div>Advertiser</div>
                          <div style={{ color: "gray", fontSize: "12px" }}>
                            The advertiser this task is regarding
                          </div>
                        </div>
                      }
                      required={false}
                      rules={[
                        { required: true, message: "Advertiser is Required" },
                      ]}
                    >
                      <Select
                        placeholder="Select Advertiser"
                        showSearch
                        loading={basicOrgsDetailsLoading}
                        onFocus={() => fetchAdvertiserOrgs()}
                        onSearch={searchText => fetchAdvertiserOrgs(searchText)}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                          optionA.children
                            .toLowerCase()
                            .localeCompare(optionB.children.toLowerCase())
                        }
                      >
                        {advertiserOrgs.map(org => (
                          <Option key={org.id} value={org.id}>
                            {org.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[18, 18]}>
                  <Col span={24}>
                    <Form.Item
                      name="taskDetails"
                      colon={false}
                      style={{ paddingRight: "10px" }}
                      label={
                        <div>
                          <div>Task Details</div>
                          <div style={{ color: "gray", fontSize: "12px" }}>
                            Details about this task
                          </div>
                        </div>
                      }
                    >
                      <Row
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          border: "1px solid #d9d9d9",
                          borderRadius: "4px",
                          position: "relative",
                          padding: "5px",
                        }}
                      >
                        <div style={{ flexGrow: 1 }}>
                          <Form.Item name="taskDetails" noStyle>
                            <Input.TextArea
                              maxLength={500}
                              autoSize={{ minRows: 2, maxRows: 6 }}
                              placeholder="Enter task details"
                              style={{
                                border: "none",
                                outline: "none",
                                resize: "none",
                                width: "100%",
                                padding: "5px",
                              }}
                            />
                          </Form.Item>
                        </div>

                        {/* Hidden files input */}
                        <input
                          type="file"
                          ref={fileInputRef}
                          style={{ display: "none" }}
                          multiple
                          accept=".doc, .docx, .pdf, .txt, .xls, .xlsx, .ppt, .pptx"
                          onChange={file => {
                            const newFiles = Array.from(file.target.files);
                            if (newFiles.length > 0) {
                              setAttachedFiles(prevFiles => [
                                ...prevFiles,
                                ...newFiles,
                              ]);
                              if (currentTaskId) {
                                setNewAttachedFiles(prevFiles => [
                                  ...prevFiles,
                                  ...newFiles,
                                ]);
                              }
                            }
                          }}
                        />

                        <input
                          type="file"
                          ref={imageInputRef}
                          style={{ display: "none" }}
                          multiple
                          accept=".jpg, .jpeg, .png, .gif, .bmp, .svg"
                          onChange={async image => {
                            const files = Array.from(
                              image.target.files
                            ).filter(file => file.type.startsWith("image/"));
                            setAttachedImages(prevFiles => [
                              ...prevFiles,
                              ...files,
                            ]);
                            if (currentTaskId) {
                              setNewAttachedImages(prevFiles => [
                                ...prevFiles,
                                ...files,
                              ]);
                            }
                          }}
                        />

                        {/* Render tags inside the text box */}
                        {attachedFiles && attachedFiles.length > 0 && (
                          <Row
                            style={{
                              display: "block",
                              marginTop: "10px",
                            }}
                          >
                            {attachedFiles.map((file, index) => (
                              <Tag
                                closable
                                key={index}
                                onClose={() => {
                                  if (currentTaskId) {
                                    handleFileDelete(file.id, "file");
                                  } else {
                                    const updatedFiles = attachedFiles.filter(
                                      (_, i) => i !== index
                                    );
                                    setAttachedFiles(updatedFiles);
                                  }
                                }}
                                icon={<PaperClipOutlined />}
                                style={{
                                  marginBottom: "4px",
                                  display: "block",
                                  whiteSpace: "pre-wrap",
                                  wordWrap: "break-word",
                                  width: "fit-content",
                                }}
                              >
                                {file.name}
                              </Tag>
                            ))}
                          </Row>
                        )}
                        {attachedImages && attachedImages.length > 0 && (
                          <Row
                            style={{
                              display: "block",
                              marginTop: "10px",
                            }}
                          >
                            {attachedImages.map((file, index) => (
                              <Tag
                                closable
                                key={index}
                                onClose={() => {
                                  if (currentTaskId) {
                                    handleFileDelete(file.id, "image");
                                  } else {
                                    const updatedImages = attachedImages.filter(
                                      (_, i) => i !== index
                                    );
                                    setAttachedImages(updatedImages);
                                  }
                                }}
                                icon={<PictureOutlined />}
                                style={{
                                  marginBottom: "4px",
                                  display: "block",
                                  whiteSpace: "pre-wrap",
                                  wordWrap: "break-word",
                                  width: "fit-content",
                                }}
                              >
                                {file.name}
                              </Tag>
                            ))}
                          </Row>
                        )}

                        {attachedCodeSnippet && (
                          <Col
                            style={{
                              maxWidth: "100%",
                              maxHeight: "300px", // Limit the height of the code block
                              overflowY: "auto", // Allow vertical scroll if content exceeds height
                              overflowX: "auto",
                            }}
                          >
                            <CopyBlock
                              text={attachedCodeSnippet}
                              language="javascript"
                              wrapLongLines
                              showLineNumbers={false}
                              theme="dracula"
                              codeBlock
                            />
                          </Col>
                        )}
                      </Row>

                      <Row style={{ paddingTop: "10px" }}>
                        <PaperClipOutlined
                          style={{
                            paddingInline: "5px",
                            color: "gray",
                            fontSize: "20px",
                          }}
                          onClick={() => {
                            fileInputRef.current.click();
                          }}
                        />
                        <PictureOutlined
                          style={{
                            paddingInline: "5px",
                            color: "gray",
                            fontSize: "20px",
                          }}
                          onClick={() => {
                            imageInputRef.current.click();
                          }}
                        />
                        <CodeOutlined
                          style={{
                            paddingInline: "5px",
                            color: "gray",
                            fontSize: "20px",
                          }}
                          onClick={() => setShowCodeSnippetModal(true)}
                        />
                      </Row>
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>

            {/* RELATED CAMPAIGNS */}
            <Col span={16}>
              {relatedCampaigns.length > 0 ? (
                <Card
                  style={{ borderRadius: "7px" }}
                  title={
                    <Text style={{ fontWeight: "bolder" }}>
                      RELATED CAMPAIGNS
                    </Text>
                  }
                  extra={
                    <Button onClick={() => setRelatedCampaignModal(true)}>
                      Edit Related Campaigns
                    </Button>
                  }
                >
                  <Table columns={columns} dataSource={relatedCampaigns} />
                </Card>
              ) : (
                <Card
                  style={{ borderRadius: "7px" }}
                  title={
                    <Text style={{ fontWeight: "bolder" }}>
                      RELATED CAMPAIGNS
                    </Text>
                  }
                >
                  <Col span={24}>
                    <Row
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <p>Select any campaigns that are related to this task</p>
                      <Button
                        onClick={() => setRelatedCampaignModal(true)}
                        disabled={
                          level !== "CHANNEL_PARTNER"
                            ? !form.getFieldValue("channelPartnerId")
                            : false
                        }
                      >
                        Select Campaigns
                      </Button>
                    </Row>
                  </Col>
                </Card>
              )}
            </Col>
          </Row>

          {taskDetailVisible && (
            <TaskDatePickerModal
              visible={taskDetailVisible}
              setVisible={setTaskDatePickerVisible}
              currentDueDateConfig={dueDateRecurringConfig}
              onClose={values => {
                setDueDateRecurringConfig(values);
                form.setFieldsValue({ dueDate: values.dueDate });
              }}
            />
          )}

          {/* Create hidden relatedCampaigns Form.Item Input */}
          <Form.Item name="relatedCampaigns">
            <Input type="hidden" />
          </Form.Item>
        </Form>
      </PageContentLayout>

      {showCodeSnippetModal && (
        <TaskCodeSnippetModal
          visible={showCodeSnippetModal}
          setVisible={setShowCodeSnippetModal}
          onEmbedSnippet={codeSnippet => {
            setAttachedCodeSnippet(codeSnippet);
          }}
        />
      )}

      {showRelatedCampaignModal && (
        <TaskRelatedCampaignsModal
          visible={showRelatedCampaignModal}
          setVisible={setRelatedCampaignModal}
          channelPartnerOrgId={form.getFieldValue("channelPartnerId")}
          selectedCampaigns={relatedCampaigns}
          onAddSelections={selectedCampaignOrders => {
            form.setFieldsValue({ relatedCampaigns: selectedCampaignOrders });
          }}
        />
      )}
    </>
  );
};

export default withRouter(TaskForm);
