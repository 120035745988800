import React, { useState, useEffect } from "react";
import { Modal, Table, Spin, message } from "antd";
import { useAudienceBuilderContext } from "../../context/AudienceBuilderContext";
import moment from "moment";

const PublishStatusDisplayModal = props => {
  const {
    refetchAudienceDemographs,
    isModalVisible,
    handleCancel,
    handleSaveAudiencesToApi,
    loadingPublishing,
  } = props;

  const {
    selectedAudiences,
    setSetSelectedAudiences,
    selectedDSP,
  } = useAudienceBuilderContext();

  const [publishComplete, setPublishComplete] = useState(false);

  const columns = [
    {
      title: "Audience Name",
      dataIndex: "name",
      key: "name",
      render: text => <span>{text}</span>,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Reach",
      dataIndex: "reachCount",
      key: "reachCount",
      align: "right",
      render: reachCount => {
        return <span>{reachCount ? reachCount.toLocaleString() : 0}</span>;
      },
    },
    {
      title: "Created",
      dataIndex: "createdAt",
      key: "createdAt",
      render: createdAt => moment(createdAt).format("M/D/YYYY"),
    },
  ];

  const handlePublishAudience = async () => {
    try {
      const response = await handleSaveAudiencesToApi({
        selectedAudiences,
        selectedDSP,
      });
      if (response) {
        message.success("Audience Preprocessed successfully");
        refetchAudienceDemographs();
        setSetSelectedAudiences([]);
      } else {
        message.error("Error while publishing audience");
      }
    } catch (err) {
      message.error("Error while publishing audience");
    } finally {
      setPublishComplete(true);
    }
  };

  useEffect(() => {
    if (isModalVisible) {
      setPublishComplete(false);
    }
  }, [isModalVisible]);

  return (
    <Modal
      title="Preprocess Audiences"
      visible={isModalVisible}
      width={1000}
      onOk={() => {
        handlePublishAudience();
      }}
      onCancel={handleCancel}
      cancelText="Close"
      okText={loadingPublishing ? "Loading..." : "Preprocess"}
      confirmLoading={loadingPublishing}
      okButtonProps={{
        disabled: publishComplete || selectedAudiences.length === 0,
      }}
    >
      <Spin spinning={loadingPublishing}>
        <Table columns={columns} dataSource={selectedAudiences} />
      </Spin>
    </Modal>
  );
};

export default PublishStatusDisplayModal;
