import React, { useState } from "react";
import { PageContentLayout, PageHeaderLayout } from "../../../shared/layout";
import { UsergroupAddOutlined, CopyOutlined } from "@ant-design/icons";
import { mainTitleIcon } from "../../../shared/globalStyling/style";
import {
  Button,
  Card,
  Col,
  Row,
  Select,
  Table,
  Tabs,
  Space,
  Input,
  message,
  Typography,
} from "antd";
import DocumentationAlert from "./components/DocumentationAlert";
import SearchWithClear from "../../../shared/SearchWithClear";
import MainTable from "./components/MainTable";
import RequestAssistance from "./components/RequestAssitance";
import { withRouter } from "react-router-dom";
import CreateImpressionsTag from "../../SignalTags/main/components/CreateImpressionsTag";
import CreatePublisherDomainTag from "../../SignalTags/main/components/CreatePublsiherDomainTag";
import { formatWholeNumber } from "../../../../core/utils/campaigns";
const { Search } = Input;
const { TabPane } = Tabs;

const TagsMainView = ({
  formattedTags,
  history,
  location,
  requestAssistance,
  publishersTags,
  measurementsTags,
  impressionsTags,
  onImressionsTagCreate,
  refetch,
  isAttentionSignals,
}) => {
  document.title = "Tags Tracking Main View";
  const params = new URLSearchParams(location.search);
  const status = params.get("status") ? params.get("status") : "live";

  const [activeTab, setActiveTab] = useState("main");
  const [filteredPublisherTags, setFilteredPublisherTags] = useState(
    publishersTags
  );
  const [filteredImpressionTags, setFilteredImpressionTags] = useState(
    impressionsTags
  );

  const publisherColumn = [
    {
      title: "Tag Name",
      dataIndex: "name",
      key: "name",
      width: "25%",
    },
    {
      title: "Advertiser",
      dataIndex: "advertiser",
      key: "advertiser",
      width: "10%",
      ellipsis: true,
    },
    {
      title: "Domain",
      dataIndex: "domain",
      key: "domain",
      width: "15%",
      ellipsis: true,
    },
    {
      title: "Page Views",
      dataIndex: "pageViews",
      key: "pageViews",
      width: "10%",
      render: text => formatWholeNumber(text),
    },
    {
      title: "Tag ID",
      dataIndex: "tagId",
      key: "tagId",
      width: "25%",
      render: text => (
        <Space direction="horizontal" style={{ width: "100%" }}>
          <span style={{ wordBreak: "break-all" }}>{text}</span>
          <CopyOutlined
            onClick={e => {
              e.stopPropagation();
              navigator.clipboard.writeText(text);
              message.info("Tag ID copied to clipboard");
            }}
          />
        </Space>
      ),
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      render: record => (
        <Typography.Link onClick={() => redirectDetails(record.id)}>
          Test Signal
        </Typography.Link>
      ),
    },
  ];

  const impressionColumn = [
    {
      title: "Tag Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Platform",
      dataIndex: "dsp",
      key: "dsp",
    },
    {
      title: "Tag ID",
      dataIndex: "tagId",
      key: "tagId",
      render: text => (
        <Space direction="horizontal">
          {text}
          <CopyOutlined
            onClick={e => {
              e.stopPropagation();
              navigator.clipboard.writeText(text);
              message.info("Tag ID copied to clipboard");
            }}
          />
        </Space>
      ),
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      render: record => (
        <Typography.Link onClick={() => redirectDetails(record.id)}>
          Test Signal
        </Typography.Link>
      ),
    },
  ];

  const redirectDetails = id => {
    history.push(`/tags/details/${id}`);
  };

  const renderMeasurementTagsContent = () => (
    <div>
      <PageHeaderLayout
        title={"Measurement Tags"}
        rightContent={
          <Row align="middle" justify="center" gutter={[5, 5]}>
            <Col>
              <Select
                defaultValue={"live"}
                value={status}
                style={{ width: "120px" }}
                onChange={val => {
                  if (status) {
                    params.delete("status");
                  }
                  params.append("status", val);
                  history.push({
                    pathname: location.pathname,
                    search: `?${params.toString()}`,
                  });
                }}
              >
                <Select.Option value="live">LIVE</Select.Option>
                <Select.Option value="disabled">DISABLED</Select.Option>
              </Select>
            </Col>
            <Col>
              <SearchWithClear searchFormat={"name"} />
            </Col>
          </Row>
        }
      />
      <Row
        align="middle"
        justify="center"
        style={{ background: "white", padding: "10px" }}
      >
        <Col xl={18} lg={24} offset={1}>
          <DocumentationAlert />
        </Col>
        <Col xl={4} lg={24} offset={1}>
          <Button
            type="primary"
            shape="round"
            onClick={() => {
              history.push(`/tags/manager`);
            }}
          >
            Create New Tag
          </Button>
        </Col>
      </Row>
      <Card>
        <MainTable tags={formattedTags} />
      </Card>
    </div>
  );

  return (
    <React.Fragment>
      <PageHeaderLayout
        title={"Tags"}
        titleIcon={<UsergroupAddOutlined style={mainTitleIcon} />}
        rightContent={
          <RequestAssistance onRequestAssistance={requestAssistance} />
        }
      />
      <PageContentLayout>
        {isAttentionSignals ? (
          <Tabs activeKey={activeTab} onChange={setActiveTab}>
            <TabPane tab="Measurement Tags" key="main">
              {renderMeasurementTagsContent()}
            </TabPane>
            <TabPane tab="Publisher Domain Tags" key="domain">
              <Card
                title={"Publisher Domain Tags"}
                extra={
                  <Space direction="horizontal">
                    <Search
                      placeholder="Search"
                      allowClear
                      onSearch={search => {
                        setFilteredPublisherTags(
                          publishersTags.filter(item =>
                            Object.values(item).some(value =>
                              value
                                .toString()
                                .toLowerCase()
                                .includes(search.toLowerCase())
                            )
                          )
                        );
                      }}
                      style={{
                        width: 200,
                      }}
                    />
                    <CreatePublisherDomainTag />
                  </Space>
                }
              >
                <Table
                  columns={publisherColumn}
                  dataSource={filteredPublisherTags}
                />
              </Card>
            </TabPane>
            <TabPane tab="Impression Tags" key="impressions">
              <Card
                title={"Impressions Tags"}
                extra={
                  <Space direction="horizontal">
                    <Search
                      placeholder="Search"
                      allowClear
                      onSearch={search => {
                        setFilteredImpressionTags(
                          impressionsTags.filter(item =>
                            Object.values(item).some(value =>
                              value
                                .toString()
                                .toLowerCase()
                                .includes(search.toLowerCase())
                            )
                          )
                        );
                      }}
                      style={{
                        width: 200,
                      }}
                    />
                    <CreateImpressionsTag
                      onSubmit={onImressionsTagCreate}
                      refetch={refetch}
                    />
                  </Space>
                }
              >
                <Table
                  columns={impressionColumn}
                  dataSource={filteredImpressionTags}
                />
              </Card>
            </TabPane>
          </Tabs>
        ) : (
          renderMeasurementTagsContent()
        )}
      </PageContentLayout>
    </React.Fragment>
  );
};

export default withRouter(TagsMainView);
