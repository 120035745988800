import React, { useContext, useEffect, useState } from "react";
import { graphql, useMutation } from "react-apollo";
import compose from "lodash/flowRight";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import { useHistory, withRouter } from "react-router-dom";
import { GlobalContext } from "../../../context/GlobalContext";
import moment from "moment";
// Queries
import CURRENT_USER from "../../../GraphQl/Queries/CURRENT_USER";
import GET_AUDIT_NOTIFICATIONS from "../../../GraphQl/Queries/GET_AUDIT_NOTIFICATIONS";
import LIST_CAMPAIGNORDERS_CONNECTION from "../../../GraphQl/Queries/LIST_CAMPAIGNORDERS_CONNECTION.js";
import LIST_ORGS_ADMIN_CAMPAIGNS from "../../../GraphQl/Queries/LIST_ORGS_ADMIN_CAMPAIGNS";
import ALL_CAMPAIGNS from "../../../GraphQl/Queries/ALL_CAMPAIGNS";
import GET_ORG_TABLE_CONFIG from "../../../GraphQl/Queries/GET_ORG_TABLE_CONFIG";
import GET_ORG_ALL_TIME_FILTER from "../../../GraphQl/Queries/GET_ORG_ALL_TIME_FILTER";
import GET_CHATWOOT_ID from "../../../GraphQl/Queries/GET_CHATWOOT_ID";
import UPDATE_CAMPAIGN_ORDER_SHARING from "../../../GraphQl/Mutations/UPDATE_CAMPAIGN_ORDER_SHARING";
import CAMPAIGN_ORDER_SHARING_DETAILS from "../../../GraphQl/Queries/CAMPAIGN_ORDER_SHARING_DETAILS";
import GET_CAMPIGN_ORDER_OVERALL_EXPORT from "../../../GraphQl/Queries/GET_CAMPIGN_ORDER_OVERALL_EXPORT";
import GET_CAMPAIGN_REPORTS from "../../../GraphQl/Queries/GET_CAMPAIGN_REPORTS";
import GET_ALL_CAMPAIGNORDERS_CONNECTION from "../../../GraphQl/Queries/GET_ALL_CAMPAIGNORDERS_CONNECTION";

// Mutations
import EDIT_ALL_TIME_FILTER from "../../../../core/GraphQl/Mutations/EDIT_ALL_TIME_FILTER";
import UPDATE_BULK_CAMPAIGN_ORDER from "../../../../core/GraphQl/Mutations/UPDATE_BULK_CAMPAIGN_ORDER";
import UPDATE_USER_CAMPAIGN_TABLE_DISPLAY_COLUMNS from "../../../../core/GraphQl/Mutations/UPDATE_USER_CAMPAIGN_TABLE_DISPLAY_COLUMNS";
import UPDATE_ORG_TABLE_CONFIG from "../../../../core/GraphQl/Mutations/UPDATE_ORG_TABLE_CONFIG.js";

import { possibleFields } from "../../../utils/constants/constants";
import {
  filterPrice,
  filterNames,
  //filterTrafficker,
} from "../../../utils/campaigns";
import CampaignsMainViewStateController from "./CampaignsMainViewStateController";
import OverlaySpinner from "../../../../platform/shared/OverlaySpinner";
import { isEmpty } from "lodash";
import { determineUserAccess } from "../../../permissions/userLevelAccess";
import { advertiserIdParamKey } from "../../../../platform/shared/AdvertiserFilter/AdvertiserFilter.container";
import {
  NotificationResult,
  RetryButton,
} from "../../../../platform/shared/NotificationResult";

import { saveAs } from "file-saver";
import Papa from "papaparse";
import JSZip from "jszip";
import DELETE_CAMPAIGN_ORDER from "../../../GraphQl/Mutations/DELETE_CAMPAIGN_ORDER";
import CART_PROVIDER from "../../../GraphQl/Introspection/CART_PROVIDER";

//CONSTANTS
const allowedOrderBys = {
  name_ASC: true,
  name_DESC: true,
  startDate_ASC: true,
  startDate_DESC: true,
  endDate_ASC: true,
  endDate_DESC: true,
  budget_ASC: true,
  budget_DESC: true,
};

const Loader = props => {
  const history = useHistory();
  const [exportRangeDate, setExportRangeDate] = useState([]);
  const [isDataFiltered, setIsDataFiltered] = useState(true);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [exportAbilityLoading, setExportAbilityLoading] = useState(false);
  const [exportAbilityError, setExportAbilityError] = useState(false);
  const [exportAbilityEmptyData, setExportAbilityEmptyData] = useState(false);
  const [exportDataLoaded, setExportDataLoaded] = useState(false);
  const [
    exportCampaignNumberResults,
    setExportCampaignNumberResults,
  ] = useState(null);
  const [exportAbilityCampaignNames, setExportAbilityCampaignNames] = useState(
    []
  );
  const [exportAllToggle, setExportAllToggle] = useState(false);
  const [currentCartProvider, setCurrentCartProvider] = useState("");

  const isRefreshTable = sessionStorage.getItem("refreshTable");

  // Handle URL parameters
  const pageNumber = props.match.params.pageNumber;
  if (isNaN(pageNumber) || Number(pageNumber) < 1)
    props.location.search
      ? props.history.push(`campaigns/main/1${props.location.search}`)
      : props.history.push("campaigns/main/1");
  const isCampaignTableDisplayColumnsExist =
    props.currentUser &&
    props.currentUser.campaignTableDisplayColumns &&
    props.currentUser.campaignTableDisplayColumns.length;
  const currentUsersOrg = props.currentUser.role.org.name;
  const currentUsersOrgId = props.currentUser.role.org.id;
  const config = props.currentUser.permission;
  const isPastData = props.currentUser.role.org.config.pastData;
  const isMediaJel =
    currentUsersOrg === "MediaJel" || currentUsersOrg === "MediaJelAdmin";

  const orgConfig = determineUserAccess(config);

  const { isDemo } = orgConfig;

  let where = {
    AND: [],
  };

  let status_where = {
    AND: [],
  };

  let paramStartDate;
  let paramEndDate;

  let params = new URLSearchParams(props.location.search);

  const pageSize = params.get("pageSize")
    ? parseInt(params.get("pageSize"))
    : 10;

  const search = params.get("search");
  if (search) where["AND"].push({ name_contains: search });

  const attribution = params.get("attribution");
  const ecommerce = params.get("ecommerce");
  const signups = params.get("signups");
  const pageViews = params.get("pageViews");

  if (!!attribution === true && !!ecommerce === true) {
    where["AND"].push({
      OR: [
        { attributionTargets_some: { id_not: null } },
        { transactionTarget_not: null },
        { transactionTarget_not: "" },
      ],
    });
  } else if (!!ecommerce === true) {
    where["AND"].push({
      OR: [{ transactionTarget_not: null }, { transactionTarget_not: "" }],
    });
  } else if (!!attribution === true) {
    where["AND"].push({
      attributionTargets_some: { id_not: null },
    });
  } else if (!!signups === true) {
    where["AND"].push({
      signUpCampaign: true,
    });
  } else if (!!pageViews === true) {
    where["AND"].push({
      pageViewConfig: {
        appId_not: null,
      },
    });
  }

  const isCampaignEmpty = (campaign, key) => {
    const { impressions, clicks } = campaign.overallData;
    return (
      JSON.stringify(campaign) === "{}" ||
      !campaign[key] ||
      campaign[key].length === 0 ||
      impressions === 0 ||
      clicks === 0
    );
  };

  const [getReport] = useLazyQuery(GET_CAMPAIGN_REPORTS, {
    fetchPolicy: "no-cache",
    onError: err => {
      console.log(err);
      setExportAbilityError(true);
      setExportAbilityLoading(false);
      return err;
    },
    onCompleted: async data => {
      const urls = data && data.getCampaignReportSignedURLs;
      let isEmptyData = false;
      let campaignData = [];
      const keys = [
        "byDate",
        "byDay",
        "deviceType",
        "creatives",
        "publishers",
        "pacingData",
        "overallData",
      ];

      Promise.all(
        await urls.map(async obj =>
          fetch(obj.url).then(async response => {
            if (response) {
              try {
                return await response.json().then(data => data);
              } catch (err) {
                setExportAbilityError(true);
                console.log(err);
              }
            }
          })
        )
      )
        .then(values => {
          // Terminate immediately if there is no data
          campaignData = values;

          if (campaignData.length === 0) {
            isEmptyData = true;
            return;
          }

          const zip = new JSZip();
          values.map((data, i) => {
            keys.map(key => {
              // Terminate process if campaign is empty
              if (isCampaignEmpty(data, key)) return null;

              const folder = zip.folder(`${exportAbilityCampaignNames[i]}`);
              folder.file(
                `${key.toUpperCase()}-${exportAbilityCampaignNames[i]}.csv`,
                Papa.unparse(
                  key === "pacingData" || key === "overallData"
                    ? [data[key]]
                    : data[key]
                )
              );
              return key;
            });
            return data;
          });

          zip.generateAsync({ type: "blob" }).then(
            blob => {
              saveAs(blob, "ALL CAMPAIGNS EXPORT DATA");
              setExportAbilityLoading(false);
              return true;
            },
            function(e) {
              console.log("error", e);
              setExportAbilityError(true);
              setExportAbilityLoading(false);
              return false;
            }
          );
        })
        .catch(err => {
          console.log(err);
          setExportAbilityError(true);
          setExportAbilityLoading(false);
        })
        .finally(() => {
          const emptyCampaigns = campaignData.filter(v =>
            keys.some(key => isCampaignEmpty(v, key))
          );

          if (emptyCampaigns.length > 0) {
            setExportCampaignNumberResults({
              exported: campaignData.length - emptyCampaigns.length,
              empty: emptyCampaigns.length,
              total: campaignData.length,
            });
          }

          if (isEmptyData) {
            setExportAbilityEmptyData(true);
            setExportAbilityError(true);
            setExportAbilityLoading(false);
          }
        });
    },
  });

  const [
    exportAbilityQuery,
    {
      loading: overallReportLoading,
      data: overallReportData,
      error: overallReportError,
    },
  ] = useLazyQuery(GET_CAMPIGN_ORDER_OVERALL_EXPORT, {
    fetchPolicy: "no-cache",
    onError: err => {
      console.log(err);
      setExportAbilityError(true);
      return;
    },
    onCompleted: () => {
      if (!overallReportData || overallReportError) {
        setExportAbilityError(true);
        return;
      }

      setExportAbilityCampaignNames(
        overallReportData.campaignOrders.map(
          campaignOrder => campaignOrder.name
        )
      );

      const reportId = overallReportData.campaignOrders
        .map(campaignOrder => {
          if (
            campaignOrder.reports.length !== 0 &&
            campaignOrder.reports !== null &&
            campaignOrder.reports !== undefined
          ) {
            return campaignOrder.reports[0].id;
          }
          return null;
        })
        .filter(id => id !== null && id !== undefined);

      if (reportId.some(e => e == null || e === undefined)) {
        setExportAbilityLoading(false);
        setExportAbilityError(true);
        return;
      }

      setExportAbilityLoading(true);
      getReport({
        variables: {
          reportId,
        },
      });
    },
  });

  const [
    getAllCampaignOrdersConnection,
    {
      data: dataAllCampaignOrdersConnection,
      error: errorAllCampaignOrdersConnection,
    },
  ] = useLazyQuery(GET_ALL_CAMPAIGNORDERS_CONNECTION, {
    fetchPolicy: "no-cache",
    onError: err => {
      console.log(err);
      setExportAbilityError(true);
      setExportAbilityLoading(false);
      return;
    },
    onCompleted: () => {
      if (
        !dataAllCampaignOrdersConnection ||
        errorAllCampaignOrdersConnection
      ) {
        setExportAbilityError(true);
        setExportAbilityLoading(false);
        return;
      }

      const data =
        dataAllCampaignOrdersConnection &&
        dataAllCampaignOrdersConnection.campaignOrdersConnection &&
        dataAllCampaignOrdersConnection.campaignOrdersConnection.edges;

      const allCampaignIds = data.map(({ node }) => node.id);

      setExportAbilityLoading(true);
      exportAbilityQuery({
        variables: { ids: allCampaignIds },
      });
    },
  });

  const { data: orgAllTimeFilter } = useQuery(GET_ORG_ALL_TIME_FILTER, {
    variables: { id: currentUsersOrgId },
  });

  const { data: chatWootId } = useQuery(GET_CHATWOOT_ID, {
    variables: { orgId: currentUsersOrgId },
  });

  const [editAllTimeFilter] = useMutation(EDIT_ALL_TIME_FILTER, {
    variables: {
      id: currentUsersOrgId,
      isAllTimeFiltered: props.isAllTimeFiltered,
    },
  });

  const getIsAllTimeFiltered =
    orgAllTimeFilter &&
    orgAllTimeFilter.org &&
    orgAllTimeFilter.org.isAllTimeFiltered;

  const getChatWootId =
    chatWootId && chatWootId.org && chatWootId.org.chatWootId;

  const status = params.get("status");
  const paramsDate = params.get("date");

  let statusNotIn = status === "draft" ? ["PENDING"] : ["DRAFT", "PENDING"];

  if (status === "demo") {
    where["AND"].push({
      type: "DEMO",
    });
  } else {
    where["AND"].push({
      type_not: "CPC",
    });
  }

  if (getIsAllTimeFiltered === true) {
    const whereBetweenStart = moment()
      .subtract(6, "months")
      .seconds(0)
      .milliseconds(0)
      .toISOString();

    const whereBetweenEnd = moment()
      .add(1, "days")
      .seconds(0)
      .milliseconds(0)
      .toISOString();

    where["AND"].push({
      AND: [
        {
          OR: [
            {
              startDate_gte: whereBetweenStart,
              startDate_lte: whereBetweenEnd,
            },
            {
              endDate_gte: whereBetweenStart,
            },
          ],
        },
      ],
    });
  }

  if (search) {
    statusNotIn = [];
  }

  selectedOrg
    ? where["AND"].push({
        status_not_in: statusNotIn,
        orgs_some: {
          OR: [
            { id_in: selectedOrg.split("+") },
            {
              parentOrg_some: {
                id_in: selectedOrg.split("+"),
              },
            },
          ],
        },
      })
    : orgConfig.isSelfService
    ? where["AND"].push({
        OR: [
          {
            status_not_in: statusNotIn,
            AND: {
              orgs_some: {
                OR: [{ name: currentUsersOrg }],
                NOT: [{ parentOrg_some: { id: currentUsersOrg } }],
              },
            },
          },
          {
            status_not_in: statusNotIn,
            AND: {
              orgs_some: {
                OR: [
                  {
                    name: currentUsersOrg,
                  },
                  {
                    parentOrg_some: {
                      name: currentUsersOrg,
                    },
                  },
                ],
              },
            },
          },
        ],
      })
    : where["AND"].push({
        AND: [
          {
            orgs_some: {
              OR: [
                { name: currentUsersOrg },
                { parentOrg_some: { name: currentUsersOrg } },
              ],
            },
          },
          {
            status_not: "PENDING",
          },
        ],
      });

  // for date filter in campaigns main page
  if (paramsDate) {
    const dates = paramsDate.split("-");
    paramStartDate = moment(dates[0], "YYYY/MM/DD");
    paramEndDate = moment(dates[1], "YYYY/MM/DD");

    status_where["AND"].push({
      OR: [
        {
          startDate_gte: paramStartDate
            .seconds(0)
            .milliseconds(0)
            .toISOString(),
          startDate_lte: paramEndDate
            .seconds(0)
            .milliseconds(0)
            .toISOString(),
        },
        {
          endDate_gte: paramStartDate
            .seconds(0)
            .milliseconds(0)
            .toISOString(),
          endDate_lte: paramEndDate
            .seconds(0)
            .milliseconds(0)
            .toISOString(),
        },
      ],
    });
  }

  const getDate = moment()
    .seconds(0)
    .milliseconds(0)
    .toISOString();

  const attributionWindowParam = moment()
    .subtract(30, "days")
    .seconds(0)
    .milliseconds(0)
    .toISOString();

  const attributionEndDate = moment()
    .subtract(1, "days")
    .seconds(0)
    .milliseconds(0)
    .toISOString();

  if (status === "queued") {
    status_where = {
      AND: [],
    };

    if (paramsDate) {
      status_where["AND"].push(
        {
          OR: [
            {
              startDate_gte: paramStartDate
                .seconds(0)
                .milliseconds(0)
                .toISOString(),
              startDate_lte: paramEndDate
                .seconds(0)
                .milliseconds(0)
                .toISOString(),
            },
            {
              endDate_gte: paramStartDate
                .seconds(0)
                .milliseconds(0)
                .toISOString(),
              endDate_lte: paramEndDate
                .seconds(0)
                .milliseconds(0)
                .toISOString(),
            },
          ],
        },
        {
          status_not: "DRAFT",
        },
        {
          status_not: "PENDING",
        },
        {
          status_not: "PAUSED",
        }
      );
    } else {
      status_where["AND"].push(
        {
          startDate_gt: moment()
            .hour(12)
            .format(),
          status_not: "DRAFT",
        },
        {
          status_not: "PENDING",
        },
        {
          status_not: "PAUSED",
        }
      );
    }
  }

  if (status === "live") {
    status_where = {
      AND: [],
    };

    if (paramsDate) {
      status_where["AND"].push(
        {
          OR: [
            {
              startDate_gte: paramStartDate
                .seconds(0)
                .milliseconds(0)
                .toISOString(),
              startDate_lte: paramEndDate
                .seconds(0)
                .milliseconds(0)
                .toISOString(),
            },
            {
              endDate_gte: paramStartDate
                .seconds(0)
                .milliseconds(0)
                .toISOString(),
              endDate_lte: paramEndDate
                .seconds(0)
                .milliseconds(0)
                .toISOString(),
            },
          ],
        },
        {
          status_not: "DRAFT",
        },
        {
          status_not: "PENDING",
        },
        {
          status_not: "PAUSED",
        }
      );
    } else {
      status_where["AND"].push(
        {
          startDate_lte: getDate,
          endDate_gt: getDate,
          status_not: "DRAFT",
        },
        {
          status_not: "PENDING",
        },
        {
          status_not: "PAUSED",
        }
      );
    }
  }

  if (status === "attribution") {
    status_where = {
      AND: [],
    };
    if (paramsDate) {
      status_where["AND"].push(
        {
          OR: [
            {
              startDate_gte: paramStartDate
                .seconds(0)
                .milliseconds(0)
                .toISOString(),
              startDate_lte: paramEndDate
                .seconds(0)
                .milliseconds(0)
                .toISOString(),
            },
            {
              endDate_gte: paramStartDate
                .seconds(0)
                .milliseconds(0)
                .toISOString(),
              endDate_lte: paramEndDate
                .seconds(0)
                .milliseconds(0)
                .toISOString(),
            },
          ],
        },
        {
          status_not: "DRAFT",
        },
        {
          status_not: "PENDING",
        },
        {
          status_not: "PAUSED",
        }
      );
    } else {
      status_where["AND"].push(
        {
          endDate_gte: attributionWindowParam,
          endDate_lt: attributionEndDate,
          status_not: "DRAFT",
        },
        {
          status_not: "PENDING",
        },
        {
          status_not: "PAUSED",
        }
      );
    }
  }

  if (status === "finished") {
    status_where = {
      AND: [],
    };

    if (paramsDate) {
      status_where["AND"].push(
        {
          OR: [
            {
              startDate_gte: paramStartDate
                .seconds(0)
                .milliseconds(0)
                .toISOString(),
              startDate_lte: paramEndDate
                .seconds(0)
                .milliseconds(0)
                .toISOString(),
            },
            {
              endDate_gte: paramStartDate
                .seconds(0)
                .milliseconds(0)
                .toISOString(),
              endDate_lte: paramEndDate
                .seconds(0)
                .milliseconds(0)
                .toISOString(),
            },
          ],
        },
        {
          status_not: "PENDING",
        },
        {
          status_not: "PAUSED",
        }
      );
    } else {
      status_where["AND"].push(
        {
          endDate_lt: attributionWindowParam,
          status_not: "DRAFT",
        },
        {
          status_not: "PENDING",
        },
        {
          status_not: "PAUSED",
        }
      );
    }
  }

  if (status === "draft") {
    status_where = {
      AND: [],
    };

    if (paramsDate) {
      status_where["AND"].push(
        {
          OR: [
            {
              startDate_gte: paramStartDate
                .seconds(0)
                .milliseconds(0)
                .toISOString(),
              startDate_lte: paramEndDate
                .seconds(0)
                .milliseconds(0)
                .toISOString(),
            },
            {
              endDate_gte: paramStartDate
                .seconds(0)
                .milliseconds(0)
                .toISOString(),
              endDate_lte: paramEndDate
                .seconds(0)
                .milliseconds(0)
                .toISOString(),
            },
          ],
        },
        {
          status: "DRAFT",
          orgs_some: {
            OR: [{ name: currentUsersOrg }],
            NOT: [{ parentOrg_some: { name: currentUsersOrg } }],
          },
        }
      );
    } else {
      status_where["AND"].push({
        status: "DRAFT",
        orgs_some: {
          OR: [{ name: currentUsersOrg }],
          NOT: [{ parentOrg_some: { name: currentUsersOrg } }],
        },
      });
    }
  }

  if (status === "pending") {
    status_where = {
      AND: [],
    };

    where = {
      AND: [],
    };

    if (search) {
      where["AND"].push({ name_contains: search });
    }

    if (paramsDate) {
      status_where["AND"].push(
        {
          OR: [
            {
              startDate_gte: paramStartDate
                .seconds(0)
                .milliseconds(0)
                .toISOString(),
              startDate_lte: paramEndDate
                .seconds(0)
                .milliseconds(0)
                .toISOString(),
            },
            {
              endDate_gte: paramStartDate
                .seconds(0)
                .milliseconds(0)
                .toISOString(),
              endDate_lte: paramEndDate
                .seconds(0)
                .milliseconds(0)
                .toISOString(),
            },
          ],
        },
        {
          status: "PENDING",
          orgs_some: {
            OR: [{ name: currentUsersOrg }],
            NOT: [{ parentOrg_some: { name: currentUsersOrg } }],
          },
        }
      );
    } else {
      status_where["AND"].push({
        status: "PENDING",
        orgs_some: {
          OR: [{ name: currentUsersOrg }],
          NOT: [{ parentOrg_some: { name: currentUsersOrg } }],
        },
      });
    }
  }

  if (status === "paused") {
    status_where = {
      AND: [],
    };

    if (paramsDate) {
      status_where["AND"].push(
        {
          OR: [
            {
              startDate_gte: paramStartDate
                .seconds(0)
                .milliseconds(0)
                .toISOString(),
              startDate_lte: paramEndDate
                .seconds(0)
                .milliseconds(0)
                .toISOString(),
            },
            {
              endDate_gte: paramStartDate
                .seconds(0)
                .milliseconds(0)
                .toISOString(),
              endDate_lte: paramEndDate
                .seconds(0)
                .milliseconds(0)
                .toISOString(),
            },
          ],
        },
        {
          status_not: "PENDING",
        },
        {
          status: "PAUSED",
          orgs_some: {
            OR: [
              { name: currentUsersOrg },
              { parentOrg_some: { name: currentUsersOrg } },
            ],
          },
        }
      );
    } else {
      status_where["AND"].push({
        status: "PAUSED",
        orgs_some: {
          OR: [
            { name: currentUsersOrg },
            { parentOrg_some: { name: currentUsersOrg } },
          ],
        },
      });
    }
  }

  where["AND"].push(status_where);

  let orderBy = "startDate_DESC";
  const sort = params.get("sort");
  if (sort && allowedOrderBys[sort]) orderBy = sort;

  // Load Sub-Orgs of the current user's Org for filtering the Campaign Main Table by Org
  const { data: orgsData } = useQuery(LIST_ORGS_ADMIN_CAMPAIGNS, {
    variables: { parentid: currentUsersOrgId, first: 1000 },
  });
  const orgs =
    orgsData && orgsData.orgs.filter(org => org.name !== currentUsersOrg);

  // Load CampaignOrders for audit notifications
  const { data: campaignOrdersData } = useQuery(GET_AUDIT_NOTIFICATIONS, {
    variables: { orgID: currentUsersOrgId },
  });
  const queuedCampaignOrders =
    campaignOrdersData && campaignOrdersData.campaignOrders;

  const context = useContext(GlobalContext);

  const getReportsClicked = () => {
    context.getReportsClicked({ variables: { where, orderBy } });
  };

  //Get Campaign List Query and Functions
  const {
    loading: loadingCampaign,
    error: errorCampaign,
    data: dataCampaign,
    refetch,
    subscribeToMore,
    ...result
  } = useQuery(LIST_CAMPAIGNORDERS_CONNECTION, {
    variables: {
      first: pageSize,
      skip: (Number(pageNumber) - 1) * pageSize,
      where,
      orderBy,
      currentUsersOrg: currentUsersOrgId,
    },
    fetchPolicy: "no-cache",
  });

  // fetch all campaign orders for export
  const [
    exportCampaignTable,
    {
      loading: loadingExportCampaignTable,
      error: errorExportCampaignTable,
      data: dataExportCampaignTable,
      ...resultCampaignTable
    },
  ] = useLazyQuery(LIST_CAMPAIGNORDERS_CONNECTION, {
    variables: {
      where,
      orderBy,
      currentUsersOrg: currentUsersOrgId,
    },
    fetchPolicy: "network-only",
    onCompleted: () => {
      setExportDataLoaded(true);
    },
  });

  const { data: orgTableConfig, refetch: orgTableConfigRefetch } = useQuery(
    GET_ORG_TABLE_CONFIG,
    {
      variables: { id: currentUsersOrgId },
    }
  );

  const [updateUserCampaignTableDisplayColumns] = useMutation(
    UPDATE_USER_CAMPAIGN_TABLE_DISPLAY_COLUMNS
  );

  const [updateOrgTableConfig] = useMutation(UPDATE_ORG_TABLE_CONFIG);

  const [updateBulkCampaignOrder] = useMutation(UPDATE_BULK_CAMPAIGN_ORDER, {
    variables: {
      id: props.id,
      budget: props.budget,
      cpm: props.cpm,
      startDate: props.startDate,
      endDate: props.endDate,
      transactionTarget: props.transactionTarget,
      transactionsConfig: props.transactionsConfig,
      signUpCampaign: props.signUpCampaign,
      signUpConfig: props.signUpConfig,
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: LIST_CAMPAIGNORDERS_CONNECTION,
        variables: {
          first: pageSize,
          skip: (Number(pageNumber) - 1) * pageSize,
          where,
          orderBy,
          currentUsersOrg,
        },
        fetchPolicy: "no-cache",
      },
    ],
  });

  const [getCartProvider, { data: cartProvider }] = useLazyQuery(CART_PROVIDER);

  const [deleteCampaignOrder] = useMutation(DELETE_CAMPAIGN_ORDER, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: LIST_CAMPAIGNORDERS_CONNECTION,
        variables: {
          first: pageSize,
          skip: (Number(pageNumber) - 1) * pageSize,
          where,
          orderBy,
          currentUsersOrg,
        },
        fetchPolicy: "no-cache",
      },
    ],
  });

  const onBulkDeleteCampaignOrder = async getRow => {
    (await getRow) &&
      getRow.forEach(val => {
        const getValStatus = val.status;
        const getValId = val.id;

        if (getValStatus === "DRAFT") {
          deleteCampaignOrder({
            variables: {
              campaignOrderId: getValId,
            },
          });
        }
      });
    setTimeout(() => {
      window.location.reload(false);
    }, 1000);
    await sessionStorage.setItem("refreshTable", true);
    await props.history.push("/campaigns/main/1?status=draft");
  };

  const [refreshTableListCampaign] = useLazyQuery(
    LIST_CAMPAIGNORDERS_CONNECTION,
    {
      variables: {
        first: pageSize,
        skip: (Number(pageNumber) - 1) * pageSize,
        where,
        orderBy,
        currentUsersOrg,
      },
      fetchPolicy: "no-cache",
    }
  );

  if (isRefreshTable === true) {
    refreshTableListCampaign();
    sessionStorage.setItem("refreshTable", true);
  }

  const {
    loading: loadingExportDataCampaign,
    data: exportDataCampaign,
  } = useLazyQuery(ALL_CAMPAIGNS, {
    variables: {
      currentDate: moment()
        .seconds(0)
        .milliseconds(0)
        .toISOString(),
    },
  });

  const campaignOrders = dataCampaign
    ? dataCampaign &&
      dataCampaign.campaignOrdersConnection &&
      dataCampaign.campaignOrdersConnection.edges &&
      dataCampaign.campaignOrdersConnection.edges.map(edge => edge.node)
    : [];

  let filteredData = {};

  useEffect(() => {
    if (getIsAllTimeFiltered === null) {
      editAllTimeFilter({
        variables: {
          isAllTimeFiltered: true,
        },
      });
    }
  }, [editAllTimeFilter, getIsAllTimeFiltered, loadingCampaign]);

  useEffect(() => {
    if (props.location.search) {
      const params = new URLSearchParams(props.location.search);
      const org = params.get(`${advertiserIdParamKey}`);
      if (org !== selectedOrg) {
        setSelectedOrg(org);
        history.push(`/campaigns/main/1?${params.toString()}`);
      }
    }
  }, [history, props.location.search, selectedOrg]);

  if (loadingCampaign) return <OverlaySpinner />;

  if (errorCampaign) {
    return (
      <NotificationResult
        status={"error"}
        title={"Sorry, unable to reach the network."}
        subTitle={
          "Reloading the page, it it doesn't load automatically please click Retry."
        }
        extras={[<RetryButton />]}
      />
    );
  }

  if (isEmpty(config) || !config.priceVisible)
    filteredData = filterPrice(possibleFields, campaignOrders);

  if (config && config.isDemo) {
    filteredData = filterNames(possibleFields, campaignOrders);
  }

  const dataConfig = props.currentUser.role.org.dataConfig;

  const defaultColumns = isCampaignTableDisplayColumnsExist
    ? props.currentUser.campaignTableDisplayColumns
    : JSON.parse(sessionStorage.getItem("defaultColumns")) ||
      Object.keys(filteredData.possibleFields || possibleFields);

  return (
    <CampaignsMainViewStateController
      {...result}
      {...resultCampaignTable}
      {...props}
      campaignOrders={filteredData.campaignOrders || campaignOrders}
      current={Number(pageNumber)}
      dataExportCampaignTable={dataExportCampaignTable}
      defaultColumns={defaultColumns}
      editAllTimeFilter={editAllTimeFilter}
      exportAbilityError={exportAbilityError}
      exportAbilityLoading={exportAbilityLoading}
      exportAbilityQuery={exportAbilityQuery}
      exportDataCampaign={exportDataCampaign}
      exportRangeDate={exportRangeDate}
      getChatWootId={getChatWootId}
      getIsAllTimeFiltered={getIsAllTimeFiltered}
      getReports={getReportsClicked}
      isDataFiltered={isDataFiltered}
      isDemo={isDemo}
      isLoading={context.csvExportLoading}
      isMediaJel={isMediaJel}
      isPastData={isPastData}
      loadingExportDataCampaign={loadingExportDataCampaign}
      loadingExportCampaignTable={loadingExportCampaignTable}
      errorExportCampaignTable={errorExportCampaignTable}
      orgConfig={orgConfig}
      orgs={orgs}
      orgTableConfig={orgTableConfig}
      pageSize={pageSize}
      possibleFields={filteredData.possibleFields || possibleFields}
      queuedCampaignOrders={queuedCampaignOrders}
      refetch={refetch}
      setExportAbilityError={setExportAbilityError}
      setExportAbilityLoading={setExportAbilityLoading}
      exportAllToggle={exportAllToggle}
      setExportAllToggle={setExportAllToggle}
      getAllCampaignOrdersConnection={getAllCampaignOrdersConnection}
      setExportRangeDate={setExportRangeDate}
      setIsDataFiltered={setIsDataFiltered}
      total={dataCampaign ? dataCampaign.full.aggregate.count : 0}
      updateBulkCampaignOrder={updateBulkCampaignOrder}
      onBulkDeleteCampaignOrder={onBulkDeleteCampaignOrder}
      overallReportLoading={overallReportLoading}
      overallReportError={overallReportError}
      exportAbilityEmptyData={exportAbilityEmptyData}
      setExportAbilityEmptyData={setExportAbilityEmptyData}
      exportCampaignNumberResults={exportCampaignNumberResults}
      setExportCampaignNumberResults={setExportCampaignNumberResults}
      getCartProvider={getCartProvider}
      cartProvider={cartProvider}
      setCurrentCartProvider={setCurrentCartProvider}
      currentCartProvider={currentCartProvider}
      updateUserCampaignTableDisplayColumns={
        updateUserCampaignTableDisplayColumns
      }
      isCampaignTableDisplayColumnsExist={isCampaignTableDisplayColumnsExist}
      dataConfig={dataConfig}
      updateOrgTableConfig={updateOrgTableConfig}
      orgTableConfigRefetch={orgTableConfigRefetch}
      exportCampaignTable={exportCampaignTable}
      exportDataLoaded={exportDataLoaded}
      setExportDataLoaded={setExportDataLoaded}
    >
      {props.children}
    </CampaignsMainViewStateController>
  );
};

export default compose(
  graphql(CURRENT_USER, {
    props: ({ data: { currentUser }, ownProps }) => {
      return ownProps.currentUser || { currentUser };
    },
  }),
  graphql(UPDATE_CAMPAIGN_ORDER_SHARING, {
    name: "updateCampaignOrder",
    options: {
      update: (proxy, { data: { updateCampaignOrder } }) => {
        proxy.writeQuery({
          query: CAMPAIGN_ORDER_SHARING_DETAILS,
          variables: { ID: updateCampaignOrder.id },
          data: { campaignOrder: updateCampaignOrder },
        });
      },
    },
  })
)(withRouter(Loader));
