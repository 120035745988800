import { useCampaignOrderQueueContext } from "../../../../core/components/campaigns/campaignordersqueue/useCampaignOrderQueueContext";
import React, { useEffect } from "react";
import { Histories } from "../../../shared/Histories";

export const CampaignOrderQueueHistory = ({ currentCampaignOrder }) => {
  const {
    fetchHistories,
    histories,
    loadingHistories,
  } = useCampaignOrderQueueContext();

  useEffect(() => {
    if (currentCampaignOrder) {
      fetchHistories({
        variables: {
          where: {
            referenceId: currentCampaignOrder.id,
            type: "CAMPAIGN_ORDER",
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCampaignOrder]);

  return (
    <Histories
      title="CAMPAIGN ORDER HISTORY"
      histories={histories}
      loading={loadingHistories}
    />
  );
};
