import React from "react";
import { useQuery } from "@apollo/react-hooks";
import CURRENT_USER from "../../GraphQl/Queries/CURRENT_USER";

const TagsLoader = props => {
  const {
    data: currentUser,
    loading: currentUserLoading,
    error: currentUserError,
  } = useQuery(CURRENT_USER);

  const isAttentionSignals =
    currentUser &&
    currentUser.currentUser &&
    ((Array.isArray(currentUser.currentUser.features) &&
      currentUser.currentUser.features.includes("EDITORIAL")) ||
      (currentUser.currentUser.role &&
        Array.isArray(currentUser.currentUser.role.roleItems) &&
        currentUser.currentUser.role.roleItems.some(function(item) {
          return item.feature === "EDITORIAL";
        })));

  return React.cloneElement(props.children, {
    ...props.children.props,
    currentUser,
    currentUserLoading,
    currentUserError,
    isAttentionSignals,
  });
};

export default TagsLoader;
