import React from "react";
import { useMutation, useQuery } from "react-apollo";
import EVENTS_TARGETS from "../../../GraphQl/Queries/EVENTS_TARGETS";
import SIGNAL_TAGS from "../../../GraphQl/Queries/SIGNAL_TAGS";
import { LoadingPage } from "../../../../platform/shared/LoadingPage";
import TAG_REQUEST_ASSISTANCE from "../../../GraphQl/Mutations/TAG_REQUEST_ASSISTANCE";
import IMPRESSION_TAG_CREATE from "../../../GraphQl/Mutations/IMPRESSION_TAG_CREATE";
import moment from "moment";
import { withRouter } from "react-router-dom";

const TagsMainLoader = props => {
  const { currentUser, location, isAttentionSignals } = props;
  const currentOrgId = currentUser.role.org.id;

  const params = new URLSearchParams(location.search);
  const status = params.get("status");
  const name = params.get("name");

  let disableFilter = { OR: [{ isDisabled: false }, { isDisabled: null }] };
  if (status === "disabled") {
    disableFilter = { isDisabled: true };
  }

  let nameFilter = {};
  if (name) {
    nameFilter = { name_contains: name };
  }

  const { data: dataMultiOrgTags, loading: loadingMultiOrgTags } = useQuery(
    EVENTS_TARGETS,
    {
      variables: {
        where: {
          OR: [
            { orgs_some: { id: currentOrgId } },
            { orgs_some: { parentOrg_some: { id: currentOrgId } } },
          ],
          AND: [disableFilter, nameFilter],
        },
      },
      fetchPolicy: "no-cache",
    }
  );

  const wherePublisher = {
    AND: [
      { clicksTag: { id_not: null } },
      { transactionsTag: { id_not: null } },
    ],
    OR: [
      { sharedOrgs_some: { org: { id: currentOrgId } } },
      { sharedOrgs_some: { org: { parentOrg_some: { id: currentOrgId } } } },
    ],
  };

  const whereAdvertiser = {
    OR: [
      { sharedOrgs_some: { org: { id: currentOrgId } } },
      { sharedOrgs_some: { org: { parentOrg_some: { id: currentOrgId } } } },
    ],
  };

  const whereImpressionTag = {
    orgs_some: { id: currentOrgId },
    isAdvertiser: true,
  };

  const whereMeasurementsTag = {
    OR: [
      { orgs_some: { id: currentOrgId } },
      { orgs_some: { parentOrg_some: { id: currentOrgId } } },
    ],
  };

  const publisherDomainTags = {
    OR: [
      { orgs_some: { id: currentOrgId } },
      { orgs_some: { parentOrg_some: { id: currentOrgId } } },
    ],
    isPublisherDomain: true,
  };

  const {
    data: signalTagsData,
    loading: loadingSignalTags,
    refetch,
  } = useQuery(SIGNAL_TAGS, {
    variables: {
      wherePublisher,
      whereAdvertiser,
      whereImpressionTag,
      whereMeasurementsTag,
      publisherDomainTags,
    },
    fetchPolicy: "no-cache",
  });

  const [requestAssistance] = useMutation(TAG_REQUEST_ASSISTANCE, {
    variables: {
      data: {
        status: "Incoming",
        advertiser: currentUser.role.org.name,
        date: moment(),
      },
    },
  });

  const [createImpressionTag] = useMutation(IMPRESSION_TAG_CREATE, {
    onCompleted: () => {
      refetch();
    },
  });

  if (loadingMultiOrgTags || loadingSignalTags) return <LoadingPage />;

  return React.cloneElement(props.children, {
    ...props.children.props,
    dataMultiOrgTags,
    signalTagsData,
    requestAssistance,
    createImpressionTag,
    refetch,
    currentOrgId,
    isAttentionSignals,
  });
};

export default withRouter(TagsMainLoader);
