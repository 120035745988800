import React, { useRef } from "react";
import PropTypes from "prop-types";
import { Row, Col, Checkbox, Button, Menu, Dropdown, Input } from "antd";
import { CloseOutlined, DownOutlined } from "@ant-design/icons";
import { withRouter } from "react-router-dom";
import {
  MenuItemText,
  ButtonText,
} from "../../../shared/globalStyling/styledText";

const DropDownStatusToggle = withRouter(({ location, history }) => {
  const statuses = [
    { text: "Active", key: "ACTIVE" },
    { text: "Inactive", key: "INACTIVE" },
    { text: "No Access", key: "NO_ACCESS" },
    { text: "Pending", key: "PENDING" },
  ];

  const params = new URLSearchParams(location.search);
  const selectedStatus = params.get("status");
  return (
    <Dropdown
      trigger={["click"]}
      overlay={
        <Menu selectedKeys={[selectedStatus]}>
          {statuses.map((status, i) => {
            const isSelected = selectedStatus === status.key;
            return (
              <Menu.Item
                key={`${status.text}-${i}`}
                onClick={() => {
                  if (selectedStatus) params.delete("status");
                  if (!isSelected) params.append("status", status.key);
                  history.push(`/paid_search/main/1?${params.toString()}`);
                }}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Checkbox
                  checked={isSelected}
                  style={{
                    visibility: isSelected ? "visible" : "hidden",
                    marginRight: "8px",
                  }}
                />
                <MenuItemText text={status.text} />
              </Menu.Item>
            );
          })}
        </Menu>
      }
    >
      <Button
        style={{
          fontSize: "14px",
          width: "100%",
          height: "36px",
        }}
        shape="round"
        type={!!selectedStatus ? "primary" : "default"}
        ghost={!!selectedStatus ? true : false}
      >
        <ButtonText text={"Status"} />
        <DownOutlined color="black" />
      </Button>
    </Dropdown>
  );
});

const FiltersBar = withRouter(({ match, location, history }) => {
  const searchRef = useRef(null);
  const params = new URLSearchParams(location.search);
  return (
    <Row className="filter-bar-container" align={"middle"} gutter={[16, 16]}>
      <Col
        xs={{ span: 24, order: 2 }}
        md={{ span: 5, order: 1 }}
        xl={{ span: 3 }}
      >
        <DropDownStatusToggle />
      </Col>
      <Col
        xs={{ span: 24, order: 1 }}
        md={{ span: 7, order: 2, offset: 7 }}
        xl={{ span: 4, offset: 15 }}
      >
        <Input.Search
          placeholder={"Search"}
          ref={searchRef}
          style={{ width: "100%", height: "36px", fontSize: "14px" }}
          onSearch={search => {
            if (params.get("search")) params.delete("search");
            if (search) params.append("search", search);
            history.push(`/paid_search/main/1?${params.toString()}`);
          }}
          defaultValue={params.get("search")}
        />
      </Col>
      <Col
        xs={{ span: 24, order: 3 }}
        md={{ span: 4, order: 3 }}
        xl={{ span: 2 }}
      >
        <Button
          style={{
            width: "100%",
            height: "36px",
            fontSize: "14px",
            pointerEvents: !params.toString() ? "none" : "auto",
          }}
          shape="round"
          type={"default"}
          onClick={() => {
            searchRef.current.input.value = "";
            history.push(`/paid_search/main/1`);
          }}
          disabled={!params.toString()}
          icon={<CloseOutlined />}
        >
          Clear
        </Button>
      </Col>
    </Row>
  );
});

FiltersBar.propTypes = {
  orgs: PropTypes.array,
  selectedKey: PropTypes.string,
};

export default FiltersBar;
