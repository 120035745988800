import React from "react";

const AdvertiserMainController = ({
  data,
  eventsTargets,
  createAdvertiser,
  refetch,
  mediajelAdminId,
  mediajelId,
  locationData,
  attributionAudiences,
  currentOrgId,
  permission,
  ...props
}) => {
  const { isSelfService } = permission;
  // Filter locations
  const filteredLocations =
    locationData && locationData.locations
      ? locationData.locations.filter(
          location =>
            location.audienceType === "ATTRIBUTION" &&
            location.org.id === currentOrgId
        )
      : [];

  // Filter location groups. Only show audiences with geoTargets
  const edges =
    attributionAudiences && attributionAudiences.audiencesConnection
      ? attributionAudiences.audiencesConnection.edges
      : undefined;

  const filteredAudiences = edges
    ? edges.filter(
        edge =>
          edge.node && edge.node.geoTargets && edge.node.geoTargets.length > 0
      )
    : [];

  const onCreateAdvertiser = async values => {
    try {
      await createAdvertiser({
        variables: {
          data: {
            name: values.name,
            impressionsTag: { connect: { id: values.impressionsTag } },
            insertionOrderIds: { set: values.insertionOrderIds },
            lineItemIds: { set: values.lineItemId },
            startDate: values.startDate,
            endDate: values.endDate,
            transactions: 0,
            revenue: 0,
            customers: 0,
            clicks: 0,
            impressions: 0,
            pageViews: 0,
            signUps: 0,
            transactionsTags: {
              create: values.transactionsTags.map(tag => ({
                eventsTarget: {
                  connect: {
                    id: tag.eventsTarget.connect.id,
                  },
                },
                trackMesurements: tag.trackMesurements,
              })),
            },
            sharedOrgs: {
              create: [
                {
                  isAdmin: false,
                  isImpressionsOrg: true,
                  isMeasurementOrg: false,
                  org: {
                    connect: {
                      id: values.selectedImpresionsTagOrgId,
                    },
                  },
                },
                ...values.transactionsTags.map(tag => ({
                  isAdmin: false,
                  isImpressionsOrg: false,
                  isMeasurementOrg: true,
                  org: {
                    connect: {
                      id: tag.eventsTarget.connect.orgId,
                    },
                  },
                })),
              ],
            },
            locations: values.locations,
          },
        },
      });
      await refetch();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <React.Fragment>
      {React.cloneElement(props.children, {
        ...props,
        tags: eventsTargets,
        data,
        currentOrgId,
        onCreateAdvertiser,
        filteredLocations,
        filteredAudiences,
        isSelfService,
      })}
    </React.Fragment>
  );
};

export default AdvertiserMainController;
