import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { uniqueId } from "lodash";
import "./styles.css";
import { Button, Card, Divider, message, Space, Steps, Typography } from "antd";
import { useCampaignRequestorContext } from "../../../core/components/campaignRequestor/useCampaignRequestorContext";
import {
  campaignTypeMap,
  countryMap,
} from "../../../core/utils/constants/constants";
import {
  buildAdGroupPayload,
  buildGeoTargetsPayload,
} from "./CampaignRequestorUtil";
import moment from "moment";
import { MinusCircleFilled } from "@ant-design/icons";
import { usePermify } from "@permify/react-role";
import CampaignRequestorCloneDialog from "./CampaignRequestorCloneDialog";
import { getCampaignOrderErrorMessage } from "../../../core/utils/constants/campaignOrderErrorType";

const { Step } = Steps;
const { Text } = Typography;

const cartToMap = {
  "dutchie-iframe": "DUTCHIEJS",
  "dutchie-subdomain": "DUTCHIE",
  shopifyuniversal: "SHOPIFYUNIVERSAL",
};

const defaultSteps = [
  {
    title: "Details",
    bookmarkId: "requester-details",
  },
  {
    title: "Duration & Budget",
    bookmarkId: "requester-duration-budget",
  },
  {
    title: "Categories",
    bookmarkId: "requester-categories",
  },
  {
    title: "Attribution Reporting",
    bookmarkId: "requester-attribution-reporting",
  },
];

const defaultActiveSteps = {
  details: false,
  durationAndBudget: false,
  categories: false,
  attributionReporting: false,
};

const CampaignRequestorSummary = ({
  form,
  previousValues,
  mode,
  handleInitialValues,
}) => {
  const [steps, setSteps] = useState(defaultSteps);
  const [activeSteps, setActiveSteps] = useState(defaultActiveSteps);
  const [visibleCloneDialog, setVisibleCloneDialog] = useState(false);

  const [canEdit, setCanEdit] = useState(false);
  const { isAuthorized } = usePermify();
  const values = form.getFieldsValue();

  const {
    createCampaignOrder,
    selectedAdvertiser,
    currentUserOrg,
    currentUser,
    currentCampaignOrder,
    loadingCreateCampaignOrder,
    loadingUpdateCampaignOrder,
  } = useCampaignRequestorContext();
  const history = useHistory();
  const camapignName = form.getFieldValue("name");

  const validateForm = async () => {
    try {
      await form.validateFields();
    } catch (error) {
      message.error(error.errorFields[0].errors[0]);
      return;
    }
  };

  const handleSaveDraft = async data => {
    try {
      message.loading("Saving draft...", 0);
      const result = await createCampaignOrder({
        variables: {
          data,
        },
      });

      message.destroy();
      message.success("Draft saved successfully");

      const campaignOrder = result.data.createCampaignOrderRequestor;
      return campaignOrder;
    } catch (error) {
      const errorMessages = getCampaignOrderErrorMessage(error);
      // Destroy the loading message and display all error messages
      message.destroy();
      message.error(errorMessages[0], 5);
    }
  };

  const handleNextStep = async () => {
    let campaignOrderId = currentCampaignOrder ? currentCampaignOrder.id : null;
    try {
      await form.validateFields();
      if (!currentCampaignOrder) {
        const campaignOrder = await handleSave();
        campaignOrderId = campaignOrder.id;
      } else {
        history.push(`/campaigns/requester/${campaignOrderId}?mode=review`);
      }
    } catch (error) {
      message.error(error.errorFields[0].errors[0]);
      return;
    }
  };

  const handleUpdate = async data => {
    try {
      message.loading("Updating campaign...", 0);
      message.destroy();
      message.success("Campaign updated successfully");
    } catch (error) {
      const errorMessages = getCampaignOrderErrorMessage(error);
      // Destroy the loading message and display all error messages
      message.destroy();
      message.error(errorMessages[0], 5);
    }
  };

  const handleSave = async (
    status = currentCampaignOrder ? currentCampaignOrder.status : "DRAFT"
  ) => {
    const attributionTargets = values.attributionTargets;
    const geoTargets = values.geoTargets;
    const campaigns = values.campaigns;
    const region = values.region;
    const transactionsConfig = values.transactionsConfig;
    const signUpConfig = values.signUpConfig;

    delete values.numberOfDays;

    // Initialize data object
    let data = {
      ...values,
      startDate: values.startDate || moment(),
      budget: Number(values.budget),
      category: {
        set: values.category,
      },
      cpm: Number(values.cpm),
      attributionTargets: [],
      geoTargets: [],
      campaigns: [],
      transactionsConfig: null,
      signUpConfig: null,
      notes: values.notes,
      status,
      requesterVersion: 2, // Created from new campaign requester
    };

    // Remove unnecessary fields
    delete data.advertiserId;
    delete data.impressions;

    // Check if attributionTargets, if so, build payload
    if (
      (attributionTargets && attributionTargets.length) ||
      (currentCampaignOrder && currentCampaignOrder.attributionTargets.length)
    ) {
      data.attributionTargets = buildGeoTargetsPayload({
        targets: attributionTargets || [],
        originalTargets: currentCampaignOrder
          ? currentCampaignOrder.attributionTargets || []
          : [],
        orgId: selectedAdvertiser.id,
      });
    }

    // Check if geoTargets, if so, build payload
    if (
      (geoTargets && geoTargets.length) ||
      (currentCampaignOrder && currentCampaignOrder.geoTargets.length)
    ) {
      data.geoTargets = buildGeoTargetsPayload({
        targets: geoTargets,
        originalTargets: currentCampaignOrder
          ? currentCampaignOrder.geoTargets || []
          : [],
        orgId: selectedAdvertiser.id,
      });
    }

    // Check if campaigns, if so, build payload
    if (campaigns && campaigns.length) {
      // Filter new campaigns from currentCampaignOrder
      const newCampaigns = campaigns.filter(campaign => !campaign.id);
      const originalCampaigns = currentCampaignOrder
        ? currentCampaignOrder.campaigns
        : [];

      const updatedCampaigns = campaigns.filter(campaign =>
        originalCampaigns.find(c => c.id === campaign.id)
      );

      const deletedCampaigns =
        originalCampaigns.filter(
          campaign => !campaigns.find(c => c.id === campaign.id)
        ) || [];

      // Build campaigns payload
      data.campaigns = {
        create: newCampaigns.map(campaign => {
          const { targetingType } = campaign;
          // Get number of days between start and end date using moment
          const startDate = moment(campaign.startDate);
          const endDate = moment(campaign.endDate);
          const days = endDate.diff(startDate, "days");
          const adGroup = campaign.adGroup;

          const newTargetingCountries = [];

          if (campaign.targetingCountries) {
            newTargetingCountries.push({
              name: countryMap[campaign.targetingCountries],
              code: campaign.targetingCountries,
            });
          }

          return {
            name: campaign.name,
            startDate: campaign.startDate,
            endDate: campaign.endDate,
            budgetTotal: Number(campaign.budgetTotal),
            budgetByDay: Number(campaign.budgetTotal) / days,
            cpm: Number(campaign.cpm),
            targetingType,
            targetingRegions: {
              create:
                targetingType === "REGION"
                  ? campaign.targetingRegions.map(region => ({
                      name: region.name,
                      regions: {
                        set: region.regions.map(region => region),
                      },
                    }))
                  : [],
            },
            targetingCountries: {
              create:
                targetingType === "COUNTRY"
                  ? newTargetingCountries.map(country => ({
                      name: country.name,
                      code: country.code,
                    }))
                  : [],
            },
            targetingLocations: {
              create:
                targetingType === "LOCAL"
                  ? campaign.targetingLocations.map(location => ({
                      name: location.name,
                      type: location.type,
                      geoTargets: buildGeoTargetsPayload({
                        targets: location.geoTargets,
                        originalTargets: [],
                        orgId: selectedAdvertiser.id,
                      }),
                    }))
                  : [],
            },
            audienceType: campaign.audienceType,
            firstPartyDataAudiences: {
              create: campaign.firstPartyDataAudiences.map(audience => ({
                name: audience.name,
                activated: audience.activated,
                tag: audience.tag,
                deviceCount: audience.deviceCount,
                matchRate: audience.matchRate,
                isProcessedByStatera: audience.isProcessedByStatera,
                dspSegmentIds: {
                  set: audience.dspSegmentIds,
                },
              })),
            },
            adGroup: adGroup
              ? buildAdGroupPayload({
                  adGroup,
                  orgId: selectedAdvertiser.id,
                  campaign,
                  originalCampaigns: originalCampaigns,
                })
              : null,
            orgs: {
              connect: {
                id: selectedAdvertiser.id,
              },
            },
          };
        }),
      };

      // Check if there are deleted campaigns, if so, build payload
      if (deletedCampaigns.length) {
        data.campaigns.delete = deletedCampaigns.map(campaign => ({
          id: campaign.id,
        }));
      }

      // Check if there are updated campaigns, if so, build payload
      if (updatedCampaigns.length) {
        data.campaigns.update = updatedCampaigns.map((campaign, index) => {
          const startDate = moment(campaign.startDate);
          const endDate = moment(campaign.endDate);
          const days = endDate.diff(startDate, "days");

          const {
            targetingRegions,
            targetingLocations,
            targetingCountries,
            audienceType,
          } = campaign;
          let newTargetingRegions = [];
          let updatedTargetingRegions = [];
          let deletedTargetingRegions = [];
          let newTargetingLocations = [];
          let updatedTargetingLocations = [];
          let deletedTargetingLocations = [];
          let newTargetingCountries = [];
          let deletedTargetingCountries = [];

          switch (campaign.targetingType) {
            case "REGION":
              newTargetingRegions = targetingRegions.filter(
                region => !region.id
              );
              updatedTargetingRegions = targetingRegions.filter(
                region => region.id
              );
              deletedTargetingRegions = originalCampaigns
                .find(c => c.id === campaign.id)
                .targetingRegions.filter(
                  region => !targetingRegions.find(r => r.name === region.name)
                );

              // Delete all targeting locations
              deletedTargetingLocations = originalCampaigns.find(
                c => c.id === campaign.id
              ).targetingLocations;
              deletedTargetingCountries = originalCampaigns.find(
                c => c.id === campaign.id
              ).targetingCountries;

              break;
            case "LOCAL":
              newTargetingLocations = targetingLocations
                .filter(location => !location.id)
                .map(location => {
                  return {
                    ...location,
                    geoTargets: location.geoTargets.map(target => {
                      delete target.id;
                      return target;
                    }),
                  };
                });
              updatedTargetingLocations = targetingLocations.filter(
                location => location.id
              );
              deletedTargetingLocations = originalCampaigns
                .find(c => c.id === campaign.id)
                .targetingLocations.filter(
                  location =>
                    !targetingLocations.find(l => l.name === location.name)
                );

              // Delete all targeting regions
              deletedTargetingRegions = originalCampaigns.find(
                c => c.id === campaign.id
              ).targetingRegions;
              deletedTargetingCountries = originalCampaigns.find(
                c => c.id === campaign.id
              ).targetingCountries;
              break;

            case "COUNTRY":
              newTargetingCountries = [
                {
                  name: countryMap[targetingCountries],
                  code: targetingCountries,
                },
              ];

              // Delete all targeting locations
              deletedTargetingLocations = originalCampaigns.find(
                c => c.id === campaign.id
              ).targetingLocations;

              // Delete all targeting regions
              deletedTargetingRegions = originalCampaigns.find(
                c => c.id === campaign.id
              ).targetingRegions;
              // Delete all targeting countries
              deletedTargetingCountries = originalCampaigns.find(
                c => c.id === campaign.id
              ).targetingCountries;
              break;

            default:
              break;
          }

          // Handle audience type
          let newFirstPartyDataAudiences = [];
          let updatedFirstPartyDataAudiences = [];
          let deletedFirstPartyDataAudiences = [];

          switch (audienceType) {
            case "FIRST_PARTY_DATA":
              newFirstPartyDataAudiences = campaign.firstPartyDataAudiences.filter(
                audience => !audience.id
              );
              updatedFirstPartyDataAudiences = campaign.firstPartyDataAudiences.filter(
                audience => audience.id
              );
              deletedFirstPartyDataAudiences = originalCampaigns
                .find(c => c.id === campaign.id)
                .firstPartyDataAudiences.filter(
                  audience =>
                    !campaign.firstPartyDataAudiences.find(
                      a => a.name === audience.name
                    )
                );

              break;
            case "ALL_USERS":
              // Delete all first party data audiences
              deletedFirstPartyDataAudiences = originalCampaigns.find(
                c => c.id === campaign.id
              ).firstPartyDataAudiences;

              break;
            default:
              break;
          }

          return {
            where: {
              id: campaign.id,
            },
            data: {
              name: campaign.name,
              startDate: campaign.startDate,
              endDate: campaign.endDate,
              budgetTotal: Number(campaign.budgetTotal),
              budgetByDay: Number(campaign.budgetTotal) / days,
              cpm: Number(campaign.cpm),
              // Handle Targeting
              targetingType: campaign.targetingType,
              targetingRegions: {
                create: newTargetingRegions.map(region => ({
                  name: region.name,
                  regions: {
                    set: region.regions.map(region => region),
                  },
                })),
                update: updatedTargetingRegions.map(region => ({
                  where: {
                    id: region.id,
                  },
                  data: {
                    name: region.name,
                    regions: {
                      set: region.regions.map(region => region),
                    },
                  },
                })),
                delete: deletedTargetingRegions.map(region => ({
                  id: region.id,
                })),
              },
              targetingLocations: {
                create: newTargetingLocations.map(location => ({
                  name: location.name,
                  type: location.type,
                  geoTargets: buildGeoTargetsPayload({
                    targets: location.geoTargets,
                    originalTargets: [],
                    orgId: selectedAdvertiser.id,
                  }),
                })),
                update: updatedTargetingLocations.map(location => ({
                  where: {
                    id: location.id,
                  },
                  data: {
                    name: location.name,
                    type: location.type,
                    geoTargets: buildGeoTargetsPayload({
                      targets: location.geoTargets,
                      originalTargets: campaign.targetingLocations.find(
                        l => l.name === location.name
                      ).geoTargets,
                      orgId: selectedAdvertiser.id,
                    }),
                  },
                })),
                delete: deletedTargetingLocations.map(location => ({
                  id: location.id,
                })),
              },
              targetingCountries: targetingCountries
                ? {
                    create: newTargetingCountries.map(country => ({
                      name: country.name,
                      code: country.code,
                    })),
                    delete: deletedTargetingCountries.map(country => ({
                      id: country.id,
                    })),
                  }
                : null,
              // Handle Audiences
              audienceType: campaign.audienceType,
              firstPartyDataAudiences: {
                create: newFirstPartyDataAudiences.map(audience => ({
                  name: audience.name,
                  activated: audience.activated,
                  tag: audience.tag,
                  deviceCount: audience.deviceCount,
                  matchRate: audience.matchRate,
                  isProcessedByStatera: audience.isProcessedByStatera,
                  dspSegmentIds: {
                    set: audience.dspSegmentIds,
                  },
                })),
                update: updatedFirstPartyDataAudiences.map(audience => ({
                  where: {
                    id: audience.id,
                  },
                  data: {
                    name: audience.name,
                    activated: audience.activated,
                    tag: audience.tag,
                    deviceCount: audience.deviceCount,
                    matchRate: audience.matchRate,
                    isProcessedByStatera: audience.isProcessedByStatera,
                    dspSegmentIds: {
                      set: audience.dspSegmentIds,
                    },
                  },
                })),
                delete: deletedFirstPartyDataAudiences.map(audience => ({
                  id: audience.id,
                })),
              },
              // Handle Ad Group
              adGroup: campaign.adGroup
                ? buildAdGroupPayload({
                    adGroup: campaign.adGroup,
                    orgId: selectedAdvertiser.id,
                    campaign,
                    originalCampaigns: originalCampaigns,
                  })
                : null,
              orgs: {
                connect: {
                  id: selectedAdvertiser.id,
                },
              },
            },
          };
        });
      }
    }

    if (region && region.length) {
      data.region = {
        set: region.reduce(
          (acc, regionGroup) => acc.concat(regionGroup.regions),
          []
        ),
      };
    }

    if (currentCampaignOrder) {
      if (transactionsConfig) {
        let appId = null;
        if (transactionsConfig.appId) {
          // Check if appId is type string
          if (typeof transactionsConfig.appId === "string") {
            appId = transactionsConfig.appId;
          } else {
            appId = transactionsConfig.appId[0];
          }
        }

        data.transactionTarget = appId;
        // Check if transactionsConfig.cart is in cartToMap
        if (transactionsConfig.cart && cartToMap[transactionsConfig.cart]) {
          transactionsConfig.cart = cartToMap[transactionsConfig.cart];
        }

        data.transactionsConfig = {
          upsert: {
            create: {
              appId: appId && appId.length ? appId : null,
              cart: transactionsConfig.cart,
              traffId: transactionsConfig.traffId,
              gtmAccess: transactionsConfig.gtmAccess,
              gtmStatus: transactionsConfig.gtmStatus,
              tracksBasketItems: transactionsConfig.tracksBasketItems,
              tag: transactionsConfig.tag,
            },
            update: {
              appId: appId && appId.length ? appId : null,
              cart: transactionsConfig.cart,
              traffId: transactionsConfig.traffId,
              gtmAccess: transactionsConfig.gtmAccess,
              gtmStatus: transactionsConfig.gtmStatus,
              tracksBasketItems: transactionsConfig.tracksBasketItems,
              tag: transactionsConfig.tag,
            },
          },
        };
      } else if (
        !transactionsConfig &&
        currentCampaignOrder.transactionsConfig
      ) {
        data.transactionsConfig = {
          delete: true,
        };
      }

      if (signUpConfig) {
        let appId = null;
        // Check if appId is type string
        if (signUpConfig.appId) {
          if (typeof signUpConfig.appId === "string") {
            appId = signUpConfig.appId;
          } else {
            appId = signUpConfig.appId[0];
          }
        }

        data.signUpCampaign = true;
        data.signUpConfig = {
          upsert: {
            create: {
              appId: appId,
              locationId: null,
              name: signUpConfig.tag ? signUpConfig.tag.name : "",
            },
            update: {
              appId: appId,
              locationId: null,
              name: signUpConfig.tag ? signUpConfig.tag.name : "",
            },
          },
        };
      } else if (!signUpConfig && currentCampaignOrder.signUpConfig) {
        data.signUpConfig = {
          delete: true,
        };
      }

      if (status === "PENDING") {
        data = {
          ...data,
          campaignReviewProcess: {
            upsert: {
              create: { stateLaw: null, scaleBudget: null },
              update: { stateLaw: null, scaleBudget: null },
            },
          },
          reviewerId: currentUser.id,
        };
      }

      await handleUpdate({
        ...data,
        type: campaignTypeMap[form.getFieldValue("type")],
      });
    } else {
      const name =
        form.getFieldValue("name") ||
        `DRAFT ${uniqueId("#")}-${new Date().getTime()}`;

      form.setFieldsValue({ name });

      data = {
        ...data,
        name,
        type: campaignTypeMap[form.getFieldValue("type")],
        requestorId: currentUser.id,
        status: "DRAFT",
        kpi: "CPM",
        kpiGoal: 0,
        isRegulated: true,
        attributionWindow: 30,
        budget: Number(form.getFieldValue("budget") || 0),
        category: {
          set: "IAB7-5",
        },
        advertiser: {
          connect: {
            id: selectedAdvertiser.id,
          },
        },
        orgs: {
          connect: {
            id: currentUserOrg.id,
          },
        },
        createdBy: {
          connect: {
            id: currentUser.id,
          },
        },
        campaignChecklistProcess: {
          create: {
            processOne: false,
            processOneText: [],
            processTwo: false,
            processTwoText: [],
            processThree: false,
            processThreeText: [],
            processFour: false,
            processFourText: [],
            processFive: false,
            processFiveText: null,
          },
        },
        adUnits: {
          create: [
            {
              name: "",
              clickthrough: "",
            },
          ],
        },
      };

      if (transactionsConfig) {
        let appId = null;
        if (transactionsConfig.appId) {
          // Check if appId is type string
          if (typeof transactionsConfig.appId === "string") {
            appId = transactionsConfig.appId;
          } else {
            appId = transactionsConfig.appId[0];
          }
        }

        data.transactionTarget = appId;
        // Check if transactionsConfig.cart is in cartToMap
        if (transactionsConfig.cart && cartToMap[transactionsConfig.cart]) {
          transactionsConfig.cart = cartToMap[transactionsConfig.cart];
        }
        data.transactionsConfig = {
          create: {
            appId: appId && appId.length ? appId : null,
            cart: transactionsConfig.cart,
            traffId: transactionsConfig.traffId,
            gtmAccess: transactionsConfig.gtmAccess,
            gtmStatus: transactionsConfig.gtmStatus,
            tracksBasketItems: transactionsConfig.tracksBasketItems,
            tag: transactionsConfig.tag,
          },
        };
      }

      if (signUpConfig) {
        let appId = null;
        // Check if appId is type string
        if (signUpConfig.appId) {
          if (typeof signUpConfig.appId === "string") {
            appId = signUpConfig.appId;
          } else {
            appId = signUpConfig.appId[0];
          }
        }

        data.signUpCampaign = true;
        data.signUpConfig = {
          create: {
            appId: appId,
            locationId: null,
          },
        };
      }

      const campaignOrder = await handleSaveDraft(data);
      if (campaignOrder) {
        history.push(`/campaigns/requester/${campaignOrder.id}`);
      }

      return campaignOrder;
    }
  };

  const handleSubmitRequest = async () => {
    try {
      // Check if form is valid
      await validateForm();
      await handleSave("PENDING");
      history.push("/campaigns/main/1");
    } catch (error) {}
  };

  const isActive = stepIndex => {
    switch (stepIndex) {
      case 0:
        return activeSteps.details;
      case 1:
        return activeSteps.durationAndBudget;
      case 2:
        return activeSteps.categories;
      case 3:
        return activeSteps.attributionReporting;
      default:
        return activeSteps[`lineItem${stepIndex - 4}`];
    }
  };

  useEffect(() => {
    const validateFields = async fields => {
      try {
        await form.validateFields(fields);
        return true;
      } catch (error) {
        return false;
      }
    };

    const checkValidity = async () => {
      let updatedActiveSteps = { ...defaultActiveSteps };
      let attributionReporting = false;
      const updatedSteps = [...defaultSteps];

      const details = await validateFields(["advertiserId"]);
      const durationAndBudget = await validateFields([
        "budget",
        "cpm",
        "startDate",
        "endDate",
      ]);
      const categoryFields = ["category", "advertiserType"];
      const advertiserType = form.getFieldValue("advertiserType");

      if (advertiserType === "RETAIL_LOCAL_BRICK_AND_MORTAR") {
        categoryFields.push("attributionTargets");
      }

      const categories = await validateFields(categoryFields);

      // Attribution Reporting
      // Check if awarenessCampaign is true
      const awarenessCampaign = form.getFieldValue("awarenessCampaign");
      if (awarenessCampaign) {
        attributionReporting = await validateFields(["awarenessCampaign"]);
      } else {
        const transactionsConfigField = await validateFields([
          "transactionsConfig",
        ]);
        const signUpConfigField = await validateFields(["signUpConfig"]);
        attributionReporting =
          transactionsConfigField || signUpConfigField || false;
      }

      updatedActiveSteps = {
        ...updatedActiveSteps,
        details,
        durationAndBudget,
        categories,
        attributionReporting,
      };

      // Check if campaigns are valid
      const campaigns = form.getFieldValue("campaigns");
      if (campaigns && campaigns.length) {
        for (let index = 0; index < campaigns.length; index++) {
          const campaign = campaigns[index];
          const targetingType = campaign.targetingType;

          updatedSteps.push({
            title: `Line Item ${index + 1}`,
            bookmarkId: `requester-line-item-${index}`,
          });

          const lineItemFields = [
            ["campaigns", index, "name"],
            ["campaigns", index, "endDate"],
            ["campaigns", index, "budgetTotal"],
            ["campaigns", index, "cpm"],
            ["campaigns", index, "targetingType"],
            ["campaigns", index, "audienceType"],
            ["campaigns", index, "adGroup", "clickthrough"],
            ["campaigns", index, "adGroup", "adGroupCreatives"],
          ];

          switch (targetingType) {
            case "REGION":
              lineItemFields.push(["campaigns", index, "targetingRegions"]);
              break;
            case "LOCAL":
              lineItemFields.push(["campaigns", index, "targetingLocations"]);
              break;

            case "COUNTRY":
              lineItemFields.push(["campaigns", index, "targetingCountries"]);
              break;

            default:
              break;
          }

          if (campaign.audienceType === "FIRST_PARTY_DATA") {
            lineItemFields.push([
              "campaigns",
              index,
              "firstPartyDataAudiences",
            ]);
          }

          const lineItemDetails = await validateFields(lineItemFields);

          updatedActiveSteps = {
            ...updatedActiveSteps,
            [`lineItem${index}`]: lineItemDetails,
          };
        }
      }

      setSteps([...updatedSteps]);
      setActiveSteps({
        ...updatedActiveSteps,
      });
    };
    checkValidity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, previousValues]);

  useEffect(() => {
    const checkPermission = async () => {
      const authorized = await isAuthorized(
        ["isProcessor", "isTrafficker"],
        []
      );
      setCanEdit(authorized);
    };

    checkPermission();
  }, [isAuthorized]);

  return (
    <>
      <Card
        title={
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            SUMMARY {camapignName ? <small>- [{camapignName}] </small> : ""}
            {selectedAdvertiser ? (
              <div style={{ display: "flex" }}>
                <small style={{ fontWeight: 300 }}>
                  {selectedAdvertiser.name}
                </small>
              </div>
            ) : (
              ""
            )}
          </div>
        }
        className="campaign-requester-summary"
      >
        {mode === "edit" && (
          <Steps className="requester-summary" direction="vertical">
            {steps.map((step, index) => {
              return (
                <Step
                  key={index}
                  title={
                    <a
                      href={`#${step.bookmarkId}`}
                      style={{ color: "inherit" }}
                      onClick={e => {
                        e.preventDefault();
                        const element = document.getElementById(
                          step.bookmarkId
                        );
                        const yOffset = -80; // Adjust this value as needed
                        const y =
                          element.getBoundingClientRect().top +
                          window.pageYOffset +
                          yOffset;
                        window.scrollTo({ top: y, behavior: "smooth" });
                      }}
                    >
                      {step.title}
                    </a>
                  }
                  status={isActive(index) ? "finish" : "wait"}
                  icon={
                    !isActive(index) ? (
                      <MinusCircleFilled
                        style={{ fontSize: 10, fill: "#00000040" }}
                      />
                    ) : null
                  }
                />
              );
            })}
          </Steps>
        )}
        {mode === "review" && (
          <Text>
            Take a moment to review the details of this request before
            submitting it.
          </Text>
        )}
        <Divider />
        <Space direction="vertical" size="middle" style={{ width: "100%" }}>
          {!currentCampaignOrder && (
            <Button
              type="secondary"
              style={{ width: "100%" }}
              onClick={() => setVisibleCloneDialog(true)}
            >
              Clone
            </Button>
          )}
          <Button
            style={{ width: "100%" }}
            onClick={() => handleSave()}
            disabled={
              loadingCreateCampaignOrder ||
              loadingUpdateCampaignOrder ||
              !canEdit
            }
          >
            {`${
              currentCampaignOrder ||
              !Object.values(activeSteps).some(step => !step)
                ? "Save"
                : "Save as Draft"
            }`}
          </Button>

          {mode === "review" && (
            <Button
              style={{ width: "100%" }}
              onClick={() =>
                history.push(
                  `/campaigns/requester/${currentCampaignOrder.id}?mode=edit`
                )
              }
              disabled={
                loadingCreateCampaignOrder ||
                loadingUpdateCampaignOrder ||
                !canEdit
              }
            >
              Make Edits
            </Button>
          )}
          {mode === "edit" && currentCampaignOrder && (
            <Button
              style={{ width: "100%" }}
              disabled={
                loadingCreateCampaignOrder ||
                loadingUpdateCampaignOrder ||
                Object.values(activeSteps).some(step => !step) ||
                !canEdit
              }
              onClick={() => handleNextStep()}
            >
              Next
            </Button>
          )}
          {mode === "review" && (
            <Button
              type="primary"
              style={{ width: "100%" }}
              onClick={() => handleSubmitRequest()}
              disabled={
                loadingCreateCampaignOrder ||
                loadingUpdateCampaignOrder ||
                !canEdit
              }
            >
              Submit Request
            </Button>
          )}
        </Space>
      </Card>

      {visibleCloneDialog && (
        <CampaignRequestorCloneDialog
          visible={visibleCloneDialog}
          setVisible={setVisibleCloneDialog}
          orgId={selectedAdvertiser.id}
          onAddSelections={campaignOrders => {
            setVisibleCloneDialog(false);
            if (campaignOrders && campaignOrders.length) {
              const campaingOrder = campaignOrders[0];
              delete campaingOrder.id;

              const { transactionsConfig, signUpConfig } = campaingOrder;
              transactionsConfig &&
                transactionsConfig.id &&
                delete transactionsConfig.id;
              signUpConfig && signUpConfig.id && delete signUpConfig.id;

              handleInitialValues({
                ...campaingOrder,
                name: `${campaignOrders[0].name} - ${new Date().getTime()}`,
                status: "DRAFT",
                startDate: moment(),
                endDate: moment().add(2, "days"),
                transactionsConfig,
                campaigns: campaingOrder.campaigns.map(campaign => {
                  delete campaign.id;
                  if (campaign.adGroup && campaign.adGroup.id) {
                    delete campaign.adGroup.id;
                  }

                  return {
                    ...campaign,
                    adGroup: {
                      ...campaign.adGroup,
                      adGroupCreatives: campaign.adGroup.adGroupCreatives.map(
                        creative => {
                          delete creative.id;
                          return creative;
                        }
                      ),
                    },
                  };
                }),
              });
            }
          }}
        />
      )}
    </>
  );
};

export default CampaignRequestorSummary;
