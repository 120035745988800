import React from "react";
import {
  PrimaryTableRowText,
  StandardTableRowText,
  TableColumnTitle,
} from "../../../../shared/globalStyling/styledText";
import { Space, Table, Tag, Typography } from "antd";
import { formatNumber } from "../../../../../core/utils/campaigns";
import { withRouter } from "react-router-dom";
import moment from "moment";

const { Text } = Typography;
const MainTable = ({ tags, history }) => {
  const columns = [
    {
      title: <TableColumnTitle text={"Tag Name"} />,
      key: "name",

      render: record => {
        return <PrimaryTableRowText text={record.name} />;
      },
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: <TableColumnTitle text={"Advertiser"} />,
      key: "advertiser",
      width: "15%",

      render: record => {
        return <StandardTableRowText text={record.advertiser} />;
      },
      sorter: (a, b) => a.advertiser.localeCompare(b.advertiser),
    },
    {
      title: <TableColumnTitle text={"Measured Events"} />,
      key: "measuredEvents",

      render: record => {
        const {
          transactions,
          pageViews,
          signups,
          date,
          colors,
          typeColors,
          isSignUps,
        } = record.measuredEvents;
        return (
          <Space>
            <Tag key={1} color={colors.pageViews}>
              <Space direction="vertical" size={0}>
                <Text type={typeColors.pageViews}>Page Views</Text>
                <Text type={typeColors.pageViews} strong>
                  {formatNumber(pageViews)}
                </Text>
                <Text type="secondary">
                  {date
                    ? moment(date).format("YYYY/MM/DD - HH:mm")
                    : "Signal Unknown"}
                </Text>
              </Space>
            </Tag>
            <Tag key={2} color={colors.transactions}>
              <Space direction="vertical" size={0}>
                <Text type={typeColors.transactions}>Transactions</Text>
                <Text type={typeColors.transactions} strong>
                  {formatNumber(transactions)}
                </Text>
                <Text type="secondary">
                  {date
                    ? moment(date).format("YYYY/MM/DD - HH:mm")
                    : "Signal Unknown"}
                </Text>
              </Space>
            </Tag>
            {isSignUps || signups > 0 ? (
              <Tag key={3} color={colors.signups}>
                <Space direction="vertical" size={0}>
                  <Text type={typeColors.signups}>Sign-Ups</Text>
                  <Text type={typeColors.signups} strong>
                    {formatNumber(signups)}
                  </Text>
                  <Text type="secondary">
                    {date
                      ? moment(date).format("YYYY/MM/DD - HH:mm")
                      : "Signal Unknown"}
                  </Text>
                </Space>
              </Tag>
            ) : null}
          </Space>
        );
      },
      sorter: (a, b) => a.measuredEvents.pageViews - b.measuredEvents.pageViews,
    },
    {
      title: <TableColumnTitle text={"Tag ID"} />,
      key: "appId",
      render: record => (
        <Space direction="horizontal">
          <Typography.Paragraph copyable>{record.appId}</Typography.Paragraph>
        </Space>
      ),
      sorter: (a, b) => a.appId.localeCompare(b.appId),
    },
    {
      title: <TableColumnTitle text={"Action"} />,
      key: "action",
      width: "10%",
      render: record => (
        <Typography.Link onClick={() => redirectDetails(record.id)}>
          Test Signal
        </Typography.Link>
      ),
    },
  ];

  const redirectDetails = id => {
    history.push(`/tags/details/${id}`);
  };

  return (
    <Table
      dataSource={tags}
      columns={columns}
      rowKey={record => record.id}
      scroll={{ x: "100%" }}
      size="middle"
    />
  );
};

export default withRouter(MainTable);
