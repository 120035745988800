import React from "react";
import { graphql } from "react-apollo";
import { withRouter } from "react-router-dom";
import compose from "lodash/flowRight";
import CURRENT_USER from "../../GraphQl/Queries/CURRENT_USER";

import { useQuery, useLazyQuery, useMutation } from "@apollo/react-hooks";

// Mutations
import CREATE_AUDIENCE_DEMOGRAPH from "../../GraphQl/Mutations/CREATE_AUDIENCE_DEMOGRAPH";
import UPDATE_AUDIENCE_DEMOGRAPH from "../../GraphQl/Mutations/UPDATE_AUDIENCE_DEMOGRAPH";
import DELETE_AUDIENCE_DEMOGRAPH from "../../GraphQl/Mutations/DELETE_AUDIENCE_DEMOGRAPH";
import SAVE_AUDIENCES_TO_API from "../../GraphQl/Mutations/SAVE_AUDIENCES_TO_API";
import ACTIVATE_DEMOGRAPH_AUDIENCE from "../../GraphQl/Mutations/ACTIVATE_DEMOGRAPH_AUDIENCE";

// Read
import GET_AUDIENCE_DEMOGRAPHS from "../../GraphQl/Queries/GET_AUDIENCE_DEMOGRAPHS";
import GET_AUDIENCE_COUNT from "../../GraphQl/Queries/GET_AUDIENCE_COUNT";
import GET_AUDIENCE_SCHEMA from "../../GraphQl/Queries/GET_AUDIENCE_SCHEMA";

const DemographicsMainLoader = props => {
  const { children, history } = props;

  const {
    role: {
      org: { id: orgId },
    },
    id: userId,
  } = props.currentUser;

  const [saveAudiencesToApi, { loading: loadingPublishing }] = useMutation(
    SAVE_AUDIENCES_TO_API,
    {
      data: { ...props.data },
    }
  );

  const [
    activateDemographAudiences,
    { loading: loadingActivation },
  ] = useMutation(ACTIVATE_DEMOGRAPH_AUDIENCE, {
    data: { ...props.data },
  });

  const [createAudienceDemograph] = useMutation(CREATE_AUDIENCE_DEMOGRAPH, {
    data: { ...props.data },
  });

  const [updateAudienceDemograph] = useMutation(UPDATE_AUDIENCE_DEMOGRAPH, {
    data: { ...props.data },
  });

  const [
    getAudienceDemographs,
    { loading: loadingAudienceDemographs, data: audienceDemographs },
  ] = useLazyQuery(GET_AUDIENCE_DEMOGRAPHS, {
    variables: {
      whereParameters: {
        ...props,
      },
    },
    fetchPolicy: "no-cache",
  });

  const {
    data: audienceSchema,
    loading: loadingAudienceSchema,
    refetch: refetchAudienceSchema,
  } = useQuery(GET_AUDIENCE_SCHEMA);

  const {
    loading: loadingAudienceCount,
    refetch: refetchAudienceCount,
  } = useQuery(GET_AUDIENCE_COUNT, {
    variables: { metric: props.metric },
    skip: true,
  });

  const [deleteAudienceDemograph] = useMutation(DELETE_AUDIENCE_DEMOGRAPH, {
    variables: {
      id: props.Id,
    },
  });

  return (
    <React.Fragment>
      {children &&
        React.cloneElement(children, {
          orgId,
          userId,
          history,
          audienceSchema,
          audienceDemographs,
          loadingPublishing,
          loadingActivation,
          loadingAudienceCount,
          loadingAudienceSchema,
          loadingAudienceDemographs,
          activateDemographAudiences,
          saveAudiencesToApi,
          createAudienceDemograph,
          updateAudienceDemograph,
          deleteAudienceDemograph,
          refetchAudienceCount,
          refetchAudienceSchema,
          getAudienceDemographs,
        })}
    </React.Fragment>
  );
};

export default compose(
  graphql(CURRENT_USER, {
    props: ({ data: { currentUser }, ownProps }) => {
      return ownProps.currentUser || { currentUser };
    },
  })
)(withRouter(DemographicsMainLoader));
