import React, { createContext, useEffect, useState } from "react";

import CURRENT_USER from "../../GraphQl/Queries/CURRENT_USER";
import { useLazyQuery, useMutation, useQuery } from "react-apollo";
import TASKS_CONNECTION from "../../GraphQl/Queries/TASKS";
import CREATE_TASK from "../../GraphQl/Queries/CREATE_TASK";
import GET_TASK from "../../GraphQl/Queries/GET_TASK";
import UPDATE_TASK from "../../GraphQl/Queries/UPDATE_TASK";
import UPDATE_COMMENT from "../../GraphQl/Queries/UPDATE_COMMENT";
import CREATE_COMMENT from "../../GraphQl/Queries/CREATE_COMMENT";
import GET_HISTORIES from "../../GraphQl/Queries/GET_HISTORIES";

const TaskContext = createContext({});

export const TaskProvider = props => {
  const { children } = props;

  const [task, setTask] = useState([]);
  const [selectedTask, setSelectedTask] = useState(null);
  const [taskBoardPagination, setTaskBoardPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [unassignedTaskPagination, setUnassignedTaskPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  // TODO: This should be move on the top level of the application
  const {
    data: { currentUser },
  } = useQuery(CURRENT_USER);

  const [
    fetchTasksPaginated,
    { data: tasksConnectionData, loading: tasksLoading },
  ] = useLazyQuery(TASKS_CONNECTION, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (tasksConnectionData && tasksConnectionData.tasksConnection) {
      setTask(tasksConnectionData.tasksConnection.edges.map(edge => edge.node));
      setTaskBoardPagination({
        ...taskBoardPagination,
        total: tasksConnectionData.full.aggregate.count,
      });
      setUnassignedTaskPagination({
        ...unassignedTaskPagination,
        total: tasksConnectionData.full.aggregate.count,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasksConnectionData]);

  const [
    fetchTask,
    { data: taskData, loading: taskLoading, refetch: refetchTask },
  ] = useLazyQuery(GET_TASK, {
    fetchPolicy: "no-cache",
  });

  // Mutations
  const [createTask, { loading: createTaskLoading }] = useMutation(CREATE_TASK);
  const [updateTask, { loading: updateTaskLoading }] = useMutation(UPDATE_TASK);
  const [createComment, { loading: createCommentLoading }] = useMutation(
    CREATE_COMMENT
  );
  const [updateComment, { loading: updateCommentLoading }] = useMutation(
    UPDATE_COMMENT
  );

  const [
    fetchHistories,
    { data: dataHistories, loading: loadingHistories },
  ] = useLazyQuery(GET_HISTORIES, {
    fetchPolicy: "no-cache",
  });

  return (
    <TaskContext.Provider
      value={{
        currentUser,
        fetchTasksPaginated,
        tasksConnectionData,
        tasksLoading,
        task,
        taskBoardPagination,
        setTaskBoardPagination,
        unassignedTaskPagination,
        setUnassignedTaskPagination,
        createTask,
        createTaskLoading,
        selectedTask,
        setSelectedTask,
        fetchTask,
        currentTask: (taskData && taskData.task) || null,
        taskLoading,
        updateTask,
        updateTaskLoading,
        createComment,
        createCommentLoading,
        updateComment,
        updateCommentLoading,
        refetchTask,
        histories: (dataHistories && dataHistories.historyLogs) || [],
        loadingHistories,
        fetchHistories,
      }}
    >
      {children}
    </TaskContext.Provider>
  );
};

export default TaskContext;
