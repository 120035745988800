import React, { useEffect } from "react";
import { Card, Space, Table, Tag, Input } from "antd";
import moment from "moment";
import { useCampaignOrderQueueContext } from "../../../../core/components/campaigns/campaignordersqueue/useCampaignOrderQueueContext";
import { Link } from "react-router-dom";

const { Search } = Input;

const columns = [
  {
    title: "Campaign Details",
    dataIndex: "name",
    key: "campaignDetails",
    render: (text, record) => (
      <Space size={4} direction="vertical">
        <Link to={`/campaigns/v2/orderqueue/details/${record.id}`}>{text}</Link>
        <Space size={4}>
          {/* <Tag color="purple">ECM</Tag> */}
          <Tag color="blue">
            {(record.advertiser && record.advertiser.name) || ""}
          </Tag>
        </Space>
      </Space>
    ),
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: text => {
      switch (text) {
        case "REJECTED":
          return "Pending Revision";

        default:
          return "Pending Review";
      }
    },
  },
  {
    title: "Launch Date",
    dataIndex: "startDate",
    key: "startDate",
    render: text => moment(text).format("MM/DD/YYYY"),
  },
  {
    title: "Submitted On",
    key: "submittedOn",
    dataIndex: "updatedAt",
    render: text => moment(text).format("MM/DD/YYYY"),
  },
];

export const CampaignOrderQueueQueued = () => {
  const {
    campaignOrders,
    loadingCampaignOrdersQueue,
    fetchCampaignOrdersQueue,
    pagination,
    setPagination,
  } = useCampaignOrderQueueContext();

  useEffect(() => {
    handleFetchCampaignOrdersQueue({
      skip: 0,
      first: pagination.pageSize,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFetchCampaignOrdersQueue = ({ skip, first, name = "" }) => {
    fetchCampaignOrdersQueue({
      variables: {
        where: {
          AND: [
            {
              status_in: ["PENDING", "LIVE_PENDING", "REJECTED"],
              name_contains: name,
            },
          ],
        },
        orderBy: "updatedAt_DESC",
        skip,
        first,
      },
    });
  };

  const handleTableChange = ({ current, pageSize }) => {
    setPagination(prev => ({ ...prev, current, pageSize }));
    handleFetchCampaignOrdersQueue({
      skip: (current - 1) * pageSize || 0,
      first: pageSize,
    });
  };

  const handleSearchChange = name => {
    handleFetchCampaignOrdersQueue({
      first: pagination.pageSize,
      name,
    });
  };

  return (
    <Card
      title="ORDER QUEUE"
      extra={
        <div>
          <Search
            placeholder="Search campaign"
            style={{
              width: 200,
            }}
            onSearch={value => handleSearchChange(value)}
            enterButton
            allowClear
          />
        </div>
      }
    >
      <Table
        key="id"
        columns={columns}
        dataSource={campaignOrders}
        loading={loadingCampaignOrdersQueue}
        pagination={pagination}
        onChange={handleTableChange}
      />
    </Card>
  );
};
